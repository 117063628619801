import callingCodesData from 'country-calling-code';
import filter from 'lodash/filter';
import map from 'lodash/fp/map';
import includes from 'lodash/includes';

import capitals from './capitals.json';

const COUNTRIES = {
  ALBANIA: 'AL',
  ALGERIA: 'DZ',
  ANDORRA: 'AD',
  ARGENTINA: 'AR',
  AUSTRALIA: 'AU',
  AUSTRIA: 'AT',
  AZERBAIJAN: 'AZ',
  BELGIUM: 'BE',
  BOSNIA_HERZEGOVINA: 'BA',
  BRAZIL: 'BR',
  BULGARIA: 'BG',
  CHINA: 'CN',
  COLOMBIA: 'CO',
  CROATIA: 'HR',
  CYPRUS: 'CY',
  CZECH_REPUBLIC: 'CZ',
  DENMARK: 'DK',
  EGYPT: 'EG',
  ESTONIA: 'EE',
  ETHIOPIA: 'ET',
  FINLAND: 'FI',
  FRANCE: 'FR',
  GEORGIA: 'GE',
  GERMANY: 'DE',
  GUERNSEY: 'GG',
  GREAT_BRITAIN: 'GB',
  GREECE: 'GR',
  HUNGARY: 'HU',
  ICELAND: 'IS',
  INDIA: 'IN',
  INDONESIA: 'ID',
  IRELAND: 'IE',
  ISRAEL: 'IL',
  ITALY: 'IT',
  JAPAN: 'JP',
  JERSEY: 'JE',
  JORDAN: 'JO',
  KAZAKHSTAN: 'KZ',
  KUWAIT: 'KW',
  LATVIA: 'LV',
  LIBYA: 'LY',
  LIECHTENSTEIN: 'LI',
  LITHUANIA: 'LT',
  LUXEMBOURG: 'LU',
  MALTA: 'MT',
  MOLDOVA: 'MD',
  MONACO: 'MC',
  NETHERLANDS: 'NL',
  NEW_ZEALAND: 'NZ',
  NIGERIA: 'NG',
  NORWAY: 'NO',
  PAKISTAN: 'PK',
  PHILIPPINES: 'PH',
  POLAND: 'PL',
  PORTUGAL: 'PT',
  ROMANIA: 'RO',
  RUSSIA: 'RU',
  SAN_MARINO: 'SM',
  SAUDI_ARABIA: 'SA',
  SERBIA: 'RS',
  SINGAPORE: 'SG',
  SLOVAKIA: 'SK',
  SLOVENIA: 'SI',
  SOUTH_AFRICA: 'ZA',
  SOUTH_KOREA: 'KR',
  SPAIN: 'ES',
  SRI_LANKA: 'LK',
  SWEDEN: 'SE',
  SWISS: 'CH',
  SWITZERLAND: 'CH',
  THAILAND: 'TH',
  TURKEY: 'TR',
  UKRAINE: 'UK',
  UNITED_ARAB_EMIRATES: 'AE',
  UNITED_KINGDOM: 'GB',
  UNITED_STATES: 'US',
  URUGUAY: 'UY',
  VATICAN_CITY: 'VA',
  VENEZUELA: 'VE',
  VIETNAM: 'VN',
  CANADA: 'CA',
  MALAYSIA: 'MY',
  MEXICO: 'MX',
  TAIWAN: 'TW',
};
export default COUNTRIES;
export type CountryCode = (typeof COUNTRIES)[keyof typeof COUNTRIES];
export const {
  CANADA,
  MALAYSIA,
  MEXICO,
  TAIWAN,
  ALBANIA,
  ANDORRA,
  ARGENTINA,
  AUSTRALIA,
  AUSTRIA,
  AZERBAIJAN,
  BELGIUM,
  BOSNIA_HERZEGOVINA,
  BRAZIL,
  BULGARIA,
  CHINA,
  COLOMBIA,
  CROATIA,
  CYPRUS,
  CZECH_REPUBLIC,
  DENMARK,
  EGYPT,
  ESTONIA,
  ETHIOPIA,
  FINLAND,
  FRANCE,
  GEORGIA,
  GERMANY,
  GREAT_BRITAIN,
  GREECE,
  HUNGARY,
  ICELAND,
  INDIA,
  INDONESIA,
  IRELAND,
  ISRAEL,
  ITALY,
  JAPAN,
  JORDAN,
  KAZAKHSTAN,
  KUWAIT,
  LATVIA,
  LIBYA,
  LIECHTENSTEIN,
  LITHUANIA,
  LUXEMBOURG,
  MALTA,
  MOLDOVA,
  MONACO,
  NETHERLANDS,
  NEW_ZEALAND,
  NIGERIA,
  NORWAY,
  PAKISTAN,
  PHILIPPINES,
  POLAND,
  PORTUGAL,
  ROMANIA,
  RUSSIA,
  SAN_MARINO,
  SAUDI_ARABIA,
  SERBIA,
  SINGAPORE,
  SLOVAKIA,
  SLOVENIA,
  SOUTH_AFRICA,
  SOUTH_KOREA,
  SPAIN,
  SRI_LANKA,
  SWEDEN,
  SWISS,
  SWITZERLAND,
  THAILAND,
  TURKEY,
  UKRAINE,
  UNITED_ARAB_EMIRATES,
  UNITED_KINGDOM,
  UNITED_STATES,
  URUGUAY,
  VATICAN_CITY,
  VENEZUELA,
  VIETNAM,
} = COUNTRIES;

export const EUROPE_COUNTRIES: CountryCode[] = [
  AUSTRIA,
  BELGIUM,
  BOSNIA_HERZEGOVINA,
  BULGARIA,
  CROATIA,
  CYPRUS,
  CZECH_REPUBLIC,
  DENMARK,
  ESTONIA,
  FINLAND,
  FRANCE,
  GERMANY,
  GREECE,
  HUNGARY,
  IRELAND,
  ITALY,
  LATVIA,
  LITHUANIA,
  LUXEMBOURG,
  MALTA,
  NETHERLANDS,
  POLAND,
  PORTUGAL,
  ROMANIA,
  SLOVAKIA,
  SLOVENIA,
  SPAIN,
  SWEDEN,
  SWITZERLAND,
  UNITED_KINGDOM,
];

export const SEPA_COUNTRIES: CountryCode[] = [
  AUSTRIA,
  BELGIUM,
  BULGARIA,
  CROATIA,
  CYPRUS,
  CZECH_REPUBLIC,
  DENMARK,
  ESTONIA,
  FINLAND,
  FRANCE,
  GERMANY,
  GREECE,
  HUNGARY,
  IRELAND,
  ITALY,
  LATVIA,
  LITHUANIA,
  LUXEMBOURG,
  MALTA,
  NETHERLANDS,
  POLAND,
  PORTUGAL,
  ROMANIA,
  SLOVAKIA,
  SLOVENIA,
  SPAIN,
  SWEDEN,
  SWITZERLAND,
  UNITED_KINGDOM,
  SAN_MARINO,
  VATICAN_CITY,
  ANDORRA,
  MONACO,
  ICELAND,
  NORWAY,
  LIECHTENSTEIN,
];

export const NON_EURO_COUNTRIES: Array<CountryCode> = [
  BULGARIA,
  CZECH_REPUBLIC,
  DENMARK,
  HUNGARY,
  POLAND,
  ROMANIA,
  SWEDEN,
  SWITZERLAND,
  UNITED_KINGDOM,
];

export function isEuropeanCountry(countryCode: CountryCode): boolean {
  return includes(EUROPE_COUNTRIES, countryCode);
}
export const isEuroCountry = (countryCode: CountryCode): boolean =>
  !NON_EURO_COUNTRIES.includes(countryCode);

export const COUNTRIES_WITHOUT_ZIPCODE = ['VU', 'HK'];

type CountryCallingCodesDataType = {
  code: string;
  name: string;
  callingPrefix: string;
  flag: string;
};

function getFlagEmoji(countryCode) {
  return countryCode
    .toUpperCase()
    .replace(/./g, char => String.fromCodePoint(127397 + char.charCodeAt()));
}

export const COUNTRY_CALLING_CODES_PREFIX: CountryCallingCodesDataType[] = map(
  ({ country, countryCodes, isoCode2 }) => ({
    code: isoCode2,
    name: country,
    callingPrefix: countryCodes[0],
    flag: getFlagEmoji(isoCode2),
  }),
  callingCodesData,
);

export const REC_COUNTRIES_CODES = [
  'AE',
  'AL',
  'AT',
  'AU',
  'BA',
  'BD',
  'BE',
  'BG',
  'BH',
  'BM',
  'BZ',
  'CA',
  'CH',
  'CL',
  'CN',
  'CO',
  'CY',
  'CZ',
  'DE',
  'DK',
  'EE',
  'EG',
  'ES',
  'FI',
  'FJ',
  'FR',
  'GB',
  'GD',
  'GG',
  'GM',
  'GP',
  'GR',
  'HK',
  'HR',
  'HU',
  'ID',
  'IE',
  'IN',
  'IT',
  'JE',
  'JO',
  'JP',
  'KH',
  'KY',
  'LB',
  'LC',
  'LK',
  'LT',
  'LU',
  'MM',
  'MT',
  'MY',
  'NL',
  'NO',
  'NZ',
  'OM',
  'PH',
  'PK',
  'PL',
  'PT',
  'RE',
  'RO',
  'RS',
  'SE',
  'SG',
  'SI',
  'SK',
  'TH',
  'TN',
  'TR',
  'TW',
  'US',
  'VN',
  'VU',
  'ZA',
];

export const REC_COUNTRIES_CAPITALS = filter(capitals, cap =>
  REC_COUNTRIES_CODES.includes(cap.code),
);
