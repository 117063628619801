const namespace = 'solar';
const flashDataNamespace = `${namespace}.flashData`;
const productNamespace = `${flashDataNamespace}.product`;

const i18n = {
  SOLAR: {
    TITLE: {
      key: `${namespace}.title`,
      message: 'Solar',
    },
    FLASH_DATA: {
      TITLE: {
        key: `${flashDataNamespace}.title`,
        message: 'Flash Data',
      },
      DESCRIPTION: {
        key: `${flashDataNamespace}.description`,
        message:
          'In the delivery overview you see directly received goods from the manufacturer and the goods your supplier has forwarded to you via the brand added value portal.',
      },
      SEARCH_ROUTE_NAME: {
        key: `${flashDataNamespace}.searchRouteName`,
        message: 'Flash Data Search',
      },
      DOWLOAD_REPORT: {
        key: `${flashDataNamespace}.downloadReport`,
        message: 'download reporting',
      },
      FOUND_PANELS: {
        key: `${flashDataNamespace}.foundPanels`,
        message:
          'We found {count, plural, one {# panel} other {# panels}} on your search.',
      },
    },
    DUPLICATED_SERIAL_NUMBER: {
      SEARCH_BUTTON: {
        key: `${namespace}.duplicatedSerialNumberSearchButton`,
        message: 'Look for usage of project serial numbers on other projects',
      },
      RESULT: {
        key: `${namespace}.duplicatedSerialNumberSearchButton`,
        message: 'Found projects',
      },
      SERIAL_NUMBERS: {
        key: `${namespace}.serialNumbers`,
        message: 'Serial numbers',
      },
    },
    PANEL: {
      SERIALNUMBER: {
        key: `${productNamespace}.serialnumber`,
        message: 'Panel Serial',
      },
      ORDERNUMBER: {
        key: `${productNamespace}.ordernumber`,
        message: 'Order n\u00B0',
      },
      DELIVERYNUMBER: {
        key: `${productNamespace}.deliverynumber`,
        message: 'Delivery n\u00B0',
      },
      PALLETNUMBER: {
        key: `${productNamespace}.palletnumber`,
        message: 'Pallet n\u00B0',
      },
      EAN: {
        key: `${productNamespace}.ean`,
        message: 'Type of panels',
      },
      PMAX: {
        key: `${productNamespace}.pmax`,
        message: 'PMax (W)',
      },
      VPM: {
        key: `${productNamespace}.vpm`,
        message: 'Vpm (V)',
      },
      IPM: {
        key: `${productNamespace}.ipm`,
        message: 'Ipm (A)',
      },
    },
  },
};

export const { SOLAR } = i18n;

export default i18n;
