import { CircleF, useGoogleMap } from '@react-google-maps/api';
import { Coordinates, useStoreItemById } from '@store-locator/store-creator';
import { useIsMediaQueryUpToMD } from '@ui-system/media-query';
import { useColors } from '@ui-system/theme/colors';
import delay from 'lodash/delay';
import isEmpty from 'lodash/isEmpty';
import React, { useCallback, useEffect, useMemo } from 'react';
import { F, U } from 'ts-toolbelt';

import { calculateZoom } from '../../utils';
import TraderMarkers from './TraderMarkers';

const INITIAL_MARKER_PIXEL = -280;
const MOBILE_INITIAL_MARK_PIXEL = 0;
// XXX: HTML injected to fix the scroll message overlay that were appearing above of store card
const MAP_SCROLL_TO_ZOOM_MSG_STYLE = document.createElement('style');
MAP_SCROLL_TO_ZOOM_MSG_STYLE.innerHTML = `
  .gm-style-mot {
    top: 80%;
  }
`;
document.head.appendChild(MAP_SCROLL_TO_ZOOM_MSG_STYLE);

interface Props {
  origin: U.Nullable<Coordinates>;
  radius: U.Nullable<number>;
  storesList: U.Nullable<number[]>;
  selectedStoreId: U.Nullable<number>;
  setZoom: F.Function<[number]>;
}

const MapInterface: React.FC<Props> = ({
  origin,
  radius,
  storesList,
  selectedStoreId,
  setZoom,
}: Props) => {
  const gmap = useGoogleMap();
  const colors = useColors();
  const isMobile = useIsMediaQueryUpToMD();
  const selectedStore = useStoreItemById(selectedStoreId);
  const panMap = useCallback(
    (lat: U.Nullable<number>, lng: U.Nullable<number>) => {
      if (!lat || !lng) return;
      gmap?.panTo({
        lat,
        lng,
      });
    },
    [gmap],
  );

  const circleOptions = useMemo(
    () => ({
      strokeColor: colors.error as unknown as string,
      strokeWeight: 2,
      fillOpacity: 0,
    }),
    [colors.error],
  );

  useEffect(() => {
    if (origin?.lat || origin?.lng) {
      const { lat, lng } = origin;
      panMap(lat, lng);
      // XXX: Used to adjust map position to the visible area after set map center coordinates
      delay(() => {
        gmap?.panBy(
          isMobile ? MOBILE_INITIAL_MARK_PIXEL : INITIAL_MARKER_PIXEL,
          0,
        );
      }, 600);
    }
  }, [origin]);

  useEffect(() => {
    if (!isMobile && selectedStore) {
      const {
        address: { lat, lng },
      } = selectedStore;
      panMap(lat, lng);
    }
  }, [selectedStore]);

  useEffect(() => {
    if (radius) {
      setZoom(calculateZoom(radius));
    }
  }, [radius]);

  return (
    <>
      <TraderMarkers storesList={storesList} />
      {origin && radius && !isEmpty(storesList) ? (
        <CircleF
          center={origin}
          radius={radius * 1000}
          options={circleOptions}
        />
      ) : null}
    </>
  );
};

export default MapInterface;
