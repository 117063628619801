import { useUseCookiesModal } from '@cookie-consent/core/cookiesState';
import useLegalLinks from '@mmw/clients-ui-hooks-use-legal-links';
import { COOKIES, DATA_PROTECTION, IMPRINT } from '@mmw/constants-i18n-common';
import { getClientVersion } from '@mmw/contextual-config';
import { css } from '@ui-system/css';
import { useIsMediaQueryUpToMD } from '@ui-system/media-query';
import UI from '@ui-system/ui';
import moment from 'moment';
import React from 'react';

const CURRENT_YEAR = moment().format('YYYY');
type FooterProps = {
  hideMmwName?: boolean;
};

const Footer: React.FC<FooterProps> = ({ hideMmwName }) => {
  const { imprintLink, dataProtectionLink } = useLegalLinks(true);
  const { toggle: toggleModal } = useUseCookiesModal();
  const isMobile = useIsMediaQueryUpToMD();
  // const { isDataProtectionVisible, toggleDataProtection } =
  //   useDataProtectionModalState();
  // const { isImprintVisible, toggleImprint } = useImprintModalState();
  return (
    <UI.Page.Footer>
      <UI.Container
        style={css`
          position: relative;
          ${isMobile
            ? `
            padding-bottom: 60px;
          `
            : ''}
        `}
        bg="white"
        shadow={5}
        w="100%"
        p="2"
        direction="column"
      >
        <UI.Container direction="column" align="center">
          {hideMmwName ? null : (
            <UI.Typography modifiers="grayA400">marken mehrwert</UI.Typography>
          )}
          <UI.Container align="center" justify="space-evenly" w={400}>
            <UI.Link
              // onClick={toggleImprint}
              href={imprintLink}
              target="_blank"
              i18n={IMPRINT}
              modifiers="grayA400"
            />
            <UI.Link
              // onClick={toggleDataProtection}
              href={dataProtectionLink}
              target="_blank"
              i18n={DATA_PROTECTION}
              modifiers="grayA400"
            />
            <UI.Link
              i18n={COOKIES}
              modifiers="grayA400"
              onClick={toggleModal}
            />
          </UI.Container>
        </UI.Container>
        <UI.Container
          style={
            !isMobile
              ? css`
                  position: absolute;
                  bottom: 5px;
                  right: 5px;
                `
              : undefined
          }
          gap={1}
          justify="flex-end"
          responsive="upToMd.justify=center;upToMd.m=2,0,0,0"
        >
          <UI.Body2 modifiers="grayA400">{getClientVersion()}</UI.Body2>
          <UI.Body2 modifiers="grayA400">© brand added value AG,</UI.Body2>
          <UI.Body2 modifiers="grayA400">{CURRENT_YEAR}</UI.Body2>
        </UI.Container>
      </UI.Container>
      {/* <DataProtectionModal
        isVisible={isDataProtectionVisible}
        toggle={toggleDataProtection}
      />
      <ImprintModal isVisible={isImprintVisible} toggle={toggleImprint} /> */}
    </UI.Page.Footer>
  );
};

export default Footer;
