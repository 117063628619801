import { Salutation, Title } from '@mmw/constants-titles-salutations';
import { AddressJSON } from '@mmw/services-core-common/types';
import { ProductJSON } from '@mmw/services-core-product/types';
import { U } from 'ts-toolbelt';

export type OpeningTimesJSON = {
  id: number;
  dayOfWeek: number;
  openingHour: number;
  openingMinutes: number;
  closingHour: number;
  closingMinutes: number;
};

export enum OrgunitDetailsType {
  INFO,
  SALES,
  SUPPORT,
}

export type OrgunitDetailsJSON = {
  id: number;
  type: OrgunitDetailsType;
  phone: string | null;
  fax: string | null;
  homepage: string | null;
  information: string | null;
  email: string | null;
  openingTimes: Array<OpeningTimesJSON>;
  categories: Array<string>;
  services: Array<string>;
  giftCards: {
    email?: string;
    website?: string;
  };
};

export type CampaignAgreementStatus = {
  accepted: boolean;
  campaignID: number;
  campaignCode: string;
};

export type TraderJSON = {
  orgunitID: number;
  participant: number;
  owner: number | null;
  address: AddressJSON;
  homePage: string | null;
  phone: string | null;
  email: string | null;
  displayname: string | null;
  gln: string | null;
  details: OrgunitDetailsJSON[];
  erpnumber: string | null;
  erpusage: string | null;
  image: string;
  demoDevices: U.Nullable<ProductJSON[]>;
  campaignAgreementStatus: U.Nullable<CampaignAgreementStatus>;
  campaignsAgreementStatus: CampaignAgreementStatus[];
};

export type TraderSearchRequestObject = {
  fullText?: string | null | void; // XXX: not implemented on API,
  categories?: Array<string> | null | void; // XXX: not implemented on API,
  // the items below wont be implemented right now for mannheim app
  city?: string | null | void;
  company?: string | null | void;
  zipcode?: string | null | void;
  street?: string | null | void;
  campaign?: string | null | void;
  country?: string | null | void;
  erpMaterialKeys?: Array<string> | null | void;
  productIDs?: Array<number> | null | void;
  classOfGoodsIDs?: Array<number> | null | void;
  lat?: number | null | void;
  lng?: number | null | void;
  radius?: number | null | void;
  brand?: number | null | void;
  campaignID?: number | null | void;
  salesOrgBrand?: number | null | void;
  orgunitID?: number | null | void;
  similar?: boolean | null | void;
  forceFilterByCampaignOrgunits?: boolean | null | void;
  forceFilterByBusinessRelation?: boolean | null | void;
  returnsDistance?: boolean | null | void;
  sortOrder?: number | null | void;
  returnsDetails?: boolean | null | void;
  returnsErpnumber?: boolean | null | void;
  productsAvailableInStock?: boolean | null | void;
  productsInDemonstration?: boolean | null | void;
  returnsOnlyTradersRankedByBrand?: boolean | null | void;
  returnsOnlyDisposableTraders?: boolean | null | void;
  campaignCodes?: Array<string> | null | void;
};

export type CreateStoreLocatorUserJSON = {
  salesmanID?: string | null | void; // identifies the user in the store (not used much),
  firstname: string;
  lastname: string;
  phone?: string | null | void;
  fax?: string | null | void;
  email: string;
  domkeysalutation: Salutation;
  domkeytitle: Title;
  address?: AddressJSON;
};

export type ContactType = 'CREATE_STORE' | 'GIFT_CARD';

export const ContactTypes = {
  CREATE_STORE: 'CREATE_STORE',
  GIFT_CARD: 'GIFT_CARD',
};

export type ContactJSON = {
  salesOrgBrandID?: number | null | void;
  language: string;
  to: string;
  message: string;
  type: ContactType;
  recaptchaCode: string;
};

export type CreateStoreLocatorOrgunitJSON = {
  store: TraderJSON;
  user: CreateStoreLocatorUserJSON;
  contact: ContactJSON;
};

export type CoordinatesResult = {
  lat: U.Nullable<number>;
  lng: U.Nullable<number>;
};

export type GoogleAddressSearchApiReturn = {
  success: boolean;
  latitude: U.Nullable<number>;
  longitude: U.Nullable<number>;
};
