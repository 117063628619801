import ButtonBase from '@material-ui/core/ButtonBase';
import { CountryCode } from '@mmw/constants-country-codes';
import { RESET } from '@mmw/constants-i18n-buttons';
import I18N from '@mmw/constants-i18n-orgunit';
import contextualConfig from '@mmw/contextual-config';
import { useRestartForm } from '@mmw/redux-store-fast-form/hooks';
import { useValue } from '@mmw/redux-store-fast-form/hooks/fields';
import { useLanguage } from '@mmw/redux-store-i18n/hooks';
import { getStoreLocatorService } from '@mmw/services-holder';
import {
  setAlreadySearch,
  setLastSearchByUserLocation,
  useAlreadySearch,
  useIsLoadingStoresByLocationSearch,
  useLastSearchByUserLocation,
  useResetStoresByLocationSearch,
  useSearchByProductParams,
  useSearchStoresByLocation,
  useTotalStoresListByLocation,
} from '@store-locator/store-creator';
import { css, styled } from '@ui-system/css';
import { useIsMediaQueryUpToMD } from '@ui-system/media-query';
import { useColors } from '@ui-system/theme/colors';
import UI from '@ui-system/ui';
import { useBoolean } from 'ahooks';
import isEmpty from 'lodash/isEmpty';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { F, U } from 'ts-toolbelt';

import {
  LocatorCustomizationContext,
  useCustomUrlPathAsObj,
  useCustomUrlRequestObj,
} from '../../context';
import { MeasureUnitsProvider } from '../../context/measure-units';
import NotificationsHandler from '../NotificationsHandler';
import SearchByProduct from '../search-by-product';
import {
  STORE_LOCATOR_FIELDPATHS,
  StoreLocatorSearchFormValues,
} from './form/configs';
import {
  CountrySelectFormField,
  getMeasureUnit,
  getRadiusSelect,
} from './form/Fields';
import SearchButton from './form/SearchButton';
import SearchFormProvider from './form/SearchFormProvider';
import RefreshSearchOnChangeValuesListener from './RefreshSearchOnChangeValuesListener';
import StoreCardsList from './StoreCardsList';
import TabContentHandler from './TabsContentHandler';

const {
  application: { supportedCountries },
} = contextualConfig;

function useTabsConfigs() {
  const colors = useColors();
  return useMemo(
    () => ({
      tabsTitle: [
        {
          ...I18N.STORE_LOCATOR.SEARCH_STORE,
          icon: 'local',
          iconColor: 'gray.A400',
        },
        {
          ...I18N.STORE_LOCATOR.USE_MY_LOCATION,
          icon: 'local',
          iconColor: 'gray.A400',
        },
      ],
      inactiveTabStyles: {
        style: {
          backgroundColor: colors.gray.A100,
          boxShadow: `inset 0 0 10px ${colors.gray.A200}`,
        },
        modifiers: 'primary',
        iconColor: 'primary',
      },
    }),
    [colors.gray.A100, colors.gray.A200],
  );
}

const TABS_INDICATOR_PROPS = {
  style: css`
    display: none;
  `,
};

const CARD_STYLE = {
  position: 'absolute',
  left: 30,
  top: 60,
  zIndex: 999,
  width: 500,
};

const MOBILE_STYLE = {
  position: 'fixed',
  left: 0,
  bottom: 0,
  zIndex: 9995,
  width: '100%',
  overflow: 'auto',
};

const CARD_CONTENT_STYLE = css`
  padding: 0;
  -webkit-box-shadow: 0px 2px 9px -3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 2px 9px -3px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 2px 9px -3px rgba(0, 0, 0, 0.75);
`;

interface SearchContainerProps extends React.PropsWithChildren {
  isListFilled: boolean;
}

const StoresSearchContainer: React.FC<SearchContainerProps> = ({
  children,
  isListFilled,
}: SearchContainerProps) => {
  const [visible, { setTrue, setFalse }] = useBoolean(true);
  const isMobile = useIsMediaQueryUpToMD();
  const alreadySearch = useAlreadySearch();
  const total = useTotalStoresListByLocation();

  useEffect(() => {
    if (
      isMobile &&
      (isListFilled || (alreadySearch && total != null && total < 1))
    ) {
      setFalse();
    }
  }, [isListFilled, total, alreadySearch]);

  if (isMobile) {
    return (
      <UI.Container direction="column" style={MOBILE_STYLE}>
        <UI.Container
          style={css`
            border-top: ${!visible ? '1px solid #939393' : ''};
          `}
          onClick={visible ? setFalse : setTrue}
          w="100%"
          h="50px"
          bg="white"
          p="2"
          justify="center"
          align="center"
        >
          <UI.Icon name={visible ? 'down' : 'up'} size={25} />
        </UI.Container>
        <UI.Container
          direction="column"
          w="100%"
          h="auto"
          bg="white"
          visible={visible}
        >
          {children}
        </UI.Container>
      </UI.Container>
    );
  }
  return (
    <UI.Card
      style={CARD_STYLE as React.CSSProperties}
      id="search-card"
      elevation={5}
    >
      {children}
    </UI.Card>
  );
};

interface SearchResultsTitleProps {
  onReset: F.Function;
}

const SearchResultsTitle: React.FC<SearchResultsTitleProps> = ({
  onReset,
}: SearchResultsTitleProps) => (
  <UI.Container align="center" justify="space-between" w="100%" p="3, 4, 0, 4">
    <UI.Container align="center">
      <UI.Icon name="local" color="primary" />
      <UI.Body2
        i18n={I18N.STORE_LOCATOR.STORES_LOCATIONS}
        modifiers="uppercase"
      />
    </UI.Container>
    <UI.Container align="center" onClick={onReset}>
      <ButtonBase
        style={css`
          padding: 10px;
          border-radius: 5px;
        `}
      >
        <UI.Body2 i18n={RESET} modifiers="uppercase" />
        <UI.Icon name="close" size={30} color="gray.A400" />
      </ButtonBase>
    </UI.Container>
  </UI.Container>
);

interface StoresSearchProps {
  storesList: U.Nullable<number[]>;
  searchError?: U.Nullable<Error>;
  currentTab: number;
  setTab: F.Function<[number]>;
  setError?: F.Function;
}

const StoresListContainer = styled(UI.Container)`
  max-height: 490px;
  overflow: auto;
  width: 100%;
  @media only screen and (max-height: 600px) {
    max-height: 160px;
  }
  @media only screen and (max-width: 1280px) and (max-height: 800px) {
    max-height: 360px;
  }
  @media only screen and (min-width: 1750px) and (min-height: 900px) {
    max-height: 700px;
  }
`;

const StoresSearch: React.FC<React.PropsWithChildren<StoresSearchProps>> = ({
  children,
  storesList,
  searchError,
  setError,
  currentTab,
  setTab,
}: React.PropsWithChildren<StoresSearchProps>) => {
  const isMobile = useIsMediaQueryUpToMD();
  const [isLoadingCurrentLocation, setIsLoading] = useState(false);
  const currentRadius = useValue<number>(STORE_LOCATOR_FIELDPATHS.radius.$path);
  const restartForm = useRestartForm();
  const isLoading = useIsLoadingStoresByLocationSearch();
  const language = useLanguage();
  const isListEmpty = isEmpty(storesList);
  const { tabsTitle, inactiveTabStyles } = useTabsConfigs();
  const resetSearch = useResetStoresByLocationSearch();
  const lastSearchByUserLocation = useLastSearchByUserLocation();
  const onRestartSearch = useCallback(() => {
    resetSearch();
    setTab(0);
    setLastSearchByUserLocation(false);
    restartForm(new StoreLocatorSearchFormValues(currentRadius as number));
  }, [currentRadius, resetSearch, restartForm, setTab]);
  const onChangeTab = useCallback(
    (nextTab: number) => {
      if (nextTab === 1) {
        /**
         * XXX: This was necessary to clean up form values and
         *  avoid re-submittind on switch between tabs,
         *  related to the FastForm onSubmitSuccess bug
         */
        restartForm(new StoreLocatorSearchFormValues(currentRadius as number));
        if (!lastSearchByUserLocation && isListEmpty) {
          resetSearch();
        }
      }
      setTab(nextTab);
    },
    [
      currentRadius,
      isListEmpty,
      lastSearchByUserLocation,
      resetSearch,
      restartForm,
      setTab,
    ],
  );

  return (
    <>
      <UI.Spinner visible={isLoading || isLoadingCurrentLocation} coverScreen />
      <NotificationsHandler mapsError={searchError} />
      <StoresSearchContainer isListFilled={!isListEmpty}>
        <UI.Card.Content style={CARD_CONTENT_STYLE}>
          {!isListEmpty ? (
            <SearchResultsTitle onReset={onRestartSearch} />
          ) : (
            <UI.Card.CollapseArea
              style={css`
                width: 100%;
                height: 100%;
              `}
              in={isListEmpty || isMobile}
            >
              <UI.TabBar
                tabsTitles={tabsTitle}
                titleModifiers="uppercase, secondary"
                titleVariant="body2"
                currentTabIndex={currentTab}
                onSelect={onChangeTab}
                tabStyle={css`
                  padding: 10px;
                `}
                indicatorColor="primary"
                inactiveTabStyles={inactiveTabStyles}
                TabIndicatorProps={TABS_INDICATOR_PROPS}
                variant="fullWidth"
              />
            </UI.Card.CollapseArea>
          )}
          <RefreshSearchOnChangeValuesListener
            shouldSearch={!isListEmpty}
            isCurrentLocationTab={currentTab === 1}
          />
          <TabContentHandler
            currentTab={currentTab}
            language={language}
            setLoading={setIsLoading}
            setError={setError}
            setTab={setTab}
            currentRadius={currentRadius}
          >
            {children}
          </TabContentHandler>
        </UI.Card.Content>
        <StoresListContainer
          w="100%"
          p={isListEmpty ? '' : '1, 0, 0, 0'}
          shadow={5}
        >
          <UI.Card.CollapseArea
            style={css`
              width: 100%;
              height: 100%;
            `}
            in={!isListEmpty}
          >
            <StoreCardsList storesList={storesList} />
          </UI.Card.CollapseArea>
        </StoresListContainer>
        {/* {isMobile ? null : (
          <StoresListContainer
            w="100%"
            p={isListEmpty ? '' : '1, 0, 0, 0'}
            shadow={5}
          >
            <UI.Card.CollapseArea
              style={css`
                width: 100%;
                height: 100%;
              `}
              in={!isListEmpty}
            >
              <StoreCardsList storesList={storesList} />
            </UI.Card.CollapseArea>
          </StoresListContainer>
        )} */}
      </StoresSearchContainer>
    </>
  );
};

interface Props {
  storesList: U.Nullable<number[]>;
}

const StoresSearchWraper: React.FC<React.PropsWithChildren<Props>> = ({
  storesList,
  children,
}: React.PropsWithChildren<Props>) => {
  const RadiusSelect = getRadiusSelect();
  const MeasureUnitFormField = getMeasureUnit();

  const isMobile = useIsMediaQueryUpToMD();
  const [searchError, setError] = useState<U.Nullable<Error>>(null);
  const [currentTab, setTab] = useState(0);
  const isStoresListEmpty = isEmpty(storesList);
  const language = useLanguage();
  const searchStores = useSearchStoresByLocation();
  const customUrlRequestObj = useCustomUrlRequestObj();
  const customUrlPathsAsObj = useCustomUrlPathAsObj();
  const resetSearch = useResetStoresByLocationSearch();
  const searchByProductParams = useSearchByProductParams();
  const customConfigs = useContext(LocatorCustomizationContext);
  const onSearchStore = useCallback(
    async data => {
      resetSearch();
      setLastSearchByUserLocation(false);
      if (!data) return;
      try {
        const result =
          await getStoreLocatorService().googleSearchAddressCoordinates(data);
        setError(null);
        if (currentTab === 1) {
          setTab(0);
        }
        if (result?.lat && result?.lng) {
          searchStores({
            language,
            lat: result?.lat,
            lng: result?.lng,
            radius: data.radius,
            country: data.country,
            ...searchByProductParams,
            ...customUrlRequestObj,
            ...customUrlPathsAsObj,
          });
          setAlreadySearch(true);
        }
      } catch (e) {
        setError(e);
      }
    },
    [
      currentTab,
      customUrlPathsAsObj,
      customUrlRequestObj,
      language,
      resetSearch,
      searchByProductParams,
      searchStores,
    ],
  );

  return (
    <SearchFormProvider onSubmitSuccess={onSearchStore}>
      <MeasureUnitsProvider>
        <StoresSearch
          storesList={storesList}
          searchError={searchError}
          setError={setError}
          currentTab={currentTab}
          setTab={setTab}
        >
          {currentTab === 1 || !isStoresListEmpty ? (
            <UI.Container
              direction="column"
              align="flex-end"
              justify="flex-end"
              p="3"
              w="100%"
            >
              <UI.Container>
                <SearchByProduct hasResults />
                <RadiusSelect
                  style={css`
                    width: ${isMobile ? '100%' : '205px'};
                  `}
                />
              </UI.Container>
              {customConfigs.showMeasureSwitch ? (
                <MeasureUnitFormField />
              ) : null}
            </UI.Container>
          ) : (
            <>
              <UI.Container direction="column" p="2, 3, 0, 3">
                {supportedCountries && supportedCountries.length > 1 ? (
                  <CountrySelectFormField
                    supportedCountries={supportedCountries as CountryCode[]}
                  />
                ) : null}
              </UI.Container>
              {children}
              <SearchByProduct />
              <SearchButton />
            </>
          )}
        </StoresSearch>
      </MeasureUnitsProvider>
    </SearchFormProvider>
  );
};

export default StoresSearchWraper;
