import { createDI } from '@di/core';
import defaultApiV2 from '@mmw/api-v2';
import { EMPTY_OBJECT } from '@shared-utils/object';
import { createStoreModule } from '@zustand-store/core';
import map from 'lodash/map';
import noop from 'lodash/noop';
import qs from 'qs';
import { U } from 'ts-toolbelt';

import logger from './log';
import {
  SearchRequestObject,
  StoreLocatorState,
  TraderSearchSortOrder,
} from './types';

const BASE_PATH = 'v1/consumer/trader';

export const [getStoresSearchParams, setParams] = createDI<
  Partial<SearchRequestObject>
>('STORES_LOCATION_SEARCH');

const DEFAULT_SEARCH_REQUEST = {
  sortOrder: TraderSearchSortOrder.DISTANCE,
  offset: 0,
  // will keep this in 100, maybe we need to implement a paginator later to handle more
  limit: 100,
  returnsDetails: true,
  returnsErpnumber: true,
  returnsDistance: true,
};

export function setStoresSearchParams(
  customParams: Partial<SearchRequestObject>,
) {
  setParams({ ...DEFAULT_SEARCH_REQUEST, ...customParams });
}

function getStoreLocatorSearchPath(
  requestParams: Partial<SearchRequestObject>,
) {
  return `${BASE_PATH}?${qs.stringify(requestParams)}`;
}

const INITIAL_STATE = {
  lastCoordinates: {
    lat: 0,
    lng: 0,
  },
  lastRadius: 0,
  lastRequestObject: null,
  storeIdsList: null,
  itemsById: EMPTY_OBJECT,
  selectedStore: null,
  total: null,
  searchStores: noop,
  resetSearch: noop,
  loading: false,
  error: null,
  alreadySearch: false,
  lastSearchByUserLocation: false,
};

const useStoreLocatorStore = createStoreModule<StoreLocatorState>({
  name: 'store-locator-store',
  initialState: INITIAL_STATE,
  disablePersist: true,
  initializer: set => ({
    ...INITIAL_STATE,
    searchStores: async ({
      language,
      lat,
      lng,
      radius,
      country,
      ...requestParams
    }: Partial<SearchRequestObject>) => {
      set(() => ({
        loading: true,
      }));
      const configuredParams = getStoresSearchParams();
      try {
        logger.debug(
          `Trying to search trader location by latitude=${lat} and longitude=${lng}`,
        );
        const requestObj = {
          language,
          lat,
          lng,
          radius,
          country,
          ...configuredParams,
          ...requestParams,
        };
        const response = await defaultApiV2.get(
          getStoreLocatorSearchPath(requestObj),
        );
        const { data } = response;
        logger.info('Successfully found trader location');
        let mappedItemsById = EMPTY_OBJECT;
        const storeIdsList = map(data.list, item => {
          mappedItemsById = {
            ...mappedItemsById,
            [item.orgunitID]: item,
          };
          return item.orgunitID;
        });
        set(() => ({
          total: data.total,
          storeIdsList,
          itemsById: mappedItemsById,
          lastRequestObject: requestObj,
          lastCoordinates: {
            lat: lat as number,
            lng: lng as number,
          },
          lastRadius: radius,
          loading: false,
        }));
      } catch (error) {
        logger.error('Error when searching trader location, error=%O', error);
        set(() => ({
          error,
          loading: false,
        }));
      }
    },
    resetSearch: () => {
      set(() => ({
        lastCoordinates: {
          lat: 0,
          lng: 0,
        },
        lastRadius: 20,
        storeIdsList: null,
        itemsById: EMPTY_OBJECT,
        selectedStore: null,
        total: null,
        loading: false,
        error: null,
        alreadySearch: false,
        lastSearchByUserLocation: false,
      }));
    },
  }),
});

export function setSelectedStore(storeId: U.Nullable<number>) {
  useStoreLocatorStore.setState({ selectedStore: storeId });
}

export function setAlreadySearch(alreadySearch: boolean) {
  useStoreLocatorStore.setState({ alreadySearch });
}

export function setLastSearchByUserLocation(lastSearchByUserLocation: boolean) {
  useStoreLocatorStore.setState({ lastSearchByUserLocation });
}

export default useStoreLocatorStore;
