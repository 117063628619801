import { getDefaultApiHostUrl } from '@mmw/config';
import {
  ConsumerProductClassOfGoodsJSON,
  ConsumerProductJSON,
} from '@mmw/services-core-consumer-product/types';
import { useResetClassOfGoodsSelectionAction } from '@product-selection-cogs/store-creator';
import { css } from '@ui-system/css';
import { useIsMediaQueryUpToMD } from '@ui-system/media-query';
import UI from '@ui-system/ui';
import React from 'react';
import { F, U } from 'ts-toolbelt';

import SelectorItemCard from './SelectorItemCard';

interface CloseButtonProps {
  onClick?: F.Function;
}

const CloseButton: React.FC<CloseButtonProps> = ({
  onClick,
}: CloseButtonProps) => (
  <UI.Container
    style={css`
      position: absolute;
      top: 0;
      right: 0;
    `}
    bg="primary"
    align="center"
    justify="center"
    p="1"
    onClick={onClick}
  >
    <UI.Icon name="close" size={15} color="white" />
  </UI.Container>
);

interface Props {
  productOrProductGroup: U.Nullable<
    ConsumerProductJSON | ConsumerProductClassOfGoodsJSON
  >;
  onlyProductName?: boolean;
}

const SelectedProductCard: React.FC<Props> = ({
  productOrProductGroup,
  onlyProductName,
}: Props) => {
  const resetCogsSelection = useResetClassOfGoodsSelectionAction();
  const isMobile = useIsMediaQueryUpToMD();
  return (
    <SelectorItemCard
      name={productOrProductGroup?.name as string}
      imgUrl={`${getDefaultApiHostUrl()}${productOrProductGroup?.image}`}
      onlyProductName={onlyProductName}
      selected
      hideImage={isMobile}
    >
      <CloseButton onClick={resetCogsSelection} />
    </SelectorItemCard>
  );
};

export default SelectedProductCard;
