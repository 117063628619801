import { getDefaultApiHostUrl, getStaticUrl } from '@mmw/config';
import { ProductJSON } from '@mmw/services-core-product/types';
import ItemsCarousel from '@mmw/ui-web-blocks-items-carousel';
import { STEPS } from '@retail/constants-i18n';
import { css } from '@ui-system/css';
import { useResponsiveProps } from '@ui-system/media-query';
import { useColors } from '@ui-system/theme/colors';
import UI from '@ui-system/ui';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import React, { useCallback } from 'react';
import { U } from 'ts-toolbelt';

import { useDemoDeviceInteractionCallback } from '../../context';
import ProductRating from './ProductRating';

const { CUSTOM_STEPS: I18N } = STEPS;

interface Props {
  devices: U.Nullable<ProductJSON[]>;
}

const RESPONSIVE = {
  xs: { itemsPerPage: 1 },
  sm: { itemsPerPage: 1 },
  mdToXxl: { itemsPerPage: 2 },
};

const DemoDevices: React.FC<Props> = ({ devices }: Props) => {
  const colors = useColors();
  const getImgSrc = useCallback((device: ProductJSON) => {
    const src = get(device, 'image');
    if (!src) {
      return `${getStaticUrl()}/images/NO_IMAGE.png`;
    }
    return `${getDefaultApiHostUrl()}${src}`;
  }, []);
  const demoDeviceInteractionCallback = useDemoDeviceInteractionCallback();
  const { itemsPerPage } = useResponsiveProps(RESPONSIVE);

  if (isEmpty(devices)) return null;

  return (
    <UI.Container direction="column" m="0, 0, 1, 0" w="100%" gap={4}>
      <UI.Typography
        i18n={I18N.DEMO_DEVICES.LIST_TITLE}
        modifiers="secondary, bold, uppercase"
      />
      <UI.Container
        direction="column"
        style={css`
          width: 100%;
          min-height: fit-content;
        `}
      >
        <ItemsCarousel itemsPerPage={itemsPerPage}>
          {map(devices, device => (
            <UI.Container
              key={device.id}
              w="100%"
              direction="row"
              justify="space-between"
              align="flex-start"
              h={200}
            >
              <UI.Container
                direction="column"
                align="center"
                gap={2}
                style={css`
                  margin: 0 auto;
                  text-align: center;
                `}
              >
                <UI.Container
                  direction="column"
                  align="center"
                  style={css`
                    width: fit-content;
                    border-width: 1px;
                    border-style: solid;
                    border-color: ${colors.gray.A200};
                    border-radius: 4px;
                  `}
                  onClick={
                    demoDeviceInteractionCallback
                      ? () => demoDeviceInteractionCallback(device)
                      : undefined
                  }
                >
                  <UI.Image
                    src={getImgSrc(device)}
                    width={120}
                    height={120}
                    style={css`
                      border-radius: 4px;
                    `}
                  />
                </UI.Container>
                <ProductRating
                  productID={device.id}
                  productName={device.name}
                  quantity={device.quantity}
                />
              </UI.Container>
            </UI.Container>
          ))}
        </ItemsCarousel>
      </UI.Container>
    </UI.Container>
  );
};

export default DemoDevices;
