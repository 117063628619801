import { useGoogleMapsCookies } from '@cookie-consent/core/cookiesState';
import { createDI } from '@di/core';
import { REC_COUNTRIES_CAPITALS } from '@mmw/constants-country-codes';
import contextualConfig from '@mmw/contextual-config';
import { GoogleMap } from '@react-google-maps/api';
import {
  useOriginCoordinates,
  useSearchRequestRadius,
  useSelectedStore,
} from '@store-locator/store-creator';
import { css } from '@ui-system/css';
import { useIsMediaQueryUpToMD } from '@ui-system/media-query';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';
import { U } from 'ts-toolbelt';

import useGoogleLoader from './hooks/useGoogleLoader';
import useMapCustomizations from './hooks/useMapCustomizations';
import MapInterface from './MapInterface';

const { logger, defaultCountry } = contextualConfig.application;

const INITIAL_CONFIG_VALUES = {
  center: {
    // GERMANY
    lat: 52.520007,
    lng: 13.404954,
  },
  zoom: 10,
  origin: null,
};

function getInitialValues() {
  const initialCountryData = find(
    REC_COUNTRIES_CAPITALS,
    item => item.code === defaultCountry,
  );
  if (isEmpty(initialCountryData)) return INITIAL_CONFIG_VALUES;
  return {
    ...INITIAL_CONFIG_VALUES,
    center: {
      lat: initialCountryData?.lat,
      lng: initialCountryData?.lng,
    },
  };
}

const INITIAL_VALUES = getInitialValues();

interface Props {
  storesList: U.Nullable<number[]>;
}

export const [getIsIframe, setIsIframe] = createDI('IS_IFRAME', 'false');

const TraderSearchMap: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  storesList,
}: React.PropsWithChildren<Props>) => {
  const [mapInstance, setMapInstance] =
    useState<U.Nullable<google.maps.Map>>(null);
  const { isLoaded, loadError } = useGoogleLoader();
  const customMapOptions = useMapCustomizations();
  const [zoom, setZoom] = useState(INITIAL_VALUES.zoom);
  const [center, setCenter] = useState(INITIAL_VALUES.center);
  const origin = useOriginCoordinates();
  const radius = useSearchRequestRadius();
  const selectedStoreId = useSelectedStore();
  const { value } = useGoogleMapsCookies();
  const isMobile = useIsMediaQueryUpToMD();
  useEffect(() => {
    if (origin?.lat && origin?.lng) {
      setCenter({ lat: origin?.lat, lng: origin?.lng });
    }
  }, [origin]);

  if (loadError) {
    logger.error('Error on loading google maps script', loadError);
    return null;
  }

  if (!value) {
    return <>{children}</>;
  }

  if (!isLoaded) return null;
  return (
    <>
      <GoogleMap
        id="google-maps-api"
        mapContainerStyle={css`
          height: 100%;
          width: 100vw;
          margin-top: ${isMobile && getIsIframe() === 'false' ? '20px' : '0px'};
        `}
        center={center}
        zoom={zoom}
        options={customMapOptions}
        onLoad={map => {
          setMapInstance(map);
        }}
        onCenterChanged={() => {
          const currentZoom = mapInstance?.getZoom();
          if (currentZoom && currentZoom > INITIAL_VALUES.zoom + 1) {
            mapInstance?.setZoom(INITIAL_VALUES.zoom);
          }
        }}
      >
        <MapInterface
          origin={origin}
          radius={radius}
          storesList={storesList}
          selectedStoreId={selectedStoreId}
          setZoom={setZoom}
        />
        {children}
      </GoogleMap>
    </>
  );
};

export default TraderSearchMap;
