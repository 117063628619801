import {
  setSelectedStore,
  useSelectedStore,
  useStoreItemById,
} from '@store-locator/store-creator';
import { css } from '@ui-system/css';
import UI from '@ui-system/ui';
import React, { useCallback, useMemo, useRef } from 'react';

import {
  useConvertDistance,
  useCurrentMeasureUnits,
} from '../context/measure-units';
import Details from './details';
import OpeningTimes from './OpeningTimes';
import StoreName from './StoreName';

interface ArrowProps {
  expanded: boolean;
}

const Arrow: React.FC<ArrowProps> = ({ expanded }: ArrowProps) => (
  <UI.Icon name={expanded ? 'down' : 'next'} size={15} />
);

interface Props {
  storeId: number;
}

const ExpansibleCard: React.FC<Props> = React.memo(({ storeId }: Props) => {
  // const [expanded, { setTrue, setFalse }] = useBoolean();
  const selectedStoreId = useSelectedStore();
  const store = useStoreItemById(storeId);
  const expanded = useMemo(
    () => storeId === selectedStoreId,
    [selectedStoreId, storeId],
  );
  const contentRef = useRef<HTMLInputElement>(null);
  const onExpandCard = useCallback(
    e => {
      e?.preventDefault();
      e?.stopPropagation();
      if (expanded) {
        setSelectedStore(null);
        return;
      }
      setSelectedStore(storeId);
    },
    [expanded, storeId],
  );
  const distance = useConvertDistance(store?.address.distance as number);
  const currentMeasureUnit = useCurrentMeasureUnits();

  // useEffect(() => {
  //   if (contentRef && contentRef.current) {
  //     setTimeout(
  //       () =>
  //         contentRef?.current?.scrollIntoView?.({
  //           // XXX: had to add a check because smooth behavior its not working on chrome
  //           // behavior: isChrome ? 'auto' : 'smooth',
  //           behavior: 'smooth',
  //           block: 'center',
  //         }),
  //       250,
  //     );
  //   }
  //   // if (selectedStore?.orgunitID === store?.orgunitID) {
  //   //   setFalse();
  //   // }
  // }, [expanded]);

  return (
    <UI.Card
      style={css`
        min-height: 100px;
      `}
      square
      clickable
    >
      <UI.Card.Content onClick={onExpandCard}>
        <UI.Container justify="space-between" align="center">
          <StoreName storeId={storeId} limitWidth />
          <UI.Container align="center" gap={3}>
            <UI.H5
              style={css`
                margin-right: 20px;
              `}
              modifiers="grayA300"
              suffix={` ${currentMeasureUnit}`}
            >
              {distance?.toFixed(2)}
            </UI.H5>
            <Arrow expanded={expanded} />
          </UI.Container>
        </UI.Container>
      </UI.Card.Content>
      <UI.Card.CollapseArea ref={contentRef} in={expanded}>
        <Details storeId={storeId} contentPadding="2, 3" />
        <OpeningTimes storeId={storeId} />
      </UI.Card.CollapseArea>
    </UI.Card>
  );
});

export default ExpansibleCard;
