import { getDefaultApiHostUrl } from '@mmw/config';
import { ConsumerProductJSON } from '@mmw/services-core-consumer-product/types';
import {
  useProductsList,
  useSelectAllProductsStatus,
  useSelectedClassOfGoods,
  useSelectProductOrClassOfGood,
  useSetSelectAllProductsAction,
} from '@product-selection-cogs/store-creator';
import { css } from '@ui-system/css';
import {
  useIsMediaQueryUpToSM,
  useIsTabletMediaQuery,
} from '@ui-system/media-query';
import UI from '@ui-system/ui';
import findLastKey from 'lodash/findLastKey';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import React, { useCallback, useState } from 'react';
import { F, U } from 'ts-toolbelt';

import ConfirmSelectionButton from './ConfirmSelectionButton';
import SelectorItemCard from './SelectorItemCard';

interface Props {
  onConfirmSelection?: F.Function;
}

const ProductSelector: React.FC<Props> = ({ onConfirmSelection }: Props) => {
  const [selectedProductItem, setProductItem] =
    useState<U.Nullable<ConsumerProductJSON>>(null);
  const products = useProductsList();
  const selectedCogs = useSelectedClassOfGoods();
  const lastKey = findLastKey(selectedCogs);
  const selectProductOrCogAction = useSelectProductOrClassOfGood();
  const selectedAllProducts = useSelectAllProductsStatus();
  const selectAllProducts = useSetSelectAllProductsAction();
  const isMobile = useIsTabletMediaQuery();
  const isPhone = useIsMediaQueryUpToSM();

  const onConfirm = useCallback(() => {
    if (selectedAllProducts) {
      if (products.total === 1) {
        selectProductOrCogAction((products.list as ConsumerProductJSON[])[0]);
        return;
      }
      selectProductOrCogAction(selectedCogs[lastKey as string]);
    } else if (!selectedAllProducts && selectedProductItem) {
      selectProductOrCogAction(selectedProductItem);
    }
    if (onConfirmSelection) {
      onConfirmSelection();
    }
  }, [
    lastKey,
    onConfirmSelection,
    products.list,
    products.total,
    selectProductOrCogAction,
    selectedAllProducts,
    selectedCogs,
    selectedProductItem,
  ]);

  if (products?.list && isEmpty(products?.list)) return null;

  return (
    <UI.Container direction="column" w="100%">
      <UI.Container
        style={css`
          ${isPhone
            ? `
            overflow-x: auto;
            padding: 10px 0;
          `
            : `
              overflow-y: auto;
              max-height: ${isMobile ? '90vh' : '300px'};
              overflow-x: hidden;
            `}
        `}
        modifiers="grid4col, gridRowGap4"
        responsive="upToSm.modifiers=none, gridJustifyCenter;upToSm.p=0,0,3,0"
      >
        {map(products.list as ConsumerProductJSON[], item => (
          <SelectorItemCard
            name={item.name}
            imgUrl={`${getDefaultApiHostUrl()}${item.image}`}
            onClick={() => {
              if (selectedAllProducts) {
                selectAllProducts();
              }
              setProductItem(item);
            }}
            selected={
              selectedProductItem?.id === item.id || selectedAllProducts
            }
          />
        ))}
      </UI.Container>
      <UI.Container
        style={css`
          ${isMobile
            ? `
            position: fixed;
            bottom: 20px;                                 
          `
            : ''}
        `}
        justify="center"
        m="7, 0, 0, 0"
        w="100%"
        responsive="upToMd.m=0;upToSm.w=auto"
      >
        <ConfirmSelectionButton
          style={css`
            width: 350px;
          `}
          onConfirm={onConfirm}
          disabled={!selectedAllProducts && !selectedProductItem}
        />
      </UI.Container>
    </UI.Container>
  );
};

export default ProductSelector;
