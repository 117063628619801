import I18N from '@mmw/constants-i18n-orgunit';
import { css } from '@ui-system/css';
import UI from '@ui-system/ui';
import noop from 'lodash/noop';
import React from 'react';
import { F, U } from 'ts-toolbelt';

interface Props {
  onClick: F.Function;
  selectedProduct?: U.Nullable<React.ReactElement>;
  foundResults?: boolean;
}

const AddProductToSearchAction: React.FC<Props> = ({
  onClick,
  selectedProduct,
  foundResults,
}: Props) => {
  const hasProduct = !!selectedProduct;

  if (foundResults) {
    return (
      <UI.Container direction="column" p="0, 2, 0, 0">
        <UI.Body2
          i18n={I18N.STORE_LOCATOR.SEARCHING_FOR_PRODUCT_OR_GROUP}
          suffix=":"
        />
        <UI.Container>{selectedProduct}</UI.Container>
      </UI.Container>
    );
  }

  return (
    <UI.Card
      style={css`
        margin: 10px;
        margin-top: 0;
      `}
      elevation={4}
    >
      <UI.Card.ActionArea
        style={css`
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          padding: 10px;
        `}
        onClick={hasProduct ? noop : onClick}
      >
        <UI.Body2 i18n={I18N.STORE_LOCATOR.SEARCH_BY_AVAILABLE_PRODUCTS} />
        {hasProduct ? null : <UI.Icon name="next" color="primary" size={25} />}
      </UI.Card.ActionArea>
      {hasProduct ? (
        <UI.Card.Content
          style={css`
            padding: 0;
            padding-bottom: 15px;
            padding-left: 10px;
            max-width: 250px;
          `}
        >
          {selectedProduct}
        </UI.Card.Content>
      ) : null}
    </UI.Card>
  );
};

export default AddProductToSearchAction;
