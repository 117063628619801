import qs from 'qs';

import { ConsumerClassOfGoodsRequestParams } from './types';

const BASE_PATH = '/v1/consumer/product';
const BASE_PATH_V2 = '/v2/consumer/product';

export const GetProductsPath = (): string => `${BASE_PATH_V2}`;

export const ConsumerProductRatingPath = (productId: number): string =>
  `${BASE_PATH}/${productId}/rating`;

export const GetConsumerProductsClassOfGoodsPath = (
  language: string,
  requestOptions: ConsumerClassOfGoodsRequestParams,
): string =>
  `${BASE_PATH}/classofgoods?${qs.stringify({
    language,
    ...requestOptions,
  })}`;
