import FIELD_PATHS from '@mmw/constants-fields-names';

const labelNamespace = 'label';
const addressNamespace = 'address';
const placeholderNamespace = 'placeholder';

const {
  PERSON: PERSON_FIELDS,
  ADDRESS: ADDRESS_FIELDS,
  CONSUMER: CONSUMER_FIELDS,
  COMPANY: COMPANY_FIELDS,
} = FIELD_PATHS;

const PERSON = {
  DOMKEYSALUTATION: {
    LABEL: {
      key: `${labelNamespace}.${PERSON_FIELDS.DOMKEYSALUTATION}`,
      message: 'Salutation',
    },
    PLACEHOLDER: {
      key: `${placeholderNamespace}.${PERSON_FIELDS.DOMKEYSALUTATION}`,
      message: 'Please select a Salutation',
    },
  },
  DOMKEYTITLE: {
    LABEL: {
      key: `${labelNamespace}.${PERSON_FIELDS.DOMKEYTITLE}`,
      message: 'Title',
    },
    PLACEHOLDER: {
      key: `${placeholderNamespace}.${PERSON_FIELDS.DOMKEYTITLE}`,
      message: 'Please select a Title',
    },
  },
  NAME: {
    LABEL: {
      key: `${labelNamespace}.${CONSUMER_FIELDS.CONSUMER_NAME}`,
      message: 'Name',
    },
  },
  FIRSTNAME: {
    LABEL: {
      key: `${labelNamespace}.${PERSON_FIELDS.FIRSTNAME}`,
      message: 'First Name',
    },
  },
  LASTNAME: {
    LABEL: {
      key: `${labelNamespace}.${PERSON_FIELDS.LASTNAME}`,
      message: 'Last Name',
    },
  },
  BIRTH_DATE: {
    LABEL: {
      key: `${labelNamespace}.${PERSON_FIELDS.BIRTHDATE}`,
      message: 'Birth date',
    },
  },
  COMPANY: {
    LABEL: {
      key: `${labelNamespace}.${PERSON_FIELDS.COMPANY}`,
      message: 'Company',
    },
  },
  BUSINESS_CUSTOMER: {
    LABEL: {
      key: `${labelNamespace}.businessCustomer`,
      message: 'Business Customer',
    },
  },
  COMPANY_NAME: {
    LABEL: {
      key: `${labelNamespace}.${PERSON_FIELDS.COMPANY_NAME}`,
      message: 'Company Name',
    },
  },
  FAX: {
    LABEL: {
      key: `${labelNamespace}.${COMPANY_FIELDS.FAX}`,
      message: 'Fax',
    },
  },
  HOMEPAGE: {
    LABEL: {
      key: `${labelNamespace}.${COMPANY_FIELDS.HOMEPAGE}`,
      message: 'Homepage',
    },
  },
  LEGAL_FORM: {
    LABEL: {
      key: `${labelNamespace}.${COMPANY_FIELDS.LEGAL_FORM}`,
      message: 'Legal Form',
    },
    PLACEHOLDER: {
      key: `${placeholderNamespace}.${COMPANY_FIELDS.LEGAL_FORM}`,
      message: 'Please select a Legal Form',
    },
  },
  MANAGEMENT_BODY: {
    LABEL: {
      key: `${labelNamespace}.${COMPANY_FIELDS.MANAGEMENTBODY}`,
      message: 'Managment Body',
    },
  },
  JURISDICTION: {
    LABEL: {
      key: `${labelNamespace}.${COMPANY_FIELDS.MANAGEMENTBODY}`,
      message: 'Jurisdiction',
    },
  },
  TAX_NUMBER: {
    LABEL: {
      key: `${labelNamespace}.${COMPANY_FIELDS.TAXNUMBER}`,
      message: 'Tax Number',
    },
  },
  SALES_TAX_ID: {
    LABEL: {
      key: `${labelNamespace}.${COMPANY_FIELDS.SALESTAXID}`,
      message: 'Sales Tax ID',
    },
  },
  GLN_ILN: {
    LABEL: {
      key: `${labelNamespace}.${COMPANY_FIELDS.GLN}`,
      message: 'GLN (ILN)',
    },
  },
  VAT: {
    LABEL: {
      key: `${labelNamespace}.vat`,
      message: 'Umst.ID (VAT-ID)',
    },
  },
  EMAIL: {
    LABEL: {
      key: `${labelNamespace}.${PERSON_FIELDS.EMAIL}`,
      message: 'Email',
    },
  },
  PHONE: {
    LABEL: {
      key: `${labelNamespace}.${PERSON_FIELDS.PHONE}`,
      message: 'Phone',
    },
  },
  MOBILE_PHONE: {
    LABEL: {
      key: `${labelNamespace}.${PERSON_FIELDS.MOBILE_PHONE}`,
      message: 'Mobile phone',
    },
  },
  STATE: {
    LABEL: {
      key: `${labelNamespace}.${PERSON_FIELDS.STATE}`,
      message: 'State',
    },
  },
  ADDRESS: {
    LABEL: {
      key: `${labelNamespace}.${addressNamespace}`,
      message: 'Address',
    },
    SHIPPING_NAME: {
      LABEL: {
        key: `${labelNamespace}.${addressNamespace}.${ADDRESS_FIELDS.NAME1}`,
        message: 'Shipping Name',
      },
    },
    SHIPPING_SURNAME: {
      LABEL: {
        key: `${labelNamespace}.${addressNamespace}.${ADDRESS_FIELDS.NAME2}`,
        message: 'Shipping Name',
      },
    },
    COUNTRY: {
      LABEL: {
        key: `${labelNamespace}.${addressNamespace}.${ADDRESS_FIELDS.COUNTRY}`,
        message: 'Country',
      },
      PLACEHOLDER: {
        key: `${placeholderNamespace}.${ADDRESS_FIELDS.COUNTRY}`,
        message: 'Please select a Country',
      },
    },
    STATE: {
      LABEL: {
        key: `${labelNamespace}.${addressNamespace}.${ADDRESS_FIELDS.STATE}`,
        message: 'State',
      },
      PLACEHOLDER: {
        key: `${placeholderNamespace}.${ADDRESS_FIELDS.STATE}`,
        message: 'State',
      },
    },
    ZIPCODE: {
      LABEL: {
        key: `${labelNamespace}.${addressNamespace}.${ADDRESS_FIELDS.ZIPCODE}`,
        message: 'Zipcode',
      },
    },
    STREET: {
      LABEL: {
        key: `${labelNamespace}.${addressNamespace}.${ADDRESS_FIELDS.STREET}`,
        message: 'Street',
      },
    },
    NUMBER: {
      LABEL: {
        key: `${labelNamespace}.${addressNamespace}.${ADDRESS_FIELDS.NUMBER}`,
        message: 'Nr.',
      },
    },
    ADDRESS_LINE_1: {
      LABEL: {
        key: `${labelNamespace}.address`,
        message: 'Address',
      },
    },
    ADDRESS_LINE_2: {
      LABEL: {
        key: `${labelNamespace}.${addressNamespace}.addressLine2`,
        message: 'Address line 2',
      },
    },
    CITY: {
      LABEL: {
        key: `${labelNamespace}.${addressNamespace}.${ADDRESS_FIELDS.CITY}`,
        message: 'City',
      },
    },
    TOWN: {
      LABEL: {
        key: `${labelNamespace}.${addressNamespace}.town`,
        message: 'Town',
      },
    },
  },
  ERP_CONSUMER: {
    LABEL: {
      key: `${labelNamespace}.${PERSON_FIELDS.ERP_CONSUMER}`,
      message: 'Customer number (ERP)',
    },
  },
  BIRTHDATE: {
    LABEL: {
      key: `${labelNamespace}.${PERSON_FIELDS.BIRTHDATE}`,
      message: 'Birthdate',
    },
  },
  FULL_NAME: {
    LABEL: {
      key: `${labelNamespace}.fullName`,
      message: 'Full Name',
    },
  },
  POSITION: {
    LABEL: {
      key: `${labelNamespace}.position`,
      message: 'Position',
    },
  },
};

export default PERSON;
