import { fastHash } from '@hasher/object-hash';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import SelectMui from '@material-ui/core/Select';
import limitString from '@mmw/constants-string-utils';
import { useTranslate } from '@mmw/utils-text-utils/hooks';
import { css } from '@ui-system/css';
import componentModifiers from '@ui-system/default-modifiers/form/select';
import defaultProps from '@ui-system/default-props/form/multiple-select';
import {
  useSelectContainerStyle,
  useSelectStyle,
} from '@ui-system/default-styles';
import { MultipleSelectProps } from '@ui-system/interfaces-form';
import UI from '@ui-system/ui';
import { find, indexOf, isObject, join, split, toUpper } from 'lodash';
import compact from 'lodash/compact';
import map from 'lodash/map';
import React, { ElementType, useCallback, useMemo } from 'react';

function getLabelModifiers(
  error: boolean,
  caption: boolean,
  labelModifiers?: string,
) {
  const modifiers = ['grayA400'];
  if (error || caption) {
    modifiers.push('error');
  }
  if (labelModifiers) {
    modifiers.push(labelModifiers);
  }
  return modifiers.join(',');
}
const MultipleSelect: React.FC<MultipleSelectProps> = ({
  options,
  disabled,
  error,
  caption,
  label,
  onChange,
  value,
  style,
  modifiers,
  placeholderI18n,
  IconComponent,
  selectInputStyle,
  labelModifiers,
  ...props
}: MultipleSelectProps) => {
  const finalSelectContainerStyle = useSelectContainerStyle(style, null, null);
  const finalSelectStyle = useSelectStyle(
    selectInputStyle,
    `${modifiers}${error && ', error'}${disabled && ', disabled'}`,
    componentModifiers,
    { disabled },
  );
  const translate = useTranslate();
  const handleChange = useCallback(
    event => {
      const {
        target: { value: eventValue },
      } = event;
      if (onChange) {
        onChange(
          typeof eventValue === 'string' ? split(eventValue, ',') : eventValue,
        );
      }
    },
    [onChange],
  );

  const texts = useMemo(() => {
    const labelText = toUpper(translate(label));
    return {
      placeholder: translate(placeholderI18n),
      // label: props.required ? `* ${labelText}` : labelText,
      label: labelText,
      caption: translate(caption),
    };
  }, [caption, label, placeholderI18n, translate]);

  const renderValue = useCallback(
    (selected: string[]) => {
      const selectedLabels = map(selected, item => {
        const labelToTranslate = find(
          options,
          option => option.value === item,
        )?.label;
        if (isObject(labelToTranslate)) return translate(labelToTranslate);
        return labelToTranslate;
      });
      if (selected.length === 0) {
        return (
          <UI.Body2 modifiers="grayA400, capitalize" i18n={placeholderI18n} />
        );
      }
      return (
        <UI.Caption>
          {limitString(join(compact(selectedLabels), ', '), 50)}
        </UI.Caption>
      );
    },
    [options, placeholderI18n, translate],
  );

  return (
    <UI.Container
      style={finalSelectContainerStyle}
      direction="column"
      w={(finalSelectStyle?.width as string) || 'auto'}
    >
      <FormControl
        disabled={disabled}
        error={error}
        style={css`
          width: 100%;
        `}
      >
        {texts.label || props.required ? (
          <UI.Overline
            modifiers={getLabelModifiers(
              !!error,
              !!texts.caption,
              labelModifiers,
            )}
            style={css`
              line-height: 16px;
              height: 16px;
            `}
          >
            {`${texts.label || ''}${props.required ? ' * ' : ''}`}
          </UI.Overline>
        ) : null}
        {/* @ts-ignore */}
        <SelectMui
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          value={value}
          onChange={handleChange}
          disabled={disabled}
          error={error}
          style={finalSelectStyle}
          IconComponent={IconComponent as ElementType}
          displayEmpty
          disableUnderline
          renderValue={renderValue}
          multiple
          {...props}
        >
          {placeholderI18n && (
            <MenuItem disabled>
              <UI.Caption modifiers={modifiers} i18n={placeholderI18n} />
            </MenuItem>
          )}
          {map(options, option => (
            <MenuItem value={option.value as string} key={fastHash(option)}>
              <Checkbox checked={indexOf(value, option.value as string) > -1} />
              <UI.Caption modifiers={modifiers} i18n={option.label} />
            </MenuItem>
          ))}
        </SelectMui>
        <UI.Caption modifiers="error" i18n={caption} />
      </FormControl>
    </UI.Container>
  );
};

MultipleSelect.defaultProps = defaultProps;

export default MultipleSelect;
