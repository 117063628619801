import { EMPTY_ARRAY } from '@mmw/constants-utils';
import { ProductJSON } from '@mmw/services-core-product/types';
import { useParams } from '@mmw/ui-web-libraries-react-router/hooks';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';
import React, { createContext, useContext, useMemo } from 'react';
import { F, U } from 'ts-toolbelt';
import strReplace from 'voca/replace';

import { CustomCompStoreProp, LocatorCustomizationContextValue } from './types';

export * from './types';

const INITIAL_VALUES = {
  CustomMapMark: null,
  CustomErpBadge: null,
  CustomStoreName: null,
  CustomTooltipItem: null,
  CustomCardTitle: null,
  customUrlRequestParams: null,
  customUrlPathsAsParams: EMPTY_ARRAY,
  productRatingConfig: null,
  showMeasureSwitch: false,
  demoDeviceInteractionCallback: noop,
};

export const LocatorCustomizationContext =
  createContext<LocatorCustomizationContextValue>(INITIAL_VALUES);

export const useCustomMapMarker = (): U.Nullable<
  React.FC<CustomCompStoreProp>
> => {
  const customConfigs = useContext(LocatorCustomizationContext);
  return useMemo(() => get(customConfigs, 'CustomMapMark'), [customConfigs]);
};

export const useCustomErpBadge = (): U.Nullable<
  React.FC<CustomCompStoreProp>
> => {
  const customConfigs = useContext(LocatorCustomizationContext);
  return useMemo(() => get(customConfigs, 'CustomErpBadge'), [customConfigs]);
};

export const useCustomStoreName = (): U.Nullable<
  React.FC<CustomCompStoreProp>
> => {
  const customConfigs = useContext(LocatorCustomizationContext);
  return useMemo(() => get(customConfigs, 'CustomStoreName'), [customConfigs]);
};

export const useCustomTooltipItem = (): U.Nullable<
  React.FC<Record<string, any>>
> => {
  const customConfigs = useContext(LocatorCustomizationContext);
  return useMemo(
    () => get(customConfigs, 'CustomTooltipItem'),
    [customConfigs],
  );
};

export const useCustomCardTitle = (): U.Nullable<
  React.FC<CustomCompStoreProp<{ limitWidth?: boolean }>>
> => {
  const customConfigs = useContext(LocatorCustomizationContext);
  return useMemo(() => get(customConfigs, 'CustomCardTitle'), [customConfigs]);
};

export const useCustomUrlRequestObj = (): Record<string, any> => {
  const customConfigs = useContext(LocatorCustomizationContext);
  return useMemo(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const customRequestObj = {};
    const namedParams = get(customConfigs, 'customUrlRequestParams');
    forEach(namedParams, param => {
      const value = urlParams.get(param);
      if (value) {
        customRequestObj[param] = Number(value) || value;
      }
    });
    return customRequestObj;
  }, [customConfigs]);
};

export const useCustomUrlPathAsObj = (): Record<string, any> => {
  const { group } = useParams<{ group: string }>();
  const customConfigs = useContext(LocatorCustomizationContext);
  return useMemo(() => {
    const customPathsConfigs = get(customConfigs, 'customUrlPathsAsParams');
    const customRequestObj = {};
    forEach(customPathsConfigs, config => {
      if (
        !isEmpty(config.expectedValues) &&
        includes(config.expectedValues, group) &&
        config.paramName
      ) {
        if (config?.customStringValue) {
          const value = strReplace(
            config.customStringValue,
            config.paramName,
            group,
          );
          customRequestObj[config.paramName] = value;
        } else {
          customRequestObj[config.paramName] = group;
        }
      }
    });
    return customRequestObj;
  }, [customConfigs, group]);
};

export const useDemoDeviceInteractionCallback = (): U.Nullable<
  F.Function<[ProductJSON]>
> => {
  const customConfigs = useContext(LocatorCustomizationContext);
  return useMemo(
    () => get(customConfigs, 'demoDeviceInteractionCallback'),
    [customConfigs],
  );
};

interface Props {
  configs?: U.Nullable<LocatorCustomizationContextValue>;
}

const LocatorCustomizationProvider: React.FC<
  React.PropsWithChildren<Props>
> = ({ configs, children }: React.PropsWithChildren<Props>) => (
  <LocatorCustomizationContext.Provider value={configs || INITIAL_VALUES}>
    {children}
  </LocatorCustomizationContext.Provider>
);

export default LocatorCustomizationProvider;
