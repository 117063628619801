const namespace = 'navigation';

const ROUTES = {
  DASHBOARD: {
    key: `${namespace}.dashboard`,
    message: 'Dashboard',
  },
  // REGISTRATIONS: {
  //   key: `${namespace}.registrations`,
  //   message: 'Registrations',
  // },
  PARTNER: {
    key: `${namespace}.partner`,
    message: 'Partner',
  },
  PARTNERS: {
    key: `${namespace}.partners`,
    message: 'Partners',
  },
  PARTNER_DATA: {
    key: `${namespace}.partnerData`,
    message: 'Partner Data',
  },
  PRODUCTS: {
    key: `${namespace}.products`,
    message: 'Products',
  },
  MARKETING_BUDGET: {
    key: `${namespace}.marketingBudget`,
    message: 'Marketing Budget',
  },
  CERTIFICATES: {
    key: `${namespace}.certificates`,
    message: 'Certificates',
  },
  REPORTS: {
    key: `${namespace}.reports`,
    message: 'REPORTS',

    STORE_LOCATOR: {
      key: `${namespace}.storeLocator`,
      message: 'Store Locator',
    },
    PROPAGE_REPORTS: {
      key: `${namespace}.proPageReports`,
      message: 'ProPage Reports',
    },
    GLOBAL_CHANNEL_REPORTS: {
      key: `${namespace}.globalChannelReports`,
      message: 'Global Channel Reports',
    },
    FIRST_REGISTRATION_REPORT: {
      key: `${namespace}.firstRegistrationReport`,
      message: 'First Registration Report',
    },
    REC_SELECT_APAC: {
      key: `${namespace}.recSelectApac`,
      message: 'REC Select APAC',
    },
    REC_SELECT_EMEA: {
      key: `${namespace}.recSelectEmea`,
      message: 'REC Select EMEA',
    },
    ANALYTICS: {
      key: `${namespace}.analytics`,
      message: 'Google Analytics',
    },
  },
  PROJECTS: {
    key: `${namespace}.projects`,
    message: 'Projects',
  },
  HELP: {
    key: `${namespace}.help`,
    message: 'Help',
  },
};

const ROUTES_OBJ = {
  ROUTES,
};

export default ROUTES_OBJ;
