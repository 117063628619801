import { CONFIRM_SELECTION } from '@mmw/constants-i18n-buttons';
import { ButtonProps } from '@ui-system/interfaces-button';
import UI from '@ui-system/ui';
import React from 'react';
import { F } from 'ts-toolbelt';

interface Props extends ButtonProps {
  onConfirm: F.Function;
}

const ConfirmSelectionButton: React.FC<Props> = ({
  onConfirm,
  ...props
}: Props) => (
  <UI.Button i18n={CONFIRM_SELECTION} onClick={onConfirm} {...props} />
);

export default ConfirmSelectionButton;
