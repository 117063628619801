const labelNamespace = 'label';

const ADDITIONAL_INFO = {
  ADDITIONAL_INFO: {
    LABEL: {
      key: `${labelNamespace}.additionalInfo`,
      message: 'Additional info',
    },
  },
  CONTACT_MEANS: {
    LABEL: {
      key: `${labelNamespace}.contactMeans`,
      message: 'How did you heard about us?',
    },
  },
  BRAND: {
    LABEL: {
      key: `${labelNamespace}.brand`,
      message: 'Brand',
    },
  },
  YOUR_CO_OPERATIONS: {
    LABEL: {
      key: `${labelNamespace}.yourCoOperations`,
      message: 'Your cooperations',
    },
  },
  NUMBER_OF_POINT_OF_SALES: {
    LABEL: {
      key: `${labelNamespace}.numberOfPointOfSales`,
      message: 'Number of point of sales',
    },
  },
};

export default ADDITIONAL_INFO;
