import { useMemo } from 'react';

import { CustomizationOptions } from '../types';

// XXX: Initially this was a hook to customize map base colors

const useMapCustomizations = (): CustomizationOptions =>
  // const colors = useColors();
  useMemo(
    () => ({
      minZoom: 5,
      restriction: {
        latLngBounds: { north: 85, south: -85, west: -180, east: 180 },
      },
      zoomControl: true,
      zoomControlOptions: {
        // Zoom control positioned on top right corner of the map
        position: 7.0,
      },
      // XXX: Had to add this config prop here to solve double click on tooltip problem
      disableDoubleClickZoom: true,
      streetViewControl: true,
      streetViewControlOptions: {
        position: 7.0,
      },
      addressControlOptions: {
        position: 0.0,
      },
      linksControl: false,
      panControl: false,
      enableCloseButton: false,
      fullscreenControl: false,
      styles: [
        {
          elementType: 'geometry',
          stylers: [
            {
              // color: colors.background,
            },
          ],
        },
        // {
        //   elementType: 'labels.icon',
        //   stylers: [
        //     {
        //       visibility: 'off',
        //     },
        //   ],
        // },
        {
          elementType: 'labels.text.fill',
          stylers: [
            {
              // color: '#616161',
            },
          ],
        },
        {
          elementType: 'labels.text.stroke',
          stylers: [
            {
              // color: '#f5f5f5',
            },
          ],
        },
        {
          featureType: 'administrative.land_parcel',
          elementType: 'labels.text.fill',
          stylers: [
            {
              // color: '#bdbdbd',
            },
          ],
        },
        {
          featureType: 'poi.park',
          elementType: 'geometry',
          stylers: [
            {
              // color: colors.gray.A100,
            },
          ],
        },
        {
          featureType: 'poi.park',
          elementType: 'labels.text.fill',
          stylers: [
            {
              // color: '#9e9e9e',
            },
          ],
        },
        {
          featureType: 'road',
          elementType: 'geometry',
          stylers: [
            {
              // color: '#ffffff',
            },
            {
              weight: 0.5,
            },
          ],
        },
        {
          featureType: 'water',
          elementType: 'geometry',
          stylers: [
            {
              // color: '#98A8B7',
            },
          ],
        },
        {
          featureType: 'road.arterial',
          elementType: 'geometry.fill',
          stylers: [
            {
              // color: colors.primary.main,
            },
            {
              weight: 0.6,
            },
          ],
        },
        {
          featureType: 'road.arterial',
          elementType: 'geometry',
          stylers: [
            {
              // color: colors.primary.main,
            },
            {
              weight: 0.4,
            },
          ],
        },
        {
          featureType: 'road.arterial',
          elementType: 'labels.text.fill',
          stylers: [
            {
              // color: '#757575',
            },
          ],
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry',
          stylers: [
            {
              // color: colors.gray.A200,
            },
            {
              weight: 0.5,
            },
          ],
        },
        {
          featureType: 'road.highway',
          elementType: 'labels.text.fill',
          stylers: [
            {
              // color: '#616161',
            },
          ],
        },
        {
          featureType: 'road.local',
          elementType: 'labels.text.fill',
          stylers: [
            {
              // color: '#9e9e9e',
            },
          ],
        },
        {
          featureType: 'transit.line',
          elementType: 'geometry',
          stylers: [
            {
              // color: colors.gray.A300,
            },
          ],
        },
        {
          featureType: 'transit.station',
          elementType: 'geometry',
          stylers: [
            {
              // color: '#eeeeee',
            },
          ],
        },
      ],
    }),
    [],
  );
export default useMapCustomizations;
