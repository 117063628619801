import { css } from '@ui-system/css';
import {
  FORM_CHECKBOX_GROUP_UUID,
  FORM_CHECKBOX_UUID,
  FORM_INPUT_CONTAINER_UUID,
  FORM_INPUT_UUID,
  FORM_RADIO_GROUP_UUID,
  FORM_SELECT_ARROW_DOWN_UUID,
  FORM_SELECT_CONTAINER_UUID,
  FORM_SELECT_DISABLED_UUID,
  FORM_SELECT_PLACEHOLDER_UUID,
  FORM_SELECT_UUID,
  FORM_SWITCH_UUID,
  FORM_TIME_PICKER_UUID,
  FROM_DATE_PICKER_UUID,
  InputProps,
  SelectProps,
  Style,
} from '@ui-system/interfaces';
import { iPadFactor } from '@ui-system/media-query';
import pxPerfect from '@ui-system/px-perfect';
import { makeStyle } from '@ui-system/style';
import { isReactNative } from 'is-react-native';
import { isString, join } from 'lodash';

import { makeFinalStyleHook, makeStyleHookWithMergedCtx } from './internal';

// INPUT
const useInputDefaultStyle = makeStyle<{ disabled: boolean }>(
  ({ theme, props }) => css`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    color: ${props?.disabled
      ? theme.colors[theme.mode].gray.A400
      : theme.typography.colors[theme.mode].onBackground};
    font-family: ${theme.typography.fontFamily.regular};
    font-size: ${pxPerfect(iPadFactor(20))};
    opacity: ${props?.disabled ? 0.8 : 1};
    flex-wrap: nowrap;
    height: ${isReactNative() ? pxPerfect(iPadFactor(43)) : 'auto'};
    margin: 0;
  `,
);

const useInputContainerDefaultStyle = makeStyle<{ disabled: boolean }>(
  ({ theme, props }) => css`
    border-top-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;
    border-color: ${props?.disabled
      ? theme.colors[theme.mode].gray.A400
      : theme.colors[theme.mode].primary.main};
    border-radius: 0;
    border-width: 1px;
    width: 100%;
    overflow: hidden;
    touch-action: none;
  `,
);

export const useInputStyle = makeFinalStyleHook<Partial<InputProps>>(
  FORM_INPUT_UUID,
  useInputDefaultStyle,
);

export const useInputContainerStyle = makeFinalStyleHook<Partial<InputProps>>(
  FORM_INPUT_CONTAINER_UUID,
  useInputContainerDefaultStyle,
);

// SELECT
const useSelectDefaultStyle = makeStyle<{ disabled: boolean }>(
  ({ theme, props }) => css`
    font-family: ${theme.typography.fontFamily.regular};
    width: 100%;
    height: ${pxPerfect(iPadFactor(45))};
    border-color: ${props?.disabled
      ? theme.colors[theme.mode].gray.A200
      : theme.colors[theme.mode].primary.main};
    border-bottom-width: 1px;
    border-style: solid;
    padding-left: ${pxPerfect(iPadFactor(10))};
  `,
);

const useSelectContainerDefaultStyle = makeStyle(() => css``);

export const useSelectStyle = makeFinalStyleHook<Partial<SelectProps>>(
  FORM_SELECT_UUID,
  useSelectDefaultStyle,
);

export const useSelectContainerStyle = makeFinalStyleHook(
  FORM_SELECT_CONTAINER_UUID,
  useSelectContainerDefaultStyle,
);

export const useDisabledDefaultStyle = makeStyle<{ disabled: boolean }>(
  ({ theme, props }) => css`
    ${props.disabled
      ? `border-bottom-color: ${theme.colors[theme.mode].gray.A100};`
      : ''}
  `,
);

export const useDisabledStyle = makeStyleHookWithMergedCtx(
  FORM_SELECT_DISABLED_UUID,
  useDisabledDefaultStyle,
);

export const usePlaceholderDefaultStyle = makeStyle<Style>(
  ({ theme, props }) => ({
    fontFamily: isString(theme.typography.fontFamily.regular)
      ? theme.typography.fontFamily.regular
      : join(theme.typography.fontFamily.regular, ''),
    color: theme.typography.colors[theme.mode].placeholder,
    textTransform: props?.textTransform,
  }),
);

export const usePlaceholderStyle = makeStyleHookWithMergedCtx(
  FORM_SELECT_PLACEHOLDER_UUID,
  usePlaceholderDefaultStyle,
);

type ArrowProps = {
  disabled: boolean;
};

// noinspection CssInvalidPropertyValue
export const useDefaultDownArrowStyle = makeStyle<ArrowProps>(
  ({ theme, utils, props }) => css`
    ${utils.applyColor(theme, props?.disabled ? 'gray.A100' : 'primary')}
    position: absolute;
    right: ${pxPerfect(10)};
    z-index: 1;
    elevation: 1;
  `,
);

export const useDownArrowStyle = makeStyleHookWithMergedCtx<ArrowProps>(
  FORM_SELECT_ARROW_DOWN_UUID,
  useDefaultDownArrowStyle,
);

// CHECKBOX
const useCheckboxDefaultStyle = makeStyle(
  () => css`
    justify-content: center;
  `,
);

export const useCheckboxStyle = makeFinalStyleHook(
  FORM_CHECKBOX_UUID,
  useCheckboxDefaultStyle,
);

// RADIO GROUP
const useRadioGroupDefaultStyle = makeStyle(() => css``);

export const useRadioGroupStyle = makeFinalStyleHook(
  FORM_RADIO_GROUP_UUID,
  useRadioGroupDefaultStyle,
);

// SWITCH
const useSwitchDefaultStyle = makeStyle(() => css``);

export const useSwitchStyle = makeFinalStyleHook(
  FORM_SWITCH_UUID,
  useSwitchDefaultStyle,
);

// CHECKBOX
const useCheckboxGroupDefaultStyle = makeStyle(
  () => css`
    justify-content: center;
  `,
);

export const useCheckboxGroupStyle = makeFinalStyleHook(
  FORM_CHECKBOX_GROUP_UUID,
  useCheckboxGroupDefaultStyle,
);

// TIME PICKER
const useTimePickerDefaultStyle = makeStyle(() => css``);

export const useTimePickerStyle = makeFinalStyleHook(
  FORM_TIME_PICKER_UUID,
  useTimePickerDefaultStyle,
);

// DATE PICKER
const useDatePickerDefaultStyle = makeStyle<{ disabled?: boolean }>(
  ({ props, theme }) => css`
    flex: 1;
    color: ${props?.disabled
      ? theme.colors[theme.mode].gray.A800
      : theme.typography.colors[theme.mode].onBackground};
    font-family: ${theme.typography.fontFamily.regular};
    font-size: ${pxPerfect(iPadFactor(20))};
    opacity: ${props?.disabled ? 0.4 : 1};
    height: ${pxPerfect(iPadFactor(43))};
    border-top-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;
    border-bottom-color: ${props?.disabled
      ? theme.colors[theme.mode].gray.A400
      : theme.colors[theme.mode].primary.main};
    border-radius: 0;
    border-width: 1px;
    width: 100%;
    overflow: hidden;
    opacity: ${props?.disabled ? 0.4 : 1};
    padding: 0;
    margin: 0;
  `,
);

export const useDatePickerStyle = makeFinalStyleHook(
  FROM_DATE_PICKER_UUID,
  useDatePickerDefaultStyle,
);
