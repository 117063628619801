import contextualConfig from '@mmw/contextual-config';
import forEach from 'lodash/forEach';
import forIn from 'lodash/forIn';
import get from 'lodash/get';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';
import noop from 'lodash/noop';
import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import some from 'lodash/some';
import { useCallback, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { F, U } from 'ts-toolbelt';
import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

import { LOCAL_STORAGE_KEY } from './constants';

const { applicationId } = contextualConfig.application;

export type Cookies = keyof CookieState['cookies'];

export type SetCookieParam = Cookies | 'modalVisible' | 'bannerVisible';

export class CookieState {
  constructor(init?: Partial<CookieState>) {
    Object.assign(this, init);
  }

  cookies = {
    marketing: null,

    analytics: null,

    googleReCaptcha: null,

    googleMaps: null,

    functional: true,

    thirdParty: null,
  };

  modalVisible = false;

  bannerVisible = false;

  setCookieConfig: F.Function<[SetCookieParam, boolean]> = noop;

  updateCookiesState: F.Function = noop;
}

function setStateCallback() {
  return set => {
    const INITIAL_STATE = new CookieState();
    INITIAL_STATE.setCookieConfig = (key, value) => {
      if (key === 'modalVisible' || key === 'bannerVisible') {
        set(state => ({
          ...state,
          [key]: value,
        }));
        return;
      }
      set(state => ({
        ...state,
        cookies: {
          ...state.cookies,
          [key]: value,
        },
      }));
    };
    return INITIAL_STATE;
  };
}

export const useCookieStore = create<CookieState>()(
  devtools(
    persist(setStateCallback(), {
      name: `${applicationId}-cookie-consent`,
      partialize: state => {
        const initialCookies = pickBy(state.cookies, cookie => !isNull(cookie));
        if (isEmpty(initialCookies)) return null;
        return {
          ...state,
          cookies: initialCookies,
        };
      },
    }),
  ),
);

export function useSetCookieStateAction() {
  return useCookieStore(state => state.setCookieConfig);
}

export function useUpdateCookiesStateAction() {
  return useCookieStore(state => state.updateCookiesState);
}

export function useCookieStateByKey() {
  const currentCookiesState = useCookieStore(state => state.cookies);
  return useCallback(
    cookieKey => get(currentCookiesState, cookieKey) || false,
    [currentCookiesState],
  );
}

export function useGoogleMapsCookies(): {
  value: U.Nullable<boolean>;
  setValue: (value: boolean) => void;
} {
  const value = useCookieStore(state => state.cookies.googleMaps);
  const setCookieConfig = useSetCookieStateAction();

  const setValue = useCallback(
    (newValue: boolean) => {
      setCookieConfig('googleMaps', newValue);
    },
    [setCookieConfig],
  );
  return { value, setValue };
}

export function useGoogleReCaptchaCookies(): {
  value: U.Nullable<boolean>;
  setValue: (value: boolean) => void;
} {
  const value = useCookieStore(state => state.cookies.googleReCaptcha);
  const setCookieConfig = useSetCookieStateAction();

  const setValue = useCallback(
    (newValue: boolean) => {
      setCookieConfig('googleReCaptcha', newValue);
    },
    [setCookieConfig],
  );
  return { value, setValue };
}

export function useAnalyticsCookies(): {
  value: U.Nullable<boolean>;
  setValue: (value: boolean) => void;
} {
  const value = useCookieStore(state => state.cookies.analytics);
  const setCookieConfig = useSetCookieStateAction();

  const setValue = useCallback(
    (newValue: boolean) => setCookieConfig('analytics', newValue),
    [setCookieConfig],
  );
  return { value, setValue };
}

export function useMarketingCookies(): {
  value: U.Nullable<boolean>;
  setValue: (value: boolean) => void;
} {
  const value = useCookieStore(state => state.cookies.marketing);
  const setCookieConfig = useSetCookieStateAction();

  const setValue = useCallback(
    (newValue: boolean) => setCookieConfig('marketing', newValue),
    [setCookieConfig],
  );
  return { value, setValue };
}

export function useFunctionalCookies(): {
  value: U.Nullable<boolean>;
  setValue: (value: boolean) => void;
} {
  const value = useCookieStore(state => state.cookies.functional);
  const setCookieConfig = useSetCookieStateAction();

  const setValue = useCallback(
    (newValue: boolean) => setCookieConfig('functional', newValue),
    [setCookieConfig],
  );
  return { value, setValue };
}

export function useThirdPartyCookies(): {
  value: U.Nullable<boolean>;
  setValue: (value: boolean) => void;
} {
  const value = useCookieStore(state => state.cookies.thirdParty);
  const setCookieConfig = useSetCookieStateAction();

  const setValue = useCallback(
    (newValue: boolean) => {
      setCookieConfig('thirdParty', newValue);
    },
    [setCookieConfig],
  );
  return { value, setValue };
}

export function useCookiesModalVisible(): boolean {
  return useCookieStore(state => state.modalVisible);
}
export function useCookiesBannerVisible(): boolean {
  return useCookieStore(state => state.bannerVisible);
}

export function useUseCookiesModal() {
  const setCookieConfig = useCookieStore(state => state.setCookieConfig);
  const value = useCookieStore(state => state.modalVisible);
  const show = useCallback(() => {
    setCookieConfig('modalVisible', true);
  }, [setCookieConfig]);
  const toggle = useCallback(() => {
    setCookieConfig('modalVisible', !value);
    if (!value) setCookieConfig('bannerVisible', false);
  }, [setCookieConfig, value]);
  return {
    show,
    toggle,
    value,
  };
}

export function useUseCookiesBanner(): { toggle: () => void; value: boolean } {
  const setCookieConfig = useCookieStore(state => state.setCookieConfig);
  const value = useCookieStore(state => state.bannerVisible);
  const toggle = useCallback(() => {
    setCookieConfig('bannerVisible', !value);
  }, [setCookieConfig, value]);

  return {
    toggle,
    value,
  };
}

export function useAcceptAllCookiesAndCloseBanner(): () => void {
  const setCookieConfig = useSetCookieStateAction();

  return useCallback(() => {
    setCookieConfig('bannerVisible', false);
    setCookieConfig('modalVisible', false);
    setCookieConfig('functional', true);
    setCookieConfig('googleReCaptcha', true);
    setCookieConfig('googleMaps', true);
    setCookieConfig('marketing', true);
    setCookieConfig('analytics', true);
  }, [setCookieConfig]);
}

export function useAvailableCookiesState(cookiesList: string[]) {
  return useCookieStore(state => pick(state.cookies, cookiesList));
}

export function useOnDeclineAll(cookiesList: string[]) {
  const setCookieConfig = useSetCookieStateAction();
  const availableCookiesState = useAvailableCookiesState(cookiesList);

  return useCallback(() => {
    forEach(cookiesList, (cookieKey: SetCookieParam) => {
      if (availableCookiesState[cookieKey]) {
        setCookieConfig(cookieKey, false);
      }
    });
  }, [availableCookiesState, cookiesList, setCookieConfig]);
}

export function useInitializeCookiesState(availableCookies: string[]) {
  const setCookieConfig = useSetCookieStateAction();
  const currentCookiesState = useCookieStore(state => state.cookies);
  const [cookies] = useCookies();
  const alreadyAcceptedCookies = cookies[LOCAL_STORAGE_KEY] === 'true';
  // const allCookieKeys = useMemo(
  //   () => keys(currentCookiesState),
  //   [currentCookiesState],
  // );

  useEffect(() => {
    if (
      !alreadyAcceptedCookies ||
      !some(currentCookiesState, item => item !== null)
    ) {
      forIn(currentCookiesState, (cookie, cookieKey: SetCookieParam) => {
        if (includes(availableCookies, cookieKey)) {
          setCookieConfig(cookieKey, true);
        }
      });
    }
  }, [alreadyAcceptedCookies]);

  return null;
}
