import I18N from '@mmw/constants-i18n-orgunit';
import {
  setAlreadySearch,
  setLastSearchByUserLocation,
  useAlreadySearch,
  useIsLoadingStoresByLocationSearch,
  useStoresByLocationSearchError,
  useTotalStoresListByLocation,
} from '@store-locator/store-creator';
import { useIsMediaQueryUpToMD } from '@ui-system/media-query';
import React, { useEffect } from 'react';
import { useToastNotificationFunction } from 'toast-notifications/hooks/useToastNotification';
import { U } from 'ts-toolbelt';

interface Props {
  mapsError: U.Nullable<Error>;
}

const NotificationsHandler: React.FC<Props> = ({ mapsError }: Props) => {
  const isMobile = useIsMediaQueryUpToMD();
  const notification = useToastNotificationFunction({
    anchorOrigin: {
      horizontal: 'center',
      vertical: isMobile ? 'top' : 'bottom',
    },
    // autoHideDuration: 6000,
  });
  const searchError = useStoresByLocationSearchError();
  const alreadySearch = useAlreadySearch();
  const loading = useIsLoadingStoresByLocationSearch();
  const total = useTotalStoresListByLocation();

  useEffect(() => {
    if (mapsError && mapsError?.message) {
      notification(mapsError?.message, 'error');
    }
  }, [mapsError]);

  useEffect(() => {
    if (alreadySearch && searchError) {
      notification(I18N.STORE_LOCATOR.SEARCH_ERROR, 'error');
      setAlreadySearch(false);
      setLastSearchByUserLocation(false);
    }
  }, [searchError]);

  useEffect(() => {
    if (alreadySearch && total !== null && total === 0) {
      notification({
        ...I18N.STORE_LOCATOR.SEARCH_RESULT,
        values: { count: total },
      });
      setAlreadySearch(false);
      setLastSearchByUserLocation(false);
    }
    if (alreadySearch && total && total > 0) {
      notification(
        { ...I18N.STORE_LOCATOR.SEARCH_RESULT, values: { count: total } },
        'success',
      );
      setAlreadySearch(false);
    }
  }, [total, loading]);

  return null;
};

export default NotificationsHandler;
