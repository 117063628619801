import { getConsumerProductService } from '@mmw/services-holder';
import { EMPTY_OBJECT } from '@shared-utils/object';
import { useStoreLocatorStore } from '@store-locator/store-creator';
import { createStoreModule } from '@zustand-store/core';
import Aigle from 'aigle';
import { flatten, includes, map, uniq } from 'lodash';
import compact from 'lodash/compact';
import get from 'lodash/get';
import keys from 'lodash/keys';
import noop from 'lodash/noop';
import { useEffect, useMemo } from 'react';

import { ProductRatingStore } from './types';

const service = getConsumerProductService();

const INITIAL_STATE = {
  ratings: EMPTY_OBJECT,
  error: null,
  loading: false,
  getRatingValueByProductId: noop,
};

const useProductRatingStore = createStoreModule<ProductRatingStore>({
  name: 'product-rating-store',
  initialState: INITIAL_STATE,
  persistOptions: {
    partialize: state => ({
      userVerifiedPhone: state.userVerifiedPhone,
      userEmail: state.userEmail,
    }),
  },
  initializer: (set, getState) => ({
    ...INITIAL_STATE,
    getRatingValueByProductId: async (productID: number) => {
      try {
        const ids = Object.keys(getState().ratings);
        const validate = includes(ids, String(productID));
        if (validate) return;
        set(() => ({ loading: true }));
        const result = await service.getProductRatingValue(productID);
        set(state => ({
          ratings: {
            ...state.ratings,
            [productID]: result,
          },
          error: null,
          loading: false,
        }));
      } catch (error) {
        set(() => ({ error, loading: false }));
      }
    },
  }),
});
useStoreLocatorStore.subscribe(state => {
  const productIds = compact(
    uniq(
      map(
        flatten(
          map(state?.storeIdsList, id => state?.itemsById?.[id]?.demoDevices),
        ),
        'id',
      ),
    ),
  );

  Aigle.resolve(productIds)
    .forEachLimit(1, async id => {
      useProductRatingStore.getState().getRatingValueByProductId(id);
    })
    .then();
});

export function useGetProductRatingOnMount(productID: number) {
  const getProductRatingById = useProductRatingStore(
    state => state.getRatingValueByProductId,
  );
  const currentRatings = useProductRatingStore(state => state.ratings);
  const mappedIds = keys(currentRatings);
  useEffect(() => {
    if (productID && !includes(mappedIds, String(productID))) {
      getProductRatingById(productID);
    }
  }, [getProductRatingById, mappedIds, productID]);
}

export function useProductRatingValue(productID: number): number {
  const ratings = useProductRatingStore(state => state.ratings);
  return useMemo(() => get(ratings, productID, 0), [productID, ratings]);
}

export function useIsLoadingRating() {
  return useProductRatingStore(state => state.loading);
}
