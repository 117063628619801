import { getDefaultApiHostUrl } from '@mmw/config';
import {
  CHANGE_CLASS_OF_GOODS,
  SELECT_ALL,
} from '@mmw/constants-i18n-functionalities';
import { ConsumerProductClassOfGoodsJSON } from '@mmw/services-core-consumer-product/types';
import {
  useAddClassOfGoodToSelectionAction,
  useAvailableClassOfGoodsList,
  useGetClassOfGoodsByParent,
  useIsLoadingProductsOrCogs,
  useSearchProductsByCog,
  useSelectedClassOfGoods,
  useSetSelectAllProductsAction,
} from '@product-selection-cogs/store-creator';
import { css, styled } from '@ui-system/css';
import {
  useIsMediaQueryUpToMD,
  useIsMediaQueryUpToSM,
} from '@ui-system/media-query';
import { Theme } from '@ui-system/theme';
import UI from '@ui-system/ui';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import last from 'lodash/last';
import map from 'lodash/map';
import some from 'lodash/some';
import values from 'lodash/values';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { U } from 'ts-toolbelt';

import { useProductSelectionByCogsConfigs } from './context';
import SelectorItemCard from './SelectorItemCard';

const ActionLinksContainer = styled(UI.Container)(
  ({ theme, disableHover }: { theme?: Theme; disableHover?: boolean }) => `  
  margin-right: 5px;
  ${
    disableHover
      ? ''
      : `
    &:hover {
      * {
        color: ${(theme as Theme).colors[(theme as Theme).mode].primary.main} !important;
        fill: ${(theme as Theme).colors[(theme as Theme).mode].primary.main} !important;       
      }
    }
  `
  }
`,
);

interface ClassOfGoodsSelectorProps {
  cogsList: ConsumerProductClassOfGoodsJSON[];
  selectionLevel: number;
  selectedCogs: Record<number, ConsumerProductClassOfGoodsJSON>;
  hideImages?: boolean;
  shouldSearchNextLevel?: boolean;
}

const ClassOfGoodsList: React.FC<ClassOfGoodsSelectorProps> = ({
  cogsList,
  selectionLevel,
  selectedCogs,
  hideImages,
  shouldSearchNextLevel,
}: ClassOfGoodsSelectorProps) => {
  const [selectedItem, setItem] =
    useState<U.Nullable<ConsumerProductClassOfGoodsJSON>>(null);
  const addClassOfGoodToSelection = useAddClassOfGoodToSelectionAction();
  const searchChildClassOfGood = useGetClassOfGoodsByParent();
  const isMobile = useIsMediaQueryUpToMD();
  const getIsItemSelectedStatus = useCallback(
    (id: number) => some(selectedCogs, c => c.id === id),
    [selectedCogs],
  );
  const onSelect = useCallback(
    item => {
      if (getIsItemSelectedStatus(item.id)) return;
      setItem(item);
      addClassOfGoodToSelection(item, selectionLevel);
    },
    [addClassOfGoodToSelection, getIsItemSelectedStatus, selectionLevel],
  );

  useEffect(() => {
    if (selectedItem && shouldSearchNextLevel) {
      searchChildClassOfGood(selectedItem.id);
    }
  }, [selectedItem]);

  return (
    <UI.Container direction="column">
      <UI.Container
        style={css`
          ${isMobile
            ? `
            overflow-x: auto;
            padding: 10px 0;
          `
            : ''}
        `}
        w="100%"
        modifiers={cogsList?.length >= 4 ? 'grid4col, gridRowGap2' : undefined}
        responsive="upToMd.modifiers=none;upToMd.p=0,0,3,0"
        justify={cogsList?.length >= 4 ? 'space-between' : 'flex-start'}
      >
        {map(cogsList, item => (
          <SelectorItemCard
            key={`${item.id}-${item.name}`}
            name={item.name}
            imgUrl={item.imageUrl || `${getDefaultApiHostUrl()}${item.image}`}
            onClick={() => onSelect(item)}
            selected={getIsItemSelectedStatus(item.id)}
            hideImage={hideImages}
          />
        ))}
      </UI.Container>
      {cogsList?.length > 1 ? (
        <UI.Divider
          style={css`
            margin: 10px 0;
          `}
        />
      ) : null}
    </UI.Container>
  );
};

interface BreadCrumbProps {
  visible: boolean;
  selectedCogsList: ConsumerProductClassOfGoodsJSON[];
  isLastStepSelection?: boolean;
}

// <ActionLinksContainer align="center" gap={1}>
// <UI.Link onClick={() => unselectCog(null, 1)}>
// <UI.Typography modifiers="uppercase, bold">X</UI.Typography>
// <UI.Icon name="edit" size={20} />
// </UI.Link>
// <UI.Icon name="next" size={15} />
// </ActionLinksContainer>

const BreadCrumbItems: React.FC<BreadCrumbProps> = ({
  visible,
  selectedCogsList,
  isLastStepSelection,
}: BreadCrumbProps) => {
  const unselectCog = useAddClassOfGoodToSelectionAction();
  const selectAllProducts = useSetSelectAllProductsAction();
  const isMobile = useIsMediaQueryUpToSM();
  const lastSelectedCog = last(selectedCogsList);

  if (!visible) return null;

  return (
    <UI.Container direction="column">
      <UI.Container
        w="100%"
        justify="space-between"
        m="3, 0"
        responsive="upToSm.direction=column"
      >
        {isMobile && !isEmpty(selectedCogsList) ? (
          <ActionLinksContainer align="center" m="0, 0, 2, 0" gap={1}>
            <UI.Icon name="back" size={20} />
            <UI.Link onClick={() => unselectCog(null, selectedCogsList.length)}>
              <UI.Typography
                i18n={CHANGE_CLASS_OF_GOODS}
                modifiers="uppercase"
              />
            </UI.Link>
          </ActionLinksContainer>
        ) : (
          <UI.Container>
            {map(selectedCogsList, (cog, idx) => {
              const disableAction = idx >= selectedCogsList.length - 1;
              return (
                <ActionLinksContainer
                  align="center"
                  gap={1}
                  disableHover={disableAction}
                >
                  {disableAction ? (
                    <UI.Typography
                      modifiers="uppercase"
                      charLimit={isMobile ? undefined : 25}
                      hideLastChars={!isMobile}
                    >
                      {cog?.name}
                    </UI.Typography>
                  ) : (
                    <UI.Link
                      onClick={() => {
                        unselectCog(null, idx + 2);
                      }}
                    >
                      <UI.Typography
                        modifiers="uppercase"
                        charLimit={isMobile ? undefined : 25}
                        hideLastChars={!isMobile}
                      >
                        {cog?.name}
                      </UI.Typography>
                    </UI.Link>
                  )}
                  {idx < selectedCogsList.length - 1 ? (
                    <UI.Icon name="next" size={15} />
                  ) : (
                    ''
                  )}
                </ActionLinksContainer>
              );
            })}
          </UI.Container>
        )}
        {!isEmpty(lastSelectedCog) && isLastStepSelection ? (
          <ActionLinksContainer>
            <UI.Link onClick={selectAllProducts}>
              <UI.Typography i18n={SELECT_ALL} modifiers="uppercase" />
              <UI.Icon
                style={css`
                  margin-left: 10px;
                `}
                name="down"
                size={15}
              />
            </UI.Link>
          </ActionLinksContainer>
        ) : null}
      </UI.Container>
    </UI.Container>
  );
};

interface ClassOfGoodsProps {
  classOfGoodsList: ConsumerProductClassOfGoodsJSON[][];
  selectedCogs: Record<number, ConsumerProductClassOfGoodsJSON>;
}

const ClassOfGoodsSelectorList: React.FC<ClassOfGoodsProps> = ({
  classOfGoodsList,
  selectedCogs,
}: ClassOfGoodsProps) => {
  const configs = useProductSelectionByCogsConfigs();
  const isLoading = useIsLoadingProductsOrCogs();
  const searchProductsByCog = useSearchProductsByCog();
  const isLastSearchItemEmpty = isEmpty(last(classOfGoodsList));
  const selectedCogsList = useMemo(() => values(selectedCogs), [selectedCogs]);
  const shouldHideBreadcrumb =
    (configs?.classOfGoodSelectionConfigs?.fisrtLineFixed &&
      selectedCogsList.length === 1) ||
    (isEmpty(selectedCogsList) && isEmpty(classOfGoodsList));
  let lastCogLevel;

  useEffect(() => {
    const currentCog = selectedCogs[lastCogLevel];
    const classOfGoodId = currentCog?.id;
    if (!isLoading && isLastSearchItemEmpty && classOfGoodId) {
      searchProductsByCog({
        classOfGoodsIDs: [classOfGoodId],
      });
    }
  }, [isLastSearchItemEmpty]);

  // if (isMobile && !isEmpty(products.list)) return null;

  return (
    <UI.Container direction="column" w="100%">
      {configs?.classOfGoodSelectionConfigs?.fisrtLineFixed ? (
        <ClassOfGoodsList
          cogsList={classOfGoodsList[0]}
          selectionLevel={1}
          hideImages={includes(
            configs?.classOfGoodSelectionConfigs?.hideImagesFromLevels,
            1,
          )}
          selectedCogs={selectedCogs}
          shouldSearchNextLevel={!isLastSearchItemEmpty}
        />
      ) : null}
      <BreadCrumbItems
        visible={!shouldHideBreadcrumb}
        selectedCogsList={selectedCogsList}
        isLastStepSelection={isEmpty(last(classOfGoodsList))}
      />
      {map(classOfGoodsList, (cogs, index) => {
        const selectionLevel = index + 1;
        lastCogLevel = !isEmpty(cogs) ? selectionLevel : lastCogLevel;
        if (
          index < classOfGoodsList.length - 1 ||
          (configs?.classOfGoodSelectionConfigs?.fisrtLineFixed && index === 0)
        )
          return null;
        return (
          <ClassOfGoodsList
            key={`${index}-${selectionLevel}`}
            cogsList={cogs}
            selectionLevel={selectionLevel}
            hideImages={includes(
              configs?.classOfGoodSelectionConfigs?.hideImagesFromLevels,
              selectionLevel,
            )}
            selectedCogs={selectedCogs}
            shouldSearchNextLevel={lastCogLevel && !isLastSearchItemEmpty}
          />
        );
      })}
      {isLoading ? <UI.Spinner visible /> : null}
    </UI.Container>
  );
};

const ClassOfGoodsSelector: React.FC = () => {
  const selectedCogs = useSelectedClassOfGoods();

  const classOfGoodsList = useAvailableClassOfGoodsList();
  const isMobile = useIsMediaQueryUpToMD();

  return (
    <UI.Container
      style={css`
        ${isMobile
          ? `
        max-height: 90vh;
        overflow: auto;
      `
          : ''}
      `}
      direction="column"
    >
      <ClassOfGoodsSelectorList
        classOfGoodsList={classOfGoodsList}
        selectedCogs={selectedCogs}
      />
    </UI.Container>
  );
};

export default ClassOfGoodsSelector;
