import { useSetFieldValue } from '@mmw/redux-store-fast-form/hooks';
import { useValue } from '@mmw/redux-store-fast-form/hooks/fields';
import convert from 'convert';
import { useCallback, useContext, useMemo } from 'react';
import { useStore } from 'zustand';

import { STORE_LOCATOR_FIELDPATHS } from '../../components/stores-search/form/configs';
import { KM_RADIUS_OPTIONS, MI_RADIUS_OPTIONS } from './constants';
import { MeasureUnitsContext } from './context';
import { Selector, State } from './types';

const useMeasureUnitsStore = (selector: Selector) => {
  const store = useContext(MeasureUnitsContext);
  if (!store) {
    throw new Error('Missing MeasureUnitsContext');
  }
  return useStore(store, selector);
};

export const useCurrentMeasureUnits = (): State['measureUnits'] =>
  useMeasureUnitsStore((state: State) => state.measureUnits);

export const useSetMeasureUnits = (): State['setMeasureUnits'] =>
  useMeasureUnitsStore((state: State) => state.setMeasureUnits);

export const useToggleMeasureUnits = (): State['toggleMeasureUnits'] =>
  useMeasureUnitsStore((state: State) => state.toggleMeasureUnits);

export const useConvertDistance = (distance: number): number => {
  const measureUnits = useCurrentMeasureUnits();
  return useMemo(
    () =>
      convert(distance, 'kilometers').to(
        // @ts-ignore
        measureUnits === 'km' ? 'kilometers' : measureUnits,
      ) as unknown as number,
    [distance, measureUnits],
  );
};

export const useRadiusOptions = () => {
  const measureUnits = useCurrentMeasureUnits();
  return useMemo(
    () => (measureUnits === 'km' ? KM_RADIUS_OPTIONS : MI_RADIUS_OPTIONS),
    [measureUnits],
  );
};

export const useOnChangeSwitch = () => {
  const value = useValue<number>(STORE_LOCATOR_FIELDPATHS.radius.$path);
  const setFieldValue = useSetFieldValue();
  const currentMeasureUnit = useCurrentMeasureUnits();

  return useCallback(() => {
    setFieldValue(
      STORE_LOCATOR_FIELDPATHS.radius.$path,
      currentMeasureUnit === 'km'
        ? convert(value, 'miles').to('kilometers')
        : convert(value, 'kilometers').to('miles'),
    );
  }, [value, currentMeasureUnit]);
};
