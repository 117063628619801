import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import {
  getFormIsSuccessfullySubmittedSelector,
  getFormSubmittedValuesSelector,
} from '../../store/stateSelector';
import { FormId } from '../../types';
import useFastFormProviderContext from '../useFastFormProviderContext';

function useSubmittedValues(formId: FormId) {
  const submittedValuesSelector = useCallback(
    state => getFormSubmittedValuesSelector(formId)(state),
    [formId],
  );
  return useSelector(submittedValuesSelector);
}

function useIsSuccessfullySubmitted(formId: FormId) {
  const submittedValuesSelector = useCallback(
    state => getFormIsSuccessfullySubmittedSelector(formId)(state),
    [formId],
  );
  return useSelector(submittedValuesSelector);
}

function useOnSubmitSuccessCallback() {
  const { formId, onSubmitSuccess } = useFastFormProviderContext();
  const submittedValues = useSubmittedValues(formId);
  const isSuccess = useIsSuccessfullySubmitted(formId);
  useEffect(() => {
    if (isSuccess) {
      onSubmitSuccess(submittedValues);
    }
    return () => {};
  }, [isSuccess]);
}

export default useOnSubmitSuccessCallback;
