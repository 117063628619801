import { useLayoutEffect, useMemo, useState } from 'react';
import { U } from 'ts-toolbelt';

export const useCurrentHeaderHeight = () => {
  const [height, setHeight] = useState<U.Nullable<number>>(null);

  useLayoutEffect(() => {
    const el = document.getElementById('pageHeader') as HTMLElement;
    if (el) {
      const { offsetHeight } = el;
      if (offsetHeight) {
        setHeight(offsetHeight);
      }
    }
  }, []);

  return useMemo(() => height, [height]);
};
