import get from 'lodash/get';
import { useMemo } from 'react';

import { FieldPath } from '../../types';
import useFieldConfigProps, { Options } from './useFieldConfigProps';

export * from './useFieldConfigProps';

const useInputFieldConfigProps = (fieldPath: FieldPath, options?: Options) => {
  const config = useFieldConfigProps(fieldPath, options);

  return useMemo(
    () => ({
      required: config.required || get(options, 'required'),
      // required: true,
      maxLength: config.maxLength,
      minLength: config.minLength,
    }),
    [config.maxLength, config.minLength, config.required, options],
  );
};

export default useInputFieldConfigProps;
