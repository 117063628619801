import { getStaticUrl } from '@mmw/config';
import { makeStyle } from '@ui-system/style';
import UI from '@ui-system/ui';
import React from 'react';
import { F, U } from 'ts-toolbelt';

const IMAGE_PLACEHOLDER = `${getStaticUrl()}/images/NO_IMAGE.png`;

const useItemCardStyle = makeStyle<{ selected?: boolean; hideImage?: boolean }>(
  ({ theme, css, props }) => css`
    position: relative;
    box-sizing: border-box;
    cursor: pointer;
    margin: 0 5px;
    width: ${props?.hideImage ? '100%' : '180px'};
    border: ${props.selected
      ? `2px solid ${theme.colors[theme.mode].primary.main}`
      : `1px solid ${theme.colors[theme.mode].gray.A400}`};
  `,
);

interface ItemProps {
  name: string;
  imgUrl: U.Nullable<string>;
  onClick?: F.Function;
  selected?: boolean;
  hideImage?: boolean;
  children?: React.ReactNode;
  onlyProductName?: boolean;
}

const SelectorItemCard: React.FC<ItemProps> = ({
  name,
  imgUrl,
  onClick,
  selected,
  hideImage,
  children,
  onlyProductName,
  ...props
}: ItemProps) => {
  const itemCardStyle = useItemCardStyle({ selected, hideImage });

  if (onlyProductName) {
    return <UI.Typography modifiers="bold">{name}</UI.Typography>;
  }

  return (
    <UI.Container
      style={itemCardStyle}
      direction="column"
      align="center"
      justify="center"
      onClick={onClick}
      p="2"
      gap={hideImage ? 0 : 2}
      {...props}
    >
      {children}
      {!hideImage ? (
        <UI.Image
          src={imgUrl}
          width={115}
          height={120}
          fallbackSrc={IMAGE_PLACEHOLDER}
        />
      ) : null}
      <UI.Typography>{name}</UI.Typography>
    </UI.Container>
  );
};

export default SelectorItemCard;
