import defaultApiV2, { ApiResponse } from '@mmw/api-v2';
import { BRAND_MAPPING, SalesOrgBrand } from '@mmw/constants-salesorgbrand-ids';
import { Pagination } from '@mmw/services-core-common/types';
import autoBind from 'auto-bind';
import createCache, { Cache } from 'keshi';

import {
  ConsumerProductRatingPath,
  GetConsumerProductsClassOfGoodsPath,
  GetProductsPath,
} from './apiPaths';
import logger from './log';
import {
  ConsumerClassOfGoodsRequestParams,
  ConsumerProductClassOfGoodsJSON,
  ConsumerProductJSON,
  ConsumerProductSearchRequestParams,
  ProducteRatingValueReturn,
} from './types';

type Api = typeof defaultApiV2;

type ConsumerProductServiceOptions = {
  apiv2?: Api;
  country?: string;
};

class ConsumerProductService {
  api: Api;

  cache: Cache;

  constructor({ apiv2, country }: ConsumerProductServiceOptions) {
    this.api = apiv2 || defaultApiV2;
    this.cache = createCache();
    autoBind(this);
  }

  async getProductRatingValue(productId: number): Promise<number> {
    logger.debug(
      'Trying to get product rating value for productID=%s',
      productId,
    );
    try {
      const response: ApiResponse<ProducteRatingValueReturn> =
        await this.api.get(ConsumerProductRatingPath(productId));
      const { data } = response;
      logger.info(
        'Successfully got product rating value of productID=%s',
        productId,
      );
      return Number(data.rating?.toFixed(2)) || 0;
    } catch (error) {
      logger.error('Error when getting product rating value, error=%O', error);
      throw error;
    }
  }

  async getProducts(
    options: ConsumerProductSearchRequestParams,
    language: string,
  ): Promise<Pagination<ConsumerProductJSON>> {
    logger.debug(
      'Trying to get consumer products by lang=%s request=%O',
      language,
      options,
    );
    const cacheKey = `get-consumer-products:${JSON.stringify(options)}-${language}`;
    return this.cache.resolve(
      cacheKey,
      async () => {
        try {
          const response: ApiResponse<Pagination<ConsumerProductJSON>> =
            await this.api.post(GetProductsPath(), options);
          const { data } = response;
          logger.info(`Successfully got consumer products, size ${data.total}`);
          return data;
        } catch (error) {
          logger.error('Error when getting consumer products, error=%O', error);
          throw error;
        }
      },
      '5m',
    );
  }

  async getProductsClassOfGoods(
    requestOptions: ConsumerClassOfGoodsRequestParams,
    salesOrgBrandID: SalesOrgBrand,
    language: string,
  ): Promise<ConsumerProductClassOfGoodsJSON[]> {
    logger.debug(
      'Trying to get product class of goods list by sob=%s',
      salesOrgBrandID,
    );
    try {
      const response: ApiResponse<ConsumerProductClassOfGoodsJSON[]> =
        await this.api.get(
          GetConsumerProductsClassOfGoodsPath(language, {
            ...requestOptions,
            salesOrgBrandID: requestOptions.salesOrgBrandID || salesOrgBrandID,
            brand:
              BRAND_MAPPING[requestOptions.salesOrgBrandID || salesOrgBrandID],
          }),
        );
      const { data } = response;
      logger.info('Successfully got class of goods by sob=%s', salesOrgBrandID);
      return data;
    } catch (error) {
      logger.error('Error when getting class of goods, error=%O', error);
      throw error;
    }
  }
}

export default ConsumerProductService;
