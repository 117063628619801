import { CANCEL } from '@mmw/constants-i18n-buttons';
import { PERSON as PERSON_I18N } from '@mmw/constants-i18n-fields';

const NAMESPACE = 'orgunit';
const STORE_LOCATOR_NAMESPACE = 'storeLocator';

const I18N = {
  LABEL: {
    key: NAMESPACE,
    message: 'orgunit',
  },
  MANAGEMENT_BODY: {
    key: `${NAMESPACE}.managementBody`,
    message: 'Management Body',
  },
  PARTNER_ID_ALREADY_USED: {
    key: `${NAMESPACE}.partnerIdAlreadyUsed`,
    message: 'Partner id already used',
  },
  ID: {
    key: `${NAMESPACE}.id`,
    message: 'orgunit id',
  },
  TYPE_INFO: {
    key: `${NAMESPACE}.typeInfo`,
    message: 'info',
  },
  TYPE_SALES: {
    key: `${NAMESPACE}.typeSales`,
    message: 'sales type',
  },
  TYPE_SUPPORT: {
    key: `${NAMESPACE}.typeSupport`,
    message: 'support',
  },
  PHONE: PERSON_I18N.PHONE.LABEL,
  HOMEPAGE: PERSON_I18N.HOMEPAGE.LABEL,
  EMAIL: PERSON_I18N.EMAIL.LABEL,
  TYPE: {
    key: `${NAMESPACE}.type`,
    message: 'type',
  },
  FAX: PERSON_I18N.FAX.LABEL,
  CATEGORIES: {
    key: `${NAMESPACE}.categories`,
    message: 'categories',
  },
  SERVICES: {
    key: `${NAMESPACE}.services`,
    message: 'services',
  },
  INFORMATION: {
    key: `${NAMESPACE}.information`,
    message: 'information',
  },
  LASTAUDITDATE: {
    key: `${NAMESPACE}.lastauditdate`,
    message: 'lastauditdate',
  },
  NEXTAUDITDATE: {
    key: `${NAMESPACE}.nextauditdate`,
    message: 'nextauditdate',
  },
  OPENINGTIMES: {
    LABEL: {
      key: `${NAMESPACE}.openingtimes`,
      message: 'opening times',
    },
    ADD: {
      key: `${NAMESPACE}.openingtimes.add`,
      message: 'add',
    },
    ID: {
      key: `${NAMESPACE}.openingtimes.id`,
      message: 'id',
    },
    DAYOFWEEK: {
      key: `${NAMESPACE}.openingtimes.dayofweek`,
      message: 'day of week',
    },
    OPENINGHOUR: {
      key: `${NAMESPACE}.openingtimes.openinghour`,
      message: 'opening hour',
    },
    OPENINGMINUTES: {
      key: `${NAMESPACE}.openingtimes.openingminutes`,
      message: 'opening minutes',
    },
    CLOSINGHOUR: {
      key: `${NAMESPACE}.openingtimes.closinghour`,
      message: 'closing hour',
    },
    CLOSINGMINUTES: {
      key: `${NAMESPACE}.openingtimes.closingminutes`,
      message: 'closing minutes',
    },
  },
  PARTICIPANT: {
    key: `${NAMESPACE}.participant`,
    message: 'participant',
  },
  OWNER: {
    key: `${NAMESPACE}.owner`,
    message: 'owner',
  },
  CRCITY: {
    key: `${NAMESPACE}.crcity`,
    message: 'cr city',
  },
  CRNUMBER: {
    key: `${NAMESPACE}.crnumber`,
    message: 'cr number',
  },
  GNL: {
    key: `${NAMESPACE}.gnl`,
    message: 'gln',
  },
  TAXNUMBER: PERSON_I18N.TAX_NUMBER.LABEL,
  IDS: {
    key: `${NAMESPACE}.ids`,
    message: 'ids',
  },
  DISPLAYNAME: {
    key: `${NAMESPACE}.displayname`,
    message: 'displayname',
  },
  LEGAL_FORM: {
    key: `${NAMESPACE}.legalForm`,
    message: 'Legal form',
  },
  ERPNUMBER: {
    key: `${NAMESPACE}.erpnumber`,
    message: 'erpnumber',
  },
  ERPUSAGE: {
    key: `${NAMESPACE}.erpusage`,
    message: 'erpusage',
  },
  END_OF_CONTRACT: {
    key: `${NAMESPACE}.endOfContract`,
    message: 'End of Contract',
  },
  SYSTEM_SISE: {
    key: `${NAMESPACE}.systemSizeKwp`,
    message: 'system size',
  },
  YEAR: {
    key: `${NAMESPACE}.year`,
    message: 'year',
  },
  QUERY_MODE: {
    key: `${NAMESPACE}.queryMode`,
    message: 'query mode',
  },
  USERID: {
    key: `${NAMESPACE}.userid`,
    message: 'userid',
  },
  MMW_ADDRESS: {
    key: `${NAMESPACE}.mmwAddress`,
    message: 'MMW Address',
  },
  ERP_ADDRESS: {
    key: `${NAMESPACE}.erpAddress`,
    message: 'ERP Address',
  },
  MINIMUM_LOGIN_DATE: {
    key: `${NAMESPACE}.minimumLoginDate`,
    message: 'last login',
  },
  SALESTAXID: PERSON_I18N.VAT.LABEL,
  ADDRESS: {
    LABEL: PERSON_I18N.ADDRESS.LABEL,
    ADDRESSID: {
      key: `${NAMESPACE}.addressid`,
      message: 'addressid',
    },
    APIVERIFIED: {
      key: `${NAMESPACE}.apiverified`,
      message: 'apiverified',
    },
    CITY: PERSON_I18N.ADDRESS.CITY.LABEL,
    COMPANY: {
      key: `${NAMESPACE}.company`,
      message: 'company',
    },
    COUNTRY: PERSON_I18N.ADDRESS.COUNTRY.LABEL,
    LAT: {
      key: `${NAMESPACE}.lat`,
      message: 'lat',
    },
    LNG: {
      key: `${NAMESPACE}.lng`,
      message: 'lng',
    },
    FIRSTNAME: {
      key: `${NAMESPACE}.firstname`,
      message: 'firstname',
    },
    LASTNAME: {
      key: `${NAMESPACE}.lastname`,
      message: 'lastname',
    },
    NR: PERSON_I18N.ADDRESS.NUMBER.LABEL,
    STATE: PERSON_I18N.ADDRESS.STATE.LABEL,
    STREET: PERSON_I18N.ADDRESS.STREET.LABEL,
    ZIPCODE: PERSON_I18N.ADDRESS.ZIPCODE.LABEL,
  },
  MY_COMPANY: {
    key: `${NAMESPACE}.myCompany`,
    message: 'My Company',
  },
  UPLOAD_LOGO: {
    key: 'orgunit.uploadLogo',
    message: 'Upload image',
  },
  DRAG_AND_DROP_THE_COMPANY_LOGO: {
    key: `${NAMESPACE}.dragAndDropTheCompanyLogo`,
    message: 'Drag and drop the company logo',
  },
  FORMATS: {
    key: `${NAMESPACE}.formatsDragAndDropTheCompanyLogo`,
    message: 'FORMATS: jpg, png or svg',
  },
  MASTER_DATA: {
    LABEL: {
      key: `${NAMESPACE}.masterData.label`,
      message: 'master data',
    },
    EDIT: {
      key: `${NAMESPACE}.masterData.edit`,
      message: 'Edit',
    },
    CONTACT: {
      key: `${NAMESPACE}.masterData.contact`,
      message: 'Contact information',
    },
    LEGAL: {
      key: `${NAMESPACE}.masterData.legal`,
      message: 'Legal information',
    },
    COMMERCIAL_REGISTER_NUMBER: {
      key: `${NAMESPACE}.masterData.commercialRegisterNumber`,
      message: 'Comercial Register Number',
    },
    TERMS_DESCRIPTION: {
      key: `${NAMESPACE}.masterData.termsDescription`,
      message:
        'I’ve read and accept the General <strong>Terms and Conditions</strong>',
    },
  },
  STATUS: {
    LABEL: {
      key: `${NAMESPACE}.status.label`,
      message: 'Status',
    },
    ID: {
      key: `${NAMESPACE}.status.id`,
      message: 'partner id',
    },
    SINCE: {
      key: `${NAMESPACE}.status.since`,
      message: 'partner since',
    },
    EXPIRATION: {
      key: `${NAMESPACE}.status.expiration`,
      message: 'expiration',
    },
    // POOR I18N
    CERTIFICATE: {
      key: `${NAMESPACE}.status.certificate`,
      message: 'last certificate date',
    },
    ERPUSAGE: {
      key: `${NAMESPACE}.status.group`,
      message: 'GROUP',
    },
    CERTIFICATE_BUTTON: {
      key: `${NAMESPACE}.status.certificateButton`,
      message: 'I want to recertify',
    },
  },
  CERTIFICATE: {
    TITLE: {
      key: `${NAMESPACE}.status.title`,
      message: 'CERTIFICATE',
    },
  },
  POINT_OF_SALE: {
    LABEL: {
      key: `${NAMESPACE}.pointOfSale.label`,
      message: 'Point of Sale',
    },
    PLURAL_LABEL: {
      key: `${NAMESPACE}.pointsOfSaleLabel`,
      message: 'Points of Sale',
    },
    ADD_BRANCH: {
      key: `${NAMESPACE}.pointOfSale.addBranch`,
      message: 'Add New Contact Branch',
    },
    SHOW_LOCATOR: {
      key: `${NAMESPACE}.pointOfSale.showLocator`,
      message: 'Appear in Installer Locator?',
    },
    PERMISSION_WARNING: {
      key: `${NAMESPACE}.pointOfSale.permissionWarning`,
      message:
        "It looks like you don't have permission to see or edit store locator data, please ask your administrator to provide to you",
    },
    SHOW: {
      key: `${NAMESPACE}.pointOfSale.show`,
      message: 'Show',
    },
    NOTSHOW: {
      key: `${NAMESPACE}.pointOfSale.notShow`,
      message: 'Hide',
    },
    DELETE_CONTACT: {
      key: `${NAMESPACE}.pointOfSale.deleteContact`,
      message: 'This action deletes this point of sale',
    },
    NOTE_LOCATOR_DELAY: {
      key: `${NAMESPACE}.pointOfSale.noteLocatorDelay`,
      message: 'It may take up to 72 hours for this change to take effect.',
    },
  },
  USER_MANAGEMENT: {
    LABEL: {
      key: `${NAMESPACE}.userManagement.label`,
      message: 'User Management',
    },
    CANCEL,
    INVITE: {
      key: `${NAMESPACE}.userManagement.inviteUser`,
      message: 'Invite New User',
    },
    EMAIL_NOT_AVAILABLE: {
      key: `${NAMESPACE}.userManagement.emailNotAvailable`,
      message: 'This email is already in use, try to enter a different one',
    },
    DELETE_USER_MESSAGE: {
      key: `${NAMESPACE}.userManagement.deleteUserMessage`,
      message:
        'This action deletes this user from your team, you can add new users in the future',
    },
  },
  STORE_LOCATOR: {
    FEATURE_TITLE: {
      key: `${STORE_LOCATOR_NAMESPACE}.searchTitle`,
      message: 'Find a store near you',
    },
    SEARCH_STORE: {
      key: `${STORE_LOCATOR_NAMESPACE}.searchStore`,
      message: 'Search store',
    },
    USE_MY_LOCATION: {
      key: `${STORE_LOCATOR_NAMESPACE}.useMyLocation`,
      message: 'Use my location',
    },
    SEARCH_ERROR: {
      key: `${STORE_LOCATOR_NAMESPACE}.searchError`,
      message:
        'Failed to search stores, try again later or with another location',
    },
    SEARCH_RESULT: {
      key: `${STORE_LOCATOR_NAMESPACE}.searchResultMessage`,
      message:
        'Your search returned {count} {count, plural, =0 {store} one {store} other {stores}}',
    },
    SEARCH_BY_AVAILABLE_PRODUCTS: {
      key: `${STORE_LOCATOR_NAMESPACE}.searchByProductTitle`,
      message:
        'Are you looking for a specific product ready for demonstration?',
    },
    SEARCHING_FOR_PRODUCT_OR_GROUP: {
      key: `${STORE_LOCATOR_NAMESPACE}.searchingForProductOrProductGroup`,
      message: 'Found stores with following product or product group',
    },
    STORES_LOCATIONS: {
      key: `${STORE_LOCATOR_NAMESPACE}.storesLocationsTitle`,
      message: 'Stores locations',
    },
  },
};

export default I18N;
