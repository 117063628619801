const i18n = {
  IMPRINT: {
    key: 'imprint',
    message: 'Imprint',
  },
  DATA_PROTECTION: {
    key: 'dataProtection',
    message: 'Data protection',
  },
  GTC: {
    key: 'gtc',
    message: 'GTC',
  },
  OF: {
    key: 'of',
    message: 'of',
  },
  PERIOD: {
    key: `period`,
    message: 'Period',
  },
  PURCHASE_PERIOD: {
    key: 'purchasePeriod',
    message: 'Purchase period',
  },
  FROM: {
    key: 'from',
    message: 'From',
  },
  TO: {
    key: 'to',
    message: 'To',
  },
  DATEPICKER_TODAY: {
    key: 'today',
    message: 'Today',
  },
  TERMS_AND_CONDITIONS: {
    key: 'termsAndConditions',
    message: 'Terms and conditions',
  },
  REGISTRATION_STATUS: {
    key: 'registrationStatus',
    message: 'Registration Status',
  },
  ABOUT_REGISTRATION: {
    key: 'aboutRegistration',
    message: 'About registration',
  },
  INFORMATION: {
    key: 'information',
    message: 'Information',
  },
  CAMPAIGN: {
    key: 'campaign',
    message: 'Campaign',
  },
  CAMPAIGNS: {
    key: 'campaigns',
    message: 'Campaigns',
  },
  PARTNERS: {
    key: 'partners',
    message: 'Partners',
  },
  CONFIRMATION: {
    key: 'confirmation',
    message: 'Confirmation',
  },
  CORRECTION: {
    key: 'correction',
    message: 'Correction',
  },
  DASHBOARD: {
    key: 'dashboard',
    message: 'Dashboard',
  },
  HELP: {
    key: 'help',
    message: 'Help',
  },
  NEW_REGISTRATION: {
    key: 'newRegistration',
    message: 'New Registration',
  },
  EDIT_REGISTRATION: {
    key: 'editRegistration',
    message: 'Edit Registration',
  },
  REQUIRED: {
    key: 'required',
    message: 'required',
  },
  YES: {
    key: 'yes',
    message: 'Yes',
  },
  NO: {
    key: 'no',
    message: 'No',
  },
  OK: {
    key: 'ok',
    message: 'OK',
  },
  MENU: {
    key: 'menu',
    message: 'Menu',
  },
  HAS_ERRORS: {
    key: 'hasErrors',
    message: 'Has Errors',
  },
  ERROR: {
    key: 'error',
    message: 'Error',
  },
  NOT_FOUND: {
    key: 'notfound',
    message: 'Not Found',
  },
  VALIDATE: {
    key: 'validate',
    message: 'Validate',
  },
  REJECT: {
    key: 'reject',
    message: 'Reject',
  },
  ROTATE: {
    key: 'rotate',
    message: 'Rotate',
  },
  CLEAR_ROTATION: {
    key: 'clearRotation',
    message: 'Clear Rotation',
  },
  ACTION: {
    key: 'action',
    message: 'Action',
  },
  ALERT: {
    key: 'alert',
    message: 'Alert',
  },
  CLOSE: {
    key: 'close',
    message: 'Close',
  },
  CLOSE_TIME: {
    key: 'closeTime',
    message: 'Closes',
  },
  PROFILE: {
    key: 'profile',
    message: 'Profile',
  },
  SALESMAN: {
    key: 'salesman',
    message: 'Salesman',
  },
  SELECT: {
    key: 'select',
    message: 'Select',
  },
  STORE: {
    key: 'store',
    message: 'Store',
  },
  STORES_LIST: {
    key: 'storesList',
    message: 'Stores list',
  },
  START: {
    key: 'start',
    message: 'Start',
  },
  REGISTRATIONS: {
    key: 'registrations',
    message: 'Registrations',
  },
  REGISTRATION: {
    key: 'registration',
    message: 'Registration',
  },
  LOGOUT: {
    key: 'logout',
    message: 'Logout',
  },
  NOT_INFORMED: {
    key: 'notInformed',
    message: 'Not informed',
  },
  REGISTRATION_ID: {
    key: 'registrationId',
    message: 'Registration ID',
  },
  SORT_BY: {
    key: 'sortBy',
    message: 'Sort by',
  },
  LIST: {
    key: 'list',
    message: 'List',
  },
  ARCHIVED: {
    key: 'archived',
    message: 'Archived',
  },
  ACTIVE: {
    key: 'active',
    message: 'Active',
  },
  INACTIVE: {
    key: 'inactive',
    message: 'Inactive',
  },
  INSTALLATIONS: {
    key: 'Installations',
    message: 'Installations',
  },
  FIELDS_REQUIRED_LABEL: {
    key: 'requiredFieldsLabel',
    message: 'Required fields',
  },
  OPTIONAL: {
    key: 'optional',
    message: 'Optional',
  },
  USER_DATA: {
    key: 'userData',
    message: 'User data',
  },
  USER: {
    key: 'user',
    message: 'User',
  },
  USERS: {
    key: 'users',
    message: 'Users',
  },
  LAST_LOGIN: {
    key: 'lastLogin',
    message: 'Last login',
  },
  STATUS: {
    key: 'status',
    message: 'Status',
  },
  DEFAULT_SELECT_PLACEHOLDER: {
    key: 'defaultSelectPlaceholder',
    message: 'Select an item',
  },
  CUSTOMER_NUMBER: {
    key: 'customerNumber',
    message: 'Customer Number',
  },
  ROWS_PER_PAGE: {
    key: 'rowsPerPage',
    message: 'Rows per Page',
  },
  REMOVE: {
    key: 'remove',
    message: 'Remove',
  },
  DESCRIPTION: {
    key: 'description',
    message: 'Description',
  },
  DUE_DATE: {
    key: 'dueDate',
    message: 'Due date',
  },
  TICKET: {
    key: 'ticket',
    message: 'Ticket',
  },
  TICKETS: {
    key: 'tickets',
    message: 'Tickets',
  },
  PURCHASE: {
    key: 'purchase',
    message: 'Purchase',
  },
  LAST_3_MONTHS: {
    key: 'last3Months',
    message: 'Last 3 months',
  },
  LAST_6_MONTHS: {
    key: 'last6Months',
    message: 'Last 6 months',
  },
  LAST_12_MONTHS: {
    key: 'last12Months',
    message: 'Last 12 months',
  },
  ALL: {
    key: 'all',
    message: 'All',
  },
  NAME: {
    key: 'name',
    message: 'Name',
  },
  OPEN: {
    key: 'open',
    message: 'Open',
  },
  OPEN_TIME: {
    key: 'openTime',
    message: 'Opens',
  },
  PREVIEW: {
    key: `preview`,
    message: 'Preview',
  },
  VIEW: {
    key: 'view',
    message: 'view',
  },
  VISIBLE: {
    key: `visible`,
    message: 'Visible',
  },
  NOT_VISIBLE: {
    key: `notVisible`,
    message: 'Not visible',
  },
  HOVER_TO_ZOOM: {
    key: 'hoverToZoom',
    message: 'Hover to zoom',
  },
  LOADING: {
    key: 'loading',
    message: 'Loading',
  },
  PHOTOS: {
    key: 'photos',
    message: 'Photos',
  },
  REGISTERED: {
    key: 'registered',
    message: 'Registered',
  },
  STATEMENT: {
    key: 'statement',
    message: 'Statement',
  },
  CONVERT: {
    key: 'convert',
    message: 'Convert',
  },
  DATE: {
    key: 'date',
    message: 'Date',
  },
  TOTAL: {
    key: 'total',
    message: 'Total',
  },
  REGION: {
    key: 'region',
    message: 'Region',
  },
  CONDITION: {
    key: 'condition',
    message: 'Condition',
  },
  CONGRATULATIONS: {
    key: 'congratulations',
    message: 'Congratulations',
  },
  ATTENTION: {
    key: 'attention',
    message: 'Attention',
  },
  HELLO: {
    key: 'hello',
    message: 'Hello',
  },
  PASSWORD: {
    key: 'password',
    message: 'Password',
  },
  EMAIL: {
    key: 'email',
    message: 'E-mail',
  },
  LETS_GO: {
    key: 'letsGo',
    message: "Let's Go",
  },
  WELCOME: {
    key: 'welcome',
    message: 'Welcome',
  },
  CREDENTIALS: {
    key: 'credentials',
    message: 'Credentials',
  },
  PERSONAL_DATA: {
    key: 'personalData',
    message: 'Personal data',
  },
  CANCEL: {
    key: 'cancel',
    message: 'Cancel',
  },
  SUCCESS: {
    key: 'success',
    message: 'Success',
  },
  DOWNLOADS: {
    key: `downloads`,
    message: 'Downloads',
  },
  SIZE: {
    key: 'size',
    message: 'Size',
  },
  TYPE: {
    key: 'type',
    message: 'Type',
  },
  OWNER: {
    key: 'owner',
    message: 'Owner',
  },
  MODEL: {
    key: 'model',
    message: 'Model',
  },
  PAGE: {
    key: 'pages',
    message: 'Page',
  },
  OPERATION: {
    key: 'operation',
    message: 'Operation',
  },
  AMOUNT: {
    key: 'amount',
    message: 'Amount',
  },
  COMMENT: {
    key: 'comment',
    message: 'Comment',
  },
  COMMENTS: {
    key: 'comments',
    message: 'Comments',
  },
  BALANCE: {
    key: 'balance',
    message: 'Balance',
  },
  ENTITY: {
    key: 'entity',
    message: 'Entity',
  },
  ID: {
    key: 'id',
    message: 'ID',
  },
  FORGOT_PASSWORD: {
    key: 'forgotPassword',
    message: 'Forgot Password',
  },
  CREATE_PASSWORD: {
    key: 'createPassword',
    message: 'Create Password',
  },
  CREATED_AT: {
    key: 'createdAt',
    message: 'Created at',
  },
  EMAIL_LOGIN: {
    key: 'emailLogin',
    message: 'E-MAIL LOGIN',
  },
  MORE_INFO: {
    key: 'moreInfo',
    message: 'More Info',
  },
  LESS_INFO: {
    key: 'lessInfo',
    message: 'Less Info',
  },
  REMEMBER_MY_LOGIN: {
    key: 'rememberMyLogin',
    message: 'Remember My Login',
  },
  EDIT_PROFILE: {
    key: 'editProfile',
    message: 'Edit Profile',
  },
  YOUR_PROFILE: {
    key: 'yourProfile',
    message: 'Your Profile',
  },
  LIBRARY: {
    key: 'library',
    message: 'Library',
  },
  VIDEOS: {
    key: 'videos',
    message: 'Videos',
  },
  RECENT_NEWS: {
    key: 'recentNews',
    message: 'Recent News',
  },
  VIEW_ALL: {
    key: 'viewAll',
    message: 'View all',
  },
  HIDE_INFO: {
    key: 'hideInfo',
    message: 'Hide info',
  },
  POINTS: {
    key: 'points',
    message: '{count, plural, =0 {point} one {point} other {points}}',
  },
  POINTS_EARNED: {
    key: 'pointsEarned',
    message: 'Points earned',
  },
  POSITION: {
    key: 'position',
    message: 'Position',
  },
  ABOUT: {
    key: 'about',
    message: 'About',
  },
  ACTIVATED: {
    key: 'activated',
    message: 'Activated',
  },
  DEACTIVATED: {
    key: 'deactivated',
    message: 'Deactivated',
  },
  LESS_OR_EQUAL: {
    key: 'LESS_OR_EQUAL',
    message: 'LESS OR EQUAL',
  },
  GREATER_OR_EQUAL: {
    key: 'GREATER_OR_EQUAL',
    message: 'GREATER OR EQUAL',
  },
  RADIUS: {
    key: 'radius',
    message: 'Radius',
  },
  MORE_INFORMATION: {
    key: 'moreInformation',
    message: 'More information',
  },
  COOKIES: {
    key: 'cookies',
    message: 'Cookies',
  },
  QUANTITY: {
    key: 'quantity',
    message: 'Quantity',
  },
  WATTAGE: {
    key: 'wattage',
    message: 'Wattage',
  },
  EARNED: {
    key: 'earned',
    message: 'Earned',
  },
  ROLES: {
    key: 'roles',
    message: 'Roles',
  },
  SOURCE: {
    key: 'source',
    message: 'Source',
  },
  YEAR: {
    key: 'year',
    message: 'Year',
  },
  KAM: {
    key: 'kam',
    message: 'KAM',
  },
  ADMIN: {
    key: 'admin',
    message: 'ADMIN',
  },
  BRANDS: {
    key: 'brands',
    message: 'Brands',
  },
  HISTORY: {
    key: 'history',
    message: 'History',
  },
  BILLING: {
    key: 'billing',
    message: 'Billing',
  },
  VOUCHER: {
    key: 'voucher',
    message: 'Voucher',
  },
  COUPON: {
    key: 'coupon',
    message: 'Coupon',
  },
  SUMMARY: {
    key: 'summary',
    message: 'Summary',
  },
  SETTINGS: {
    key: 'settings',
    message: 'Settings',
  },
  PRODUCT_REGISTRATION: {
    key: 'productRegistration',
    message: 'Product registration',
  },
  THANK_YOU: {
    key: 'thankYou',
    message: 'Thank you',
  },
  FILTERS: {
    key: 'filters',
    message: 'Filters',
  },
  PRODUCTS: {
    key: 'products',
    message: 'Products',
  },
  CURRENT_CAMPAIGNS: {
    key: `currentCampaigns`,
    message: 'Current Campaigns',
  },
  VISIBLE_FOR_TRADERS: {
    key: `visibleForTraders`,
    message: 'Visible for Traders',
  },

  VISIBLE_FOR_CONSUMERS: {
    key: `visibleForConsumers`,
    message: 'Visible for Consumers',
  },
  REGISTRABLE: {
    key: `registrable`,
    message: 'Registrable',
  },
  LEGAL_ENTITY: {
    key: 'legalEntity',
    message: 'Legal entity',
  },
  CONTACT_INFORMATION: {
    key: 'contactInformation',
    message: 'Contact Information',
  },
  PARTICIPATIONS: {
    key: 'participations',
    message: 'Participations',
  },
};

export const {
  VISIBLE_FOR_TRADERS,
  VISIBLE_FOR_CONSUMERS,
  REGISTRABLE,
  CONDITION,
  CURRENT_CAMPAIGNS,
  ABOUT_REGISTRATION,
  ABOUT,
  ACTION,
  ACTIVATED,
  ACTIVE,
  ADMIN,
  ALERT,
  ALL,
  AMOUNT,
  ARCHIVED,
  ATTENTION,
  BALANCE,
  BILLING,
  BRANDS,
  CAMPAIGN,
  CAMPAIGNS,
  CANCEL,
  CLEAR_ROTATION,
  CLOSE_TIME,
  CLOSE,
  COMMENT,
  COMMENTS,
  CONFIRMATION,
  CONGRATULATIONS,
  CONVERT,
  COOKIES,
  CORRECTION,
  COUPON,
  CREATE_PASSWORD,
  CREDENTIALS,
  CUSTOMER_NUMBER,
  DASHBOARD,
  DATA_PROTECTION,
  DATE,
  DATEPICKER_TODAY,
  DEACTIVATED,
  LESS_OR_EQUAL,
  GREATER_OR_EQUAL,
  PERIOD,
  DEFAULT_SELECT_PLACEHOLDER,
  DESCRIPTION,
  DOWNLOADS,
  DUE_DATE,
  EARNED,
  EDIT_PROFILE,
  EDIT_REGISTRATION,
  EMAIL_LOGIN,
  EMAIL,
  ENTITY,
  ERROR,
  FILTERS,
  FIELDS_REQUIRED_LABEL,
  FORGOT_PASSWORD,
  FROM,
  GTC,
  HAS_ERRORS,
  HELLO,
  HELP,
  HIDE_INFO,
  HISTORY,
  HOVER_TO_ZOOM,
  ID,
  IMPRINT,
  INACTIVE,
  INFORMATION,
  INSTALLATIONS,
  KAM,
  LAST_LOGIN,
  LESS_INFO,
  LETS_GO,
  LIBRARY,
  LOADING,
  LOGOUT,
  MENU,
  MODEL,
  MORE_INFO,
  MORE_INFORMATION,
  NAME,
  NEW_REGISTRATION,
  NO,
  NOT_FOUND,
  NOT_INFORMED,
  NOT_VISIBLE,
  OF,
  OK,
  OPEN_TIME,
  OPEN,
  OPERATION,
  OPTIONAL,
  OWNER,
  PAGE,
  PASSWORD,
  PERSONAL_DATA,
  PHOTOS,
  POINTS_EARNED,
  POINTS,
  POSITION,
  PREVIEW,
  PRODUCT_REGISTRATION,
  PROFILE,
  PURCHASE,
  PURCHASE_PERIOD,
  QUANTITY,
  RADIUS,
  RECENT_NEWS,
  REGISTERED,
  REGISTRATION_ID,
  REGISTRATION_STATUS,
  REGISTRATION,
  REGISTRATIONS,
  REJECT,
  REMEMBER_MY_LOGIN,
  REMOVE,
  REQUIRED,
  ROLES,
  ROTATE,
  ROWS_PER_PAGE,
  SALESMAN,
  SELECT,
  SETTINGS,
  SIZE,
  SORT_BY,
  SOURCE,
  START,
  STATEMENT,
  STATUS,
  STORE,
  STORES_LIST,
  SUCCESS,
  SUMMARY,
  TERMS_AND_CONDITIONS,
  THANK_YOU,
  TICKET,
  TICKETS,
  TO,
  TOTAL,
  TYPE,
  USER_DATA,
  USER,
  USERS,
  VALIDATE,
  VIDEOS,
  VIEW,
  VIEW_ALL,
  VISIBLE,
  VOUCHER,
  WATTAGE,
  WELCOME,
  YEAR,
  YES,
  YOUR_PROFILE,
  PARTNERS,
  REGION,
  CREATED_AT,
  PRODUCTS,
  LEGAL_ENTITY,
  CONTACT_INFORMATION,
  PARTICIPATIONS,
} = i18n;

export default i18n;
