import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { F } from 'ts-toolbelt';

import { submitStartAction } from '../store/actions';
import useFormId from './useFormId';
import useFormValidationSchema from './useFormValidationSchema';

function useSubmitStart(): F.Function {
  const dispatch = useDispatch();
  const formId = useFormId();
  const validationSchema = useFormValidationSchema();
  return useCallback(() => {
    dispatch(submitStartAction(formId, validationSchema));
  }, [dispatch, formId, validationSchema]);
}

export function useSubmitStartByFormID(formId: string): F.Function {
  const dispatch = useDispatch();
  const validationSchema = useFormValidationSchema();
  return useCallback(() => {
    dispatch(submitStartAction(formId, validationSchema));
  }, [dispatch, formId, validationSchema]);
}

export default useSubmitStart;
