export const RETAIL_CLIENT = 'TraderClient';
export const RETAIL_CLIENT_V4 = 'RetailClientV4';
export const JURA_SUPPORT_APP = 'JuraSupportApp';
export const RETAIL_CLIENT_V4_REC = 'RetailClientV4Rec';
export const REC_PROJECTS_APP = 'RecProjectApp';
export const RETAIL_CLIENT_KWS = 'KwsWebClient';
export const RETAIL_CLIENT_AEG = RETAIL_CLIENT_V4;
export const RETAIL_CLIENT_ORALB = RETAIL_CLIENT_V4;
export const RETAIL_CLIENT_JURA = RETAIL_CLIENT_V4;
export const RETAIL_CLIENT_ROMMELSBACHER = RETAIL_CLIENT_V4;
export const RETAIL_CLIENT_MIELE = RETAIL_CLIENT_V4;
export const RETAIL_CLIENT_SAMSUNG = RETAIL_CLIENT_V4;
export const RETAIL_CLIENT_BOSCH = RETAIL_CLIENT_V4;
export const RETAIL_CLIENT_BOSCH_AT = RETAIL_CLIENT_V4;
export const RETAIL_CLIENT_BOSCH_CH = RETAIL_CLIENT_V4;
export const RETAIL_CLIENT_BOSCH_LU = RETAIL_CLIENT_V4;
export const RETAIL_CLIENT_GROHE = RETAIL_CLIENT_V4;
export const RETAIL_CLIENT_GROHE_AT = RETAIL_CLIENT_V4;
export const RETAIL_CLIENT_DELONGHI = RETAIL_CLIENT_V4;
export const RETAIL_CLIENT_SHARKNINJA = RETAIL_CLIENT_V4;
export const RETAIL_CLIENT_SIEMENS = RETAIL_CLIENT_V4;
export const RETAIL_CLIENT_MEGABRAND = RETAIL_CLIENT_V4;
export const RETAIL_CLIENT_PANASONIC = RETAIL_CLIENT_V4;
export const RETAIL_CLIENT_SIEMENS_AT = RETAIL_CLIENT_V4;
export const RETAIL_CLIENT_SIEMENS_CH = RETAIL_CLIENT_V4;
export const RETAIL_CLIENT_SIEMENS_LU = RETAIL_CLIENT_V4;
export const RETAIL_CLIENT_CANON = RETAIL_CLIENT_V4;
export const RETAIL_CLIENT_SONY = RETAIL_CLIENT_V4;
export const RETAIL_CLIENT_ZEISS = RETAIL_CLIENT_V4;
export const RETAIL_CLIENT_GIGASET = RETAIL_CLIENT_V4;
export const RETAIL_CLIENT_REC = RETAIL_CLIENT_V4_REC;
export const CONSUMER_CAMPAIGN_CLIENT = 'ConsumerCampaignClient';
export const SERVICE_DOCUMENTS = 'ServiceDocuments';
export const LABELS = 'Labels';
export const QUESTIONS = 'Questions';
export const ADDED_VALUE = 'AddedValue';
export const Mails = 'Mails';
export const CONSUMER_CLIENT = 'ConsumerClient';
