import 'tippy.js/themes/light.css';

import { useSelectedStore } from '@store-locator/store-creator';
// import useIsVisible from '@mmw/ui-hooks-use-is-visible';
import { css } from '@ui-system/css';
import { useIsMediaQueryUpToSM } from '@ui-system/media-query';
import UI from '@ui-system/ui';
import { useBoolean } from 'ahooks';
import React, { useEffect, useMemo } from 'react';
import { Tooltip } from 'tooltip';
import { U } from 'ts-toolbelt';

import Details from './details';
import StoreName from './StoreName';

const MAP_SCROLL_TO_ZOOM_OVERLAY_STYLE = document.createElement('style');
MAP_SCROLL_TO_ZOOM_OVERLAY_STYLE.innerHTML = `
  .gm-style-moc {
    z-index: -1 !important;
  }
`;

interface ContentProps {
  storeId: U.Nullable<number>;
  limitTitleWidth?: boolean;
}

const StorePinContent: React.FC<ContentProps> = ({
  storeId,
  limitTitleWidth,
}: ContentProps) => {
  const isMobile = useIsMediaQueryUpToSM();
  return (
    <UI.Container
      direction="column"
      style={css`
        min-width: ${isMobile ? '100%' : '450px'};
        z-index: 999999;
        max-width: 500px;
        min-height: 150px;
        max-height: 650px;
        overflow: auto;
      `}
      bg="white"
      p="2"
    >
      <StoreName storeId={storeId} limitWidth={limitTitleWidth} hidePin />
      <Details storeId={storeId} contentPadding="1" />
    </UI.Container>
  );
};

const StoreInfoTooltip: React.FC<
  React.PropsWithChildren<Omit<ContentProps, 'limitTitleWidth'>>
> = React.memo(
  ({
    storeId,
    children,
  }: React.PropsWithChildren<Omit<ContentProps, 'limitTitleWidth'>>) => {
    const [visible, { toggle }] = useBoolean();
    const selectedStoreId = useSelectedStore();
    const isMobile = useIsMediaQueryUpToSM();
    const shouldShowTooltip = useMemo(
      () => !isMobile && selectedStoreId === storeId,
      [isMobile, selectedStoreId, storeId],
    );
    const tootltipProps = useMemo(
      () => ({
        theme: 'light',
        interactive: true,
        maxWidth: 'max-content',
        visible,
        onClickOutside: toggle,
        modifiers: 'flip',
        ignoreAttributes: true,
        popperOptions: {
          modifiers: [
            {
              name: 'flip',
              options: {
                fallbackPlacements: ['top', 'bottom', 'right'],
              },
            },
          ],
        },
        /**
         * XXX
         * commented this to mantain the position of tooltip when map zoom changes
         * (this could cause the tooltip to hide behind the search content card
         * in some positions, with appendTo option, tooltip was always on top of all);
         */
        // appendTo: document.body,
      }),
      [toggle, visible],
    );

    useEffect(() => {
      if (visible) {
        document.head.appendChild(MAP_SCROLL_TO_ZOOM_OVERLAY_STYLE);
      }

      return () => {
        if (document.head.contains(MAP_SCROLL_TO_ZOOM_OVERLAY_STYLE)) {
          document.head.removeChild(MAP_SCROLL_TO_ZOOM_OVERLAY_STYLE);
        }
      };
    }, [visible]);

    useEffect(() => {
      if (shouldShowTooltip) {
        toggle();
      }
    }, [shouldShowTooltip]);

    if (isMobile) {
      return (
        <>
          {React.cloneElement(children as React.ReactElement<any>, {
            onClick: toggle,
          })}
          <UI.Modal
            style={css`
              padding: 0 5px;
              margin-top: 100px;
            `}
            visible={visible}
            toggle={toggle}
          >
            <StorePinContent storeId={storeId} limitTitleWidth />
          </UI.Modal>
        </>
      );
    }

    return (
      <Tooltip
        placement="right"
        overlay={<StorePinContent storeId={storeId} />}
        tooltipProps={tootltipProps}
      >
        {React.cloneElement(children as React.ReactElement<any>, {
          onClick: toggle,
        })}
      </Tooltip>
    );
  },
);

export default StoreInfoTooltip;
