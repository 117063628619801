import { TraderJSON } from '@mmw/services-core-store-locator/types';
import { OverlayView, OverlayViewF } from '@react-google-maps/api';
import { useStoreItemById } from '@store-locator/store-creator';
import UI from '@ui-system/ui';
import map from 'lodash/map';
import React, { useMemo } from 'react';
import { U } from 'ts-toolbelt';

import { useCustomMapMarker } from '../../context';
import { getStorePosition } from '../../utils';
import StoreInfoTooltip from '../StoreInfoTooltip';

const getPixelPositionOffset = (width: number, height: number) => ({
  x: -(width / 2),
  y: -(height / 2),
});

interface Props {
  storeId: number;
}

const StorePin: React.FC<Props> = React.memo(({ storeId }: Props) => {
  const CustomMapMark = useCustomMapMarker();
  const store = useStoreItemById(storeId);
  const sotePosition = useMemo(
    () => getStorePosition(store as TraderJSON),
    [store],
  );

  return (
    <OverlayViewF
      key={`${sotePosition.lat}, ${sotePosition.lng}`}
      position={sotePosition}
      getPixelPositionOffset={getPixelPositionOffset}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
    >
      <StoreInfoTooltip storeId={storeId}>
        {CustomMapMark ? (
          <CustomMapMark store={store} />
        ) : (
          <UI.Icon name="local" color="primary" size={45} />
        )}
      </StoreInfoTooltip>
    </OverlayViewF>
  );
});

interface MarkerProps {
  storesList: U.Nullable<number[]>;
}

const TraderMarkers: React.FC<MarkerProps> = ({ storesList }: MarkerProps) => {
  if (!storesList) return null;

  return (
    <>
      {map(storesList, storeId => {
        if (!storeId) return null;
        return <StorePin storeId={storeId} key={storeId} />;
      })}
    </>
  );
};

export default TraderMarkers;
