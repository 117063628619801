import { fastHash } from '@hasher/object-hash';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MUIMenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Popper from '@material-ui/core/Popper';
import { EMPTY_OBJECT } from '@shared-utils/object';
import { useMenuItemStyle, useMenuStyle } from '@ui-system/default-styles';
import {
  MenuContainerProps,
  MenuContainerType,
  MenuItemComponentProps,
  MenuOption,
  MenuOptionInterface,
  MenuProps,
  MenuType,
} from '@ui-system/interfaces-menu';
import { makeStyle } from '@ui-system/style';
import UI from '@ui-system/ui';
import { useBoolean, useHover } from 'ahooks';
import map from 'lodash/map';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';

interface MenuItemProps {
  item: MenuOptionInterface;
  style: MenuProps['menuItemStyle'];
  isFirstChild: boolean;
  isLastChild: boolean;
  Component?: React.FC<MenuItemComponentProps>;
  round?: boolean;
}

const MenuItem: React.FC<MenuItemProps> = ({
  item,
  style,
  Component,
  isFirstChild,
  isLastChild,
  round,
}: MenuItemProps) => {
  const menuOption = useMemo(() => new MenuOption(item), [item]);
  const ref = useRef();
  const hover = useHover(ref);
  const menuItemFinalStyle = useMenuItemStyle(style, null, null, {
    isHovering: hover,
    isFirstChild,
    isLastChild,
    round,
  });

  const handleClick = menuOption.useOnClick();

  const onClick = useCallback(() => {
    if (menuOption.onClick) menuOption.onClick();
    else handleClick();
  }, [handleClick, menuOption]);
  const visible = menuOption.useIsVisible();

  if (!visible) return null;
  return (
    <MUIMenuItem
      // @ts-ignore
      ref={ref}
      onClick={onClick}
      key={fastHash(menuOption)}
      style={menuItemFinalStyle}
      round={round}
    >
      {Component ? (
        <Component
          option={menuOption}
          isHovering={hover}
          onClick={onClick}
          isFirstChild={isFirstChild}
          isLastChild={isLastChild}
        />
      ) : (
        <UI.Caption
          i18n={menuOption.label}
          modifiers={`center, ${hover ? 'white' : ''}`}
          {...(menuOption.labelProps || EMPTY_OBJECT)}
        />
      )}
    </MUIMenuItem>
  );
};

const STYLE = {
  zIndex: 99999,
};

type ListFirstOptionComponentProps = {
  isHovering: boolean;
};

type MenuListCustomComponentProps = {
  style: MenuProps['menuItemStyle'];
  round?: boolean;
  onClose: () => void;
  Component: React.FC<ListFirstOptionComponentProps>;
};

const MenuListCustomComponent: React.FC<MenuListCustomComponentProps> = ({
  style,
  Component,
  round,
  onClose,
}: MenuListCustomComponentProps) => {
  const ref = useRef();
  const hover = useHover(ref);
  const menuItemFinalStyle = useMenuItemStyle(style, null, null, {
    isHovering: hover,
    isFirstChild: true,
    isLastChild: false,
    round,
  });
  return (
    <MUIMenuItem
      // @ts-ignore
      ref={ref}
      onClick={onClose}
      style={menuItemFinalStyle}
      round={round}
    >
      <Component isHovering={hover} />
    </MUIMenuItem>
  );
};

const MenuView: MenuType = ({
  isOpen,
  anchorEl,
  options,
  children,
  onClose,
  style,
  menuItemStyle,
  MenuItemComponent,
  round,
  innerRef,
  ListFirstOptionComponent,
}: MenuProps): React.ReactElement => {
  const optionsLength = useMemo(
    () => options?.length || 0,
    [options],
  ) as number;
  const menuStyle = useMenuStyle(style, null, null, { round });

  return (
    <Popper open={isOpen} anchorEl={anchorEl?.current} style={STYLE}>
      <UI.Animation>
        <ClickAwayListener onClickAway={onClose}>
          <div>
            {children || (
              <MenuList
                innerRef={innerRef}
                autoFocusItem={isOpen}
                style={menuStyle}
              >
                {ListFirstOptionComponent && (
                  <MenuListCustomComponent
                    style={menuItemStyle}
                    round={round}
                    onClose={onClose}
                    Component={ListFirstOptionComponent}
                  />
                )}
                {map(options, (item, index) => (
                  <MenuItem
                    item={item}
                    style={menuItemStyle}
                    key={fastHash(item)}
                    Component={MenuItemComponent}
                    isFirstChild={index === 0 && !ListFirstOptionComponent}
                    isLastChild={index === optionsLength - 1}
                    round={round}
                  />
                ))}
              </MenuList>
            )}
          </div>
        </ClickAwayListener>
      </UI.Animation>
    </Popper>
  );
};

const useDivStyle = makeStyle(
  ({ css }) => css`
    display: flex;
    height: 100%;
  `,
);

// ${props.isOpen ? 'visibility: hidden' : ''};

export const MenuContainer: MenuContainerType = ({
  children,
  menuChildren,
  trigger,
  options,
  onToggleSubMenu,
  onKeyDown,
  customItemContainerStyle,
  round,
  ...rest
}: MenuContainerProps) => {
  const ref = useRef();
  const [isOpen, { setFalse, toggle }] = useBoolean(false);
  const divStyle = useDivStyle();

  const onClick = useCallback(() => {
    if (trigger?.includes('click')) toggle();
  }, [toggle, trigger]);

  const onMouse = useCallback(() => {
    if (trigger?.includes('hover')) toggle();
  }, [toggle, trigger]);

  useEffect(() => {
    if (onToggleSubMenu) {
      onToggleSubMenu(isOpen);
    }
  }, [isOpen]);

  return (
    <div
      // @ts-ignore
      ref={ref}
      style={customItemContainerStyle || divStyle}
      onMouseEnter={onMouse}
      onMouseLeave={onMouse}
      onClick={onClick}
      onKeyDown={onKeyDown || onClick}
      role="button"
      tabIndex={0}
    >
      {children}
      <MenuView
        isOpen={isOpen}
        onClose={setFalse}
        anchorEl={ref}
        options={options}
        round={round}
        {...rest}
      >
        {menuChildren}
      </MenuView>
    </div>
  );
};

MenuContainer.defaultProps = {
  trigger: 'hover',
};

MenuView.MenuContainer = MenuContainer;

export default MenuView;
