import { TraderJSON } from '@mmw/services-core-store-locator/types';
import { useStoreItemById } from '@store-locator/store-creator';
import { css } from '@ui-system/css';
import UI from '@ui-system/ui';
import get from 'lodash/get';
import React, { useMemo } from 'react';
import { U } from 'ts-toolbelt';

import {
  useCustomCardTitle,
  useCustomMapMarker,
  useCustomStoreName,
} from '../context';

interface CommonProps {
  store: TraderJSON;
}

export const NameComponent: React.FC<CommonProps> = ({
  store,
  ...porps
}: CommonProps) => {
  const CustomStoreName = useCustomStoreName();
  const name = useMemo(
    () => store?.displayname || get(store, 'address.company'),
    [store],
  );
  if (!CustomStoreName && !name) return null;
  if (CustomStoreName) {
    return <CustomStoreName store={store} />;
  }
  return (
    <UI.Typography modifiers="bold, capitalize" {...porps}>
      {name}
    </UI.Typography>
  );
};

interface StoreNameProps {
  storeId: U.Nullable<number>;
  limitWidth?: boolean;
  hidePin?: boolean;
}

const StoreName: React.FC<StoreNameProps> = ({
  storeId,
  limitWidth,
  hidePin,
}: StoreNameProps) => {
  const CustomCardTitle = useCustomCardTitle();
  const CustomMapMark = useCustomMapMarker();
  const store = useStoreItemById(storeId);
  return (
    <UI.Container
      style={css`
        ${limitWidth ? 'max-width: 260px' : ''};
      `}
      m="0, 0, 3, 0"
      gap={2}
    >
      {!hidePin ? (
        <>
          {CustomMapMark ? (
            <CustomMapMark store={store} />
          ) : (
            <UI.Icon name="local" size={25} color="gray.A200" />
          )}
        </>
      ) : null}
      {CustomCardTitle ? (
        <CustomCardTitle store={store} />
      ) : (
        <NameComponent store={store as TraderJSON} />
      )}
    </UI.Container>
  );
};

export default StoreName;
