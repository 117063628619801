const namespace = 'projectsList';
const registrationSourceNamespace = 'project.registrationSource';

const PAGE = {
  TITLE: {
    key: `${namespace}.navTitle`,
    message: 'My registrations',
  },
  SEARCH_RESULT: {
    key: `${namespace}.projectsFound`,
    message: '{count, plural, one {# project} other {# projects}} was found.',
  },
  SEARCH_RESULT_IE11: {
    key: `${namespace}.projectsFound.ie11`,
    message: 'Total projects found:',
  },
  NO_PROJECTS_FOUND: {
    key: `${namespace}.noProjectsFound`,
    message: 'No projects found.',
  },
  DOWNLOAD_SELECTED_CERTIFICATE: {
    key: `${namespace}.downloadSelectedCertificate`,
    message: 'Download Selected Certificates',
  },
  SELECTED_PROJECTS: {
    key: `${namespace}.selectedProjects`,
    message:
      'Verify all selected projects below. If they are marked in red, it means the project cant perform the action. If you click in the check mark, the project will be discarted from this.',
  },
};

const filterNamespace = `${namespace}.filter`;

const FILTER = {
  DESCRIPTION: {
    key: `${filterNamespace}.description`,
    message: 'Organize your list of projects through search filters',
  },
  PROJECT: {
    LABEL: {
      key: `${filterNamespace}.project.label`,
      message: 'Project Filters',
    },
    BYID: {
      key: `${filterNamespace}.project.searchById`,
      message: 'Search by Project ID',
    },
    REGISTRATION_DATE: {
      key: `${filterNamespace}.project.registrationDate`,
      message: 'Date of registration',
    },
    CITY: {
      key: `${filterNamespace}.project.city`,
      message: 'City',
    },
  },
  CUSTOMER: {
    LABEL: {
      key: `${filterNamespace}.customer.label`,
      message: 'End Customer Filters',
    },
    LAST_NAME: {
      key: `${filterNamespace}.customer.lastName`,
      message: 'Filter by Customer Last Name',
    },
    COUNTRY: {
      key: `${filterNamespace}.customer.country`,
      message: 'Filter by Country',
    },
  },
  PANEL: {
    LABEL: {
      key: `${filterNamespace}.panel.label`,
      message: 'Panels Filters',
    },
    SERIAL_NUMBER: {
      key: `${filterNamespace}.panel.serialNumber`,
      message: 'Serial number',
    },
  },
  STATUS: {
    LABEL: {
      key: `${filterNamespace}.status.label`,
      message: 'Filter by Status',
    },
    CONCEPT: {
      key: `${filterNamespace}.concept`,
      message: 'Concept',
    },
    INVALID: {
      key: `${filterNamespace}.invalid`,
      message: 'Invalid',
    },
    COMPLETED: {
      key: `${filterNamespace}.registered`,
      message: 'Registered',
    },
  },
  REGISTRATION_SOURCE: {
    REC_APP: {
      key: `${registrationSourceNamespace}.sunSnap`,
      message: 'REC SunSnap App',
    },
    RTC_REC: {
      key: `${registrationSourceNamespace}.retailRec`,
      message: 'REC ProPortal',
    },
    RTC_REC_MASS_UPLOAD: {
      key: `${registrationSourceNamespace}.retailRecMassUpload`,
      message: 'REC ProPortal - Mass upload',
    },
    SOLAR_THIRD_PARTY: {
      key: `${registrationSourceNamespace}.solarThirdParty`,
      message: 'Formbay',
    },
    MANU_REC: {
      key: `${registrationSourceNamespace}.manufacturerClient`,
      message: 'REC ProPortal Admin',
    },
    MANU_REC_MASS_UPLOAD: {
      key: `${registrationSourceNamespace}.manufacturerClientMassUpload`,
      message: 'REC ProPortal Admin - Mass upload',
    },
  },
};

const I18N = {
  PAGE,
  FILTER,
  BUTTONS: {
    FILTER: {
      key: `${namespace}.filterButton`,
      message: 'Filter',
    },
    SEARCH: {
      key: `${namespace}.searchButton`,
      message: 'Search',
    },
    RESET: {
      key: `${namespace}.resetButton`,
      message: 'Reset',
    },
    ACTIONS: {
      key: `${namespace}.actionsButton`,
      message: 'Actions',
    },
  },
};

export default I18N;
