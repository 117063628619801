import { SalesOrgBrand } from '@mmw/constants-salesorgbrand-ids';
import { U } from 'ts-toolbelt';

export type ProducteRatingValueReturn = {
  productID: number;
  rating: U.Nullable<number>;
};

export enum ClassOfGoodType {
  CLASSOFGOOD = 'CLASSOFGOOD',
  CAMPAIGN_CLASSOFGOOD = 'CAMPAIGN_CLASSOFGOOD',
}

export type ConsumerProductClassOfGoodsJSON = {
  id: number;
  image: string;
  imageUrl: string;
  name: string;
  sortorder: number;
  departmentID: number;
  type: ClassOfGoodType;
};

export type ConsumerClassOfGoodsRequestParams = {
  salesOrgBrandID?: SalesOrgBrand;
  brand?: number;
  classOfGood?: U.Nullable<number>;
  country?: string;
  enabledPortals?: number;
  onlyWhichHasProducts?: boolean;
};

export type TagRequest = {
  categoryID: number;
  values: number[];
  searchType: number;
};

export type ConsumerProductSearchRequestParams = {
  sortOrder?: number;
  classOfGoodsIDs?: number[];
  campaignClassOfGoodsIDs?: number[];
  productGroupIDs?: number[];
  ean?: string;
  eans?: string[];
  serialnumber?: string;
  imei?: string;
  erpMaterialKey?: string;
  erpProductCode?: string;
  name?: string;
  brandIDs?: number[];
  salesOrgBrandIDs?: SalesOrgBrand[];
  campaignIDs?: number[];
  campaignCodes?: string[];
  traderIDs?: number[];
  tags?: TagRequest[];
  offset?: number;
  limit?: number;
};

export type TagSearchResult = {
  categoryID: number;
  brandID: number;
  categoryResourcekey: string;
  categoryResource: string;
  searchType: number;
  unit: string;
  valueResourcekey: string;
  valueResource: string;
  value: number;
  total: number;
};

export type ConsumerProductJSON = {
  name: string;
  ean: string;
  erpMaterialKey: string;
  erpProductCode: string;
  id: number;
  image: string;
  salesOrgBrandID: SalesOrgBrand;
  brand: string;
  serialHelpText: string;
  couponHelpText: string;
  serialHelpImage: string;
  couponHelpImage: string;
  flagchecknr: boolean;
  classOfGoodID: number;
  classOfGoodName: string;
  campaignClassOfGoodID: number;
  campaignClassOfGoodName: string;
  serialnumber: string;
  deliveryDate: Date;
  tags: TagSearchResult[];
};
