import '@mmw/yup-validation-zipcode';

import { DEFAULT as I18N_VALIDATIONS } from '@mmw/constants-i18n-validations';
import contextualConfig from '@mmw/contextual-config';
import { getFieldPaths } from '@mmw/utils-object-utils';
import { U } from 'ts-toolbelt';
import { number, object, ObjectShape, string } from 'yup';

const { defaultCountry } = contextualConfig.application;
export class StoreLocatorSearchFormValues {
  constructor(radius?: number) {
    if (radius) {
      this.radius = radius;
    }
  }

  city = '';

  zipcode = '';

  state = '';

  radius = 20;

  country = defaultCountry;
}

export const STORE_LOCATOR_FIELDPATHS = getFieldPaths(
  new StoreLocatorSearchFormValues(),
);

export const generateValidationSchema = <
  AdditionalSchema extends U.Nullable<ObjectShape>,
>(
  additionalValidations: AdditionalSchema,
) =>
  object<StoreLocatorSearchFormValues & AdditionalSchema>().shape({
    [STORE_LOCATOR_FIELDPATHS.country.$path]: string().required(),
    [STORE_LOCATOR_FIELDPATHS.city.$path]: string().test(
      'required-if-no-zipcode',
      I18N_VALIDATIONS.REQUIRED.key,
      (value, context) => {
        if (context?.parent?.zipcode) {
          return true;
        }
        if (value) {
          return true;
        }
        return false;
      },
    ),
    [STORE_LOCATOR_FIELDPATHS.zipcode.$path]: string()
      .test(
        'required-if-no-city',
        I18N_VALIDATIONS.REQUIRED.key,
        (value, context) => {
          if (context?.parent?.city) {
            return true;
          }
          if (value) {
            return true;
          }
          return false;
        },
      )
      // @ts-ignore
      .zipcode(),
    [STORE_LOCATOR_FIELDPATHS.radius.$path]: number(),
    ...additionalValidations,
  });
