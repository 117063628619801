import { css } from '@ui-system/css';
import UI from '@ui-system/ui';
import map from 'lodash/map';
import React from 'react';
// import InfiniteScroll from 'react-infinite-scroll-component';
import { U } from 'ts-toolbelt';

import ExpansibleCard from '../ExpansibleCard';

interface Props {
  storesList: U.Nullable<number[]>;
}

const StoreCardsList: React.FC<Props> = ({ storesList }: Props) => (
  <UI.Container
    direction="column"
    style={css`
      height: 100%;
    `}
  >
    {map(storesList, storeId => (
      <ExpansibleCard key={storeId} storeId={storeId} />
    ))}
  </UI.Container>
);
export default StoreCardsList;
