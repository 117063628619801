import config from '@mmw/config';
import { REC_STORE_LOCATOR } from '@mmw/constants-application-ids';
import { RETAIL_CLIENT_REC as DEFAULT_RESOURCE_BUNDLE_NAME } from '@mmw/constants-bundle-names';
import { RETAIL_CLIENT_REC as CONTEXT_PATH } from '@mmw/constants-context-paths';
import { GERMANY, REC_COUNTRIES_CODES } from '@mmw/constants-country-codes';
import { DE, EN, ES, FR, IT, JA, NL, PL } from '@mmw/constants-languages';
import { REC as SALESORGBRAND_ID } from '@mmw/constants-salesorgbrand-ids';
import { ContextualConfig } from '@mmw/contextual-config';
import log from '@mmw/logging-logger';
import detectBrowserLanguage, {
  getUserDeviceCountryByTimeZone,
} from '@mmw/web-utils-detect-browser-language';

const APPLICATION_NAME = 'rec-store-locator';
const logger = log.extend(APPLICATION_NAME);

const APPLICATION_ID = REC_STORE_LOCATOR;
const SUPPORTED_COUNTRIES = REC_COUNTRIES_CODES;
const SUPPORTED_LANGUAGES = [DE, EN, ES, FR, IT, JA, NL, PL];
const DEFAULT_LANGUAGE = detectBrowserLanguage(SUPPORTED_LANGUAGES, EN);
const DEFAULT_COUNTRY = getUserDeviceCountryByTimeZone(
  REC_COUNTRIES_CODES,
  GERMANY,
);
const contextualConfig: ContextualConfig = {
  ...config,
  // Contextual Configs (by application)
  application: {
    displayName: 'Rec Store Locator',
    applicationId: APPLICATION_ID,
    salesOrgBrandID: SALESORGBRAND_ID,
    defaultLanguage: DEFAULT_LANGUAGE,
    supportedLanguages: SUPPORTED_LANGUAGES,
    supportedCountries: SUPPORTED_COUNTRIES,
    defaultCountry: DEFAULT_COUNTRY,
    defaultResourceBundleName: DEFAULT_RESOURCE_BUNDLE_NAME,
    checkAuthenticationTimeout: 15 * 60 * 1000,
    logger,
    version: process.env.REACT_APP_VERSION as string,
  },
  webApplication: {
    contextPath: CONTEXT_PATH,
  },
  logs: {
    applicationId: APPLICATION_NAME,
  },
  script: undefined,
  googleMaps: {
    apikey: 'AIzaSyDQO5GzkNCu_yNCM1NSO-wW_u1uLew6OTc',
    routeBase: 'https://maps.google.com/maps',
  },
  googleTagManager: {
    gtmId: 'GT-TXH7PZ9',
  },
  googleAnalytics: {
    trackingID: 'G-PNCN8F593Y',
  },
  // XXX: section to OVERRIDE ...config
  core: {
    authApi: {
      accessTokenKey: 'twc:auth-api:mmw-cli-auth-api:access-token', // XXX: detect retail client token
      refreshTokenKey: 'twc:auth-api:mmw-cli-auth-api:refresh-token', // XXX: detect retail client token
    },
  },
  store: {
    defaultNamespace: 'global',
    authenticationNamespace: '', // XXX: share with retail client local store namespace!
    fingerprintNamespace: 'fingerprint',
  },
  links: {
    common: {
      imprint: 'https://www.rec.com/de-de/impressum',
      dataProtection: 'https://www.rec.com/de-de/datenschutzerklarung',
    },
  },
};

export default contextualConfig;
