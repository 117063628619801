import { I18nShape } from '@mmw/constants-i18n';
import { CONFIRM, DECLINE } from '@mmw/constants-i18n-buttons';
import { MORE_INFORMATION } from '@mmw/constants-i18n-common';
import contextualConfig from '@mmw/contextual-config';
import { PerfectScroll } from '@perfect-scroll/core';
import { css } from '@ui-system/css';
import { Style } from '@ui-system/interfaces';
import { useIsMediaQueryUpToSM } from '@ui-system/media-query';
import { makeStyle } from '@ui-system/style';
import UI from '@ui-system/ui';
import { F } from '@utils/ts';
import compact from 'lodash/compact';
import forEach from 'lodash/forEach';
import forIn from 'lodash/forIn';
import includes from 'lodash/includes';
import map from 'lodash/map';
import moment from 'moment';
import React, { useCallback, useMemo, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useDeepCompareEffect } from 'use-deep-compare';

import { ALL_COOKIES_CONFIGS, LOCAL_STORAGE_KEY } from './constants';
import {
  Cookies,
  useAvailableCookiesState,
  useCookieStateByKey,
  useInitializeCookiesState,
  useOnDeclineAll,
  useSetCookieStateAction,
  useUseCookiesModal,
} from './cookiesState';
import { COOKIE_CONSENT } from './i18n';

interface CookieItem {
  displayNameI18n: string | I18nShape;
  key: Cookies;
  useCookieState: F.Function<[], { value: boolean; setValue: F.Function }>;
  description: string | I18nShape;
  optional: boolean;
}

const useStyle = makeStyle(
  () => css`
    padding: 10p 20px;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  `,
);

interface ExpansibleContentProps {
  changeable?: boolean;
  itemI18n: string | I18nShape;
  value: boolean;
  onChange: F.Function;
  disableSwitch: boolean;
  onExpandItem: F.Function;
}

const CookieExpansibleContent: React.FC<ExpansibleContentProps> = ({
  changeable,
  itemI18n,
  value,
  onChange,
  disableSwitch,
  onExpandItem,
}: ExpansibleContentProps) => {
  if (changeable) {
    return (
      <UI.Container
        direction="row"
        w="100%"
        align="center"
        justify="space-between"
      >
        <UI.Container align="center" gap={2} onClick={onExpandItem}>
          <UI.Icon name="down" size={15} />
          <UI.Typography i18n={itemI18n} />
        </UI.Container>
        <UI.Form.Switch
          checked={value}
          // value={value ? 'true' : undefined}
          onChange={onChange}
          disabled={disableSwitch}
        />
      </UI.Container>
    );
  }

  return (
    <UI.Container
      direction="row"
      w="100%"
      align="center"
      justify="space-between"
    >
      <UI.Container align="center" gap={2} onClick={onExpandItem}>
        <UI.Icon name="down" size={15} />
        <UI.Typography i18n={itemI18n} />
      </UI.Container>
    </UI.Container>
  );
};

interface CookieItemComponentProps {
  item: CookieItem;
  style?: Style;
  value: boolean;
  onChange: F.Function<[Cookies, boolean]>;
  changeable?: boolean;
}

const CookieItemComponent: React.FC<CookieItemComponentProps> = ({
  item,
  style,
  value,
  onChange,
  changeable,
}: CookieItemComponentProps) => {
  const [itemExpanded, expandItem] = useState(false);
  const borderStyle = useStyle();

  return (
    <UI.Card style={style} square>
      <UI.Card.Content style={borderStyle}>
        <CookieExpansibleContent
          changeable={changeable}
          itemI18n={item.displayNameI18n}
          value={value}
          onChange={() => onChange(item.key, !value)}
          disableSwitch={!item.optional}
          onExpandItem={() => expandItem(prev => !prev)}
        />
      </UI.Card.Content>
      <UI.Card.CollapseArea in={itemExpanded}>
        <UI.Container p={4}>
          <UI.Caption i18n={item.description} />
        </UI.Container>
      </UI.Card.CollapseArea>
    </UI.Card>
  );
};

// const currentDate = new Date();
// expire date 1 year ahead
// const expireDate = new Date(
//   currentDate.setFullYear(currentDate.getFullYear() + 1),
// );

const CONTENT_STYLE = { maxHeight: '90vh' };
const CONTENT_STYLE_MOBILE = {
  maxHeight: '90vh',
  maxWidth: '100vw',
};
const MODAL_STYLE = {
  width: 600,
  margin: '0 auto',
  top: '10vh',
  overflow: 'auto',
};
const MODAL_STYLE_MOBILE = {
  width: '100%',
  margin: '0 auto',
  overflow: 'auto',
};
const DEFAULT_COOKIES_OPTIONS = {
  path: contextualConfig.webApplication?.contextPath || '/',
};
interface CookieConsentProps {
  onAccept?: F.Function;
  onDecline: F.Function;
  moreInformationUrl?: string;
  cookiesList: string[];
  customizableCookies?: boolean;
}

export const CookieConsent: React.FC<CookieConsentProps> = ({
  onAccept,
  onDecline,
  moreInformationUrl,
  cookiesList,
  customizableCookies,
}: CookieConsentProps) => {
  const { value: isVisible, toggle, show } = useUseCookiesModal();
  const isMobile = useIsMediaQueryUpToSM();
  const width = isMobile ? '100%' : 600;
  const [cookies, setCookie, removeCookie] = useCookies(cookiesList);
  const availableCookiesState = useAvailableCookiesState(cookiesList);
  const onDeclineAll = useOnDeclineAll(cookiesList);
  const onChangeCookieValue = useSetCookieStateAction();
  const getCookieStateValue = useCookieStateByKey();

  const cookiesItems = useMemo(
    () =>
      compact(
        map(ALL_COOKIES_CONFIGS, configItem => {
          if (includes(cookiesList, configItem.key)) {
            return configItem;
          }
          return null;
        }),
      ) as CookieItem[],
    [cookiesList],
  );

  const removeAllCookies = useCallback(() => {
    forEach(cookiesList, cookieKey => {
      removeCookie(cookieKey);
    });
    onDeclineAll();
  }, [cookiesList, onDeclineAll, removeCookie]);

  const handleDecline = useCallback(() => {
    if (onDecline) {
      onDecline();
    }
    setCookie(LOCAL_STORAGE_KEY, false, DEFAULT_COOKIES_OPTIONS);
    removeAllCookies();
    toggle();
  }, [onDecline, removeAllCookies, setCookie, toggle]);

  const handleConfirmation = useCallback(() => {
    const expires = moment().add(1, 'year').toDate();
    forIn(availableCookiesState, (item, key) => {
      setCookie(key, item, { ...DEFAULT_COOKIES_OPTIONS, expires });
    });
    setCookie(LOCAL_STORAGE_KEY, true, {
      ...DEFAULT_COOKIES_OPTIONS,
      expires,
    });
    if (onAccept) {
      onAccept();
    }
    toggle();
  }, [availableCookiesState, onAccept, setCookie, toggle]);

  useDeepCompareEffect(() => {
    if (cookies[LOCAL_STORAGE_KEY] === 'false' || !cookies[LOCAL_STORAGE_KEY]) {
      show();
    }
  }, [cookies]);

  useInitializeCookiesState(cookiesList);

  return (
    <UI.Modal
      visible={isVisible}
      style={isMobile ? MODAL_STYLE_MOBILE : MODAL_STYLE}
      hideCloseButton
    >
      <UI.Container
        direction="column"
        style={isMobile ? CONTENT_STYLE_MOBILE : CONTENT_STYLE}
        align="center"
        justify="center"
        w="100%"
        bg="white"
      >
        <PerfectScroll>
          <UI.Container direction="column" gap={2} bg="white" w={width} p={4}>
            <UI.Container>
              <UI.Typography
                i18n={COOKIE_CONSENT.CONFIG.TITLE}
                modifiers="bold"
              />
            </UI.Container>
            <UI.Container direction="column" gap={1}>
              <UI.Typography
                i18n={COOKIE_CONSENT.CONFIG.TEXT}
                variant="caption"
              />
              {moreInformationUrl ? (
                <UI.Link
                  href={moreInformationUrl}
                  i18n={MORE_INFORMATION}
                  modifiers="underline"
                />
              ) : null}
            </UI.Container>
            <UI.Container direction="column" gap={2}>
              <UI.Container m="1, 0">
                <UI.Typography
                  i18n={COOKIE_CONSENT.CONFIG.CONTENT_TITLE}
                  modifiers="bold"
                />
              </UI.Container>
              <UI.Container direction="column" f={1} gap={1}>
                {map(cookiesItems, item => {
                  const itemValue = getCookieStateValue(item.key);
                  return (
                    <CookieItemComponent
                      key={item.key}
                      item={item}
                      value={itemValue}
                      onChange={onChangeCookieValue}
                      changeable={customizableCookies}
                    />
                  );
                })}
              </UI.Container>
            </UI.Container>
          </UI.Container>
        </PerfectScroll>
        <UI.Container justify="space-between" bg="white" w={width} p={4}>
          <UI.Button
            onClick={handleDecline}
            i18n={DECLINE}
            modifiers="secondary"
          />
          <UI.Button onClick={handleConfirmation} i18n={CONFIRM} />
        </UI.Container>
      </UI.Container>
    </UI.Modal>
  );
};
