import { I18nShape } from '@mmw/constants-i18n';
import {
  useFastFormField,
  useInputFieldConfigProps,
} from '@mmw/redux-store-fast-form/hooks/fields';
import { deepmerge } from '@shared-utils/object';
import { SelectProps } from '@ui-system/interfaces-form';
import UI from '@ui-system/ui';
import head from 'lodash/head';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useMemo } from 'react';

export interface FastFormSelectProps extends Partial<SelectProps> {
  fieldPath: string;
  autoSelectHead?: boolean;
  [props: string]: any;
}
const FastFormSelect: React.FC<FastFormSelectProps> = ({
  fieldPath,
  placeholder,
  placeholderI18n,
  options,
  optionsName,
  optionsValue,
  autoSelectHead,
  ...rest
}: FastFormSelectProps) => {
  const { value, setValue, errors, canShowError } =
    useFastFormField<SelectProps['value']>(fieldPath);

  const caption: I18nShape | null = useMemo(() => {
    if (!isEmpty(errors?.message) && errors) {
      return {
        message: errors.message,
        key: errors.message,
        values: { value },
      };
    }
    return null;
  }, [errors, value]);

  useEffect(() => {
    const headValue = head(options)?.value;
    if (autoSelectHead) {
      setValue(headValue);
    }
  }, [autoSelectHead, options, setValue]);

  const inputFieldConfigProps = useInputFieldConfigProps(fieldPath);
  const otherProps = deepmerge(inputFieldConfigProps, rest);
  if (!options) return null;

  return (
    <UI.Form.Select
      options={options}
      optionsName={optionsName}
      optionsValue={optionsValue}
      data-test-id={fieldPath}
      value={value}
      onChange={setValue}
      placeholder={placeholder}
      placeholderI18n={placeholderI18n}
      caption={caption}
      error={canShowError && !isEmpty(errors)}
      {...otherProps}
    />
  );
};

export default FastFormSelect;
