import ADDITIONAL_INFO from './additionalInfo';
import BANK_ACCOUNT from './bankAccount';
import CAMPAIGN from './campaign';
import CONSUMER from './consumer';
import COUNTRIES from './countrySelect';
import INSTALLER from './installer';
import PERSON from './person';
import PRODUCT from './product';
import REGISTRATION from './registration';
import STORE from './store';

export { default as ADDITIONAL_INFO } from './additionalInfo';
export { default as BANK_ACCOUNT } from './bankAccount';
export { default as CAMPAIGN } from './campaign';
export { default as CONSUMER } from './consumer';
export { default as COUNTRIES } from './countrySelect';
export { default as INSTALLER } from './installer';
export { default as PERSON } from './person';
export { default as PRODUCT } from './product';
export { default as REGISTRATION } from './registration';
export { default as STORE } from './store';

const I18N = {
  BANK_ACCOUNT,
  CAMPAIGN,
  CONSUMER,
  PERSON,
  PRODUCT,
  REGISTRATION,
  COUNTRIES,
  INSTALLER,
  STORE,
  ADDITIONAL_INFO,
};

export default I18N;
