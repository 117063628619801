import { Style } from '@ui-system/interfaces/types';
import { ResponsiveProps } from '@ui-system/media-query';
import { ImageContentFit, ImageProps as ExpoImageProps } from 'expo-image';
import { nanoid } from 'nanoid';
import React from 'react';
import { F, U } from 'ts-toolbelt';

export type ResizeMode = 'contain' | 'cover' | 'stretch' | 'center' | 'fill';

export type ImageResponsiveProps = ResponsiveProps<
  Pick<ImageProps, 'src' | 'height' | 'width' | 'resizeMode'>
>;
export interface ImageProps extends ImageResponsiveProps {
  src: U.Nullable<string>;
  source?: U.Nullable<string>;
  preview?: string;
  style?: Style;
  height?: number | string;
  width?: number | string;
  resizeMode?: ResizeMode;
  ignorePxPerfect?: boolean;
  noCache?: boolean;
  fallbackSrc?: string;
  getImageSize?: (w: number | undefined, h: number | undefined) => void;
  onClick?: U.Nullable<F.Function>;
  onLoad?: U.Nullable<F.Function>;
}

export type NativeImageProps = {
  // placeholderStyle?: Record<string, any>;
  // PlaceholderContent?: React.ReactElement;
  // children?: React.ReactNode;
  containerStyle?: Record<string, any>;
  transition?: number;
  onLoad?: (e: any) => void;
  height: number | string;
  width: number | string;
  resizeMode?: ImageContentFit;
  fallbackSrc?: string;
} & Partial<Omit<ImageProps, 'resizeMode' | 'source'>> &
  Partial<ExpoImageProps>;

export type ImageType = React.FC<ImageProps>;

export const IMAGE_DEFAULT_PROPS: Partial<Omit<ImageProps, 'resizeMode'>> = {
  height: 100,
  width: 100,
  ignorePxPerfect: false,
  noCache: false,
};

export const IMAGE_UUID = nanoid();
