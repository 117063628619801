import { isReactNative } from 'is-react-native';
import { useMemo } from 'react';
import { MediaQueryAllQueryable, useMediaQuery } from 'react-responsive';

import { mediaQueryParser, mediaQueryParserNoop } from './parser';
import { MediaQueries } from './types';

export type QueryableMedia = Partial<MediaQueryAllQueryable>;

export const XS: QueryableMedia = {
  minWidth: 320,
  maxWidth: 575,
};
export const SM: QueryableMedia = {
  minWidth: 576,
  maxWidth: 767,
};
export const MD: QueryableMedia = {
  minWidth: 768,
  maxWidth: 991,
};
export const LG: QueryableMedia = {
  minWidth: 992,
  maxWidth: 1199,
};
export const XL: QueryableMedia = { minWidth: 1200, maxWidth: 1599 };
export const XXL: QueryableMedia = { minWidth: 1600, maxWidth: 2660 };

export const UP_TO_XS: QueryableMedia = {
  maxWidth: XS.maxWidth,
};

export const UP_TO_SM: QueryableMedia = {
  maxWidth: SM.maxWidth,
};
export const UP_TO_MD: QueryableMedia = {
  maxWidth: MD.maxWidth,
};
export const UP_TO_LG: QueryableMedia = {
  maxWidth: LG.maxWidth,
};
export const UP_TO_XL: QueryableMedia = { maxWidth: XL.maxWidth };

export const UP_TO_XXL: QueryableMedia = { maxWidth: XXL.maxWidth };

export const RETINA: QueryableMedia = { minResolution: '2dppx' };
export const PORTRAIT: QueryableMedia = {
  orientation: 'portrait',
};
export const LANDSCAPE: QueryableMedia = {
  orientation: 'landscape',
};
type MediaQueryRange =
  | 'xsToSm'
  | 'xsToMd'
  | 'xsToLg'
  | 'xsToXl'
  | 'xsToXxl'
  | 'smToMd'
  | 'smToLg'
  | 'smToXl'
  | 'smToXxl'
  | 'mdToLg'
  | 'mdToXl'
  | 'mdToXxl'
  | 'lgToXl'
  | 'lgToXxl'
  | 'xlToXxl';

const RANGE_MAPPING: Record<MediaQueryRange, QueryableMedia> = {
  xsToSm: { minWidth: XS.minWidth, maxWidth: SM.maxWidth },
  xsToMd: { minWidth: XS.minWidth, maxWidth: MD.maxWidth },
  xsToLg: { minWidth: XS.minWidth, maxWidth: LG.maxWidth },
  xsToXl: { minWidth: XS.minWidth, maxWidth: XL.maxWidth },
  xsToXxl: { minWidth: XS.minWidth, maxWidth: XXL.maxWidth },
  smToMd: { minWidth: SM.minWidth, maxWidth: MD.maxWidth },
  smToLg: { minWidth: SM.minWidth, maxWidth: LG.maxWidth },
  smToXl: { minWidth: SM.minWidth, maxWidth: XL.maxWidth },
  smToXxl: { minWidth: SM.minWidth, maxWidth: XXL.maxWidth },
  mdToLg: { minWidth: MD.minWidth, maxWidth: LG.maxWidth },
  mdToXl: { minWidth: MD.minWidth, maxWidth: XL.maxWidth },
  mdToXxl: { minWidth: MD.minWidth, maxWidth: XXL.maxWidth },
  lgToXl: { minWidth: LG.minWidth, maxWidth: XL.maxWidth },
  lgToXxl: { minWidth: LG.minWidth, maxWidth: XXL.maxWidth },
  xlToXxl: { minWidth: XL.minWidth, maxWidth: XXL.maxWidth },
};

export function useMediaQueryRange(range: MediaQueryRange): boolean {
  return useMediaQuery(RANGE_MAPPING[range]);
}

export function useIsMediaQuery(query: QueryableMedia): boolean {
  return useMediaQuery(query);
}

export function useMediaQueries(): MediaQueries {
  const xs = useMediaQuery(XS);
  const sm = useMediaQuery(SM);
  const md = useMediaQuery(MD);
  const lg = useMediaQuery(LG);
  const xl = useMediaQuery(XL);
  const xxl = useMediaQuery(XXL);

  const upToXs = useMediaQuery(UP_TO_XS);
  const upToSm = useMediaQuery(UP_TO_SM);
  const upToMd = useMediaQuery(UP_TO_MD);
  const upToLg = useMediaQuery(UP_TO_LG);
  const upToXl = useMediaQuery(UP_TO_XL);
  const upToXxl = useMediaQuery(UP_TO_XXL);

  const xsToSm = useMediaQueryRange('xsToSm');
  const xsToMd = useMediaQueryRange('xsToMd');
  const xsToLg = useMediaQueryRange('xsToLg');
  const xsToXl = useMediaQueryRange('xsToXl');
  const xsToXxl = useMediaQueryRange('xsToXxl');
  const smToMd = useMediaQueryRange('smToMd');
  const smToLg = useMediaQueryRange('smToLg');
  const smToXl = useMediaQueryRange('smToXl');
  const smToXxl = useMediaQueryRange('smToXxl');
  const mdToLg = useMediaQueryRange('mdToLg');
  const mdToXl = useMediaQueryRange('mdToXl');
  const mdToXxl = useMediaQueryRange('mdToXxl');
  const lgToXl = useMediaQueryRange('lgToXl');
  const lgToXxl = useMediaQueryRange('lgToXxl');
  const xlToXxl = useMediaQueryRange('xlToXxl');

  const isPortrait = useMediaQuery(PORTRAIT);
  const isLandscape = useMediaQuery(LANDSCAPE);
  const isRetina = useMediaQuery(RETINA);
  const reactNative = isReactNative();

  return useMemo(
    () => ({
      reactNative: reactNative ? mediaQueryParser : mediaQueryParserNoop,
      xs: xs ? mediaQueryParser : mediaQueryParserNoop,
      sm: sm ? mediaQueryParser : mediaQueryParserNoop,
      md: md ? mediaQueryParser : mediaQueryParserNoop,
      lg: lg ? mediaQueryParser : mediaQueryParserNoop,
      xl: xl ? mediaQueryParser : mediaQueryParserNoop,
      xxl: xxl ? mediaQueryParser : mediaQueryParserNoop,
      portrait: isPortrait ? mediaQueryParser : mediaQueryParserNoop,
      landscape: isLandscape ? mediaQueryParser : mediaQueryParserNoop,
      retina: isRetina ? mediaQueryParser : mediaQueryParserNoop,
      upToXs: upToXs ? mediaQueryParser : mediaQueryParserNoop,
      upToSm: upToSm ? mediaQueryParser : mediaQueryParserNoop,
      upToMd: upToMd ? mediaQueryParser : mediaQueryParserNoop,
      upToLg: upToLg ? mediaQueryParser : mediaQueryParserNoop,
      upToXl: upToXl ? mediaQueryParser : mediaQueryParserNoop,
      upToXxl: upToXxl ? mediaQueryParser : mediaQueryParserNoop,
      xsToSm: xsToSm ? mediaQueryParser : mediaQueryParserNoop,
      xsToMd: xsToMd ? mediaQueryParser : mediaQueryParserNoop,
      xsToLg: xsToLg ? mediaQueryParser : mediaQueryParserNoop,
      xsToXl: xsToXl ? mediaQueryParser : mediaQueryParserNoop,
      xsToXxl: xsToXxl ? mediaQueryParser : mediaQueryParserNoop,
      smToMd: smToMd ? mediaQueryParser : mediaQueryParserNoop,
      smToLg: smToLg ? mediaQueryParser : mediaQueryParserNoop,
      smToXl: smToXl ? mediaQueryParser : mediaQueryParserNoop,
      smToXxl: smToXxl ? mediaQueryParser : mediaQueryParserNoop,
      mdToLg: mdToLg ? mediaQueryParser : mediaQueryParserNoop,
      mdToXl: mdToXl ? mediaQueryParser : mediaQueryParserNoop,
      mdToXxl: mdToXxl ? mediaQueryParser : mediaQueryParserNoop,
      lgToXl: lgToXl ? mediaQueryParser : mediaQueryParserNoop,
      lgToXxl: lgToXxl ? mediaQueryParser : mediaQueryParserNoop,
      xlToXxl: xlToXxl ? mediaQueryParser : mediaQueryParserNoop,
      current: {
        reactNative,
        xs,
        sm,
        md,
        lg,
        xl,
        xxl,
        upToXs,
        upToSm,
        upToMd,
        upToLg,
        upToXl,
        upToXxl,
        xsToSm,
        xsToMd,
        xsToLg,
        xsToXl,
        xsToXxl,
        smToMd,
        smToLg,
        smToXl,
        smToXxl,
        mdToLg,
        mdToXl,
        mdToXxl,
        lgToXl,
        lgToXxl,
        xlToXxl,
        portrait: isPortrait,
        landscape: isLandscape,
        retina: isRetina,
      },
    }),
    [
      isLandscape,
      isPortrait,
      isRetina,
      lg,
      lgToXl,
      lgToXxl,
      md,
      mdToLg,
      mdToXl,
      mdToXxl,
      reactNative,
      sm,
      smToLg,
      smToMd,
      smToXl,
      smToXxl,
      upToLg,
      upToMd,
      upToSm,
      upToXl,
      upToXs,
      upToXxl,
      xl,
      xlToXxl,
      xs,
      xsToLg,
      xsToMd,
      xsToSm,
      xsToXl,
      xsToXxl,
      xxl,
    ],
  );
}

export function useIsMediaQueryCurrent(query: QueryableMedia): boolean {
  const mediaQueries = useMediaQueries();
  return mediaQueries.current[query.orientation || 'xs'];
}

export function useIsMediaQueryUpToSM(): boolean {
  const mediaQueries = useMediaQueries();
  return mediaQueries.current.upToSm;
}

export function useIsMediaQueryUpToMD(): boolean {
  const mediaQueries = useMediaQueries();
  return mediaQueries.current.upToMd;
}

export function useIsMediaQueryUpToLG(): boolean {
  const mediaQueries = useMediaQueries();
  return mediaQueries.current.upToLg;
}
export function useIsMediaQueryUpToXL(): boolean {
  const mediaQueries = useMediaQueries();
  return mediaQueries.current.upToXl;
}

export function useIsTabletMediaQuery(): boolean {
  const mediaQueries = useMediaQueries();
  return mediaQueries.current.smToMd;
}
export function useIsPortraitMediaQuery(): boolean {
  const mediaQueries = useMediaQueries();
  return mediaQueries.current.portrait;
}
