import '@brainhubeu/react-carousel/lib/style.css';

import Carousel, {
  arrowsPlugin,
  slidesToShowPlugin,
} from '@brainhubeu/react-carousel';
import Container, { Column, Row } from '@mmw/hybrid-ui-structure-containers';
import { use } from '@mmw/ui-theme/utils';
import isEmpty from 'lodash/isEmpty';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { CarouselProps } from './types';

export { slidesToShowPlugin } from '@brainhubeu/react-carousel';

const StyledCarousel = styled(Carousel)(props => use(props, 'itemsCarousel'));

const LeftArrow = styled.button.attrs(props => ({
  type: props.type || 'button',
}))(props => use(props, 'itemsCarousel.leftArrow'));

const RightArrow = styled.button.attrs(props => ({
  type: props.type || 'button',
}))(props => use(props, 'itemsCarousel.rightArrow'));

const StyledDot = styled(Container)(
  ({ theme, active }) => `
  width: 8px;
  height: 8px;
  border-radius: 10px;
  background-color: ${
    active ? theme.colors.pallet.primary : theme.colors.pallet.basic
  };
  margin-left: 10px;
  cursor: pointer;
`,
);

const ItemsCarousel: React.FC<CarouselProps> = ({
  children,
  itemsPerPage,
  infinite,
  draggable,
  showDots,
  customPlugins,
  ...otherProps
}: CarouselProps) => {
  const [currentPosition, setPosition] = useState(0);
  const itemsList = useMemo(() => React.Children.toArray(children), [children]);
  const ITEMS_LENGTH = itemsList.length;
  const onChange = useCallback(value => {
    setPosition(value);
  }, []);

  useEffect(() => {
    setPosition(0);
  }, [ITEMS_LENGTH]);

  const pluginsConfig = useMemo(() => {
    const disableLeftArrow = (): boolean => !infinite && currentPosition === 0;
    const disableRightArrow = (): boolean =>
      !infinite &&
      !!itemsPerPage &&
      currentPosition === ITEMS_LENGTH - itemsPerPage;
    let plugins =
      itemsPerPage && ITEMS_LENGTH > itemsPerPage
        ? [
            {
              resolve: slidesToShowPlugin,
              options: {
                numberOfSlides: itemsPerPage,
              },
            },
            {
              resolve: arrowsPlugin,
              options: {
                arrowLeft: <LeftArrow disabled={disableLeftArrow()} />,
                arrowRight: <RightArrow disabled={disableRightArrow()} />,
                addArrowClickHandler: true,
              },
            },
          ]
        : [
            {
              resolve: slidesToShowPlugin,
              options: {
                numberOfSlides: itemsPerPage,
              },
            },
          ];

    if (!isEmpty(customPlugins)) {
      plugins = customPlugins;
    }

    if (infinite) {
      plugins = [...plugins, 'infinite'];
    }

    return plugins;
  }, [itemsPerPage, ITEMS_LENGTH, customPlugins, infinite, currentPosition]);

  const customProps = useMemo(
    () =>
      !infinite
        ? {
            value: currentPosition,
            onChange,
          }
        : {},
    [currentPosition, infinite, onChange],
  );

  const onClickDots = useCallback(
    (index: number) => {
      if (currentPosition === index) return null;
      return setPosition(index);
    },
    [currentPosition],
  );

  if (ITEMS_LENGTH === 1) {
    return <Row autoWidth>{children}</Row>;
  }

  return (
    <Column>
      <StyledCarousel
        draggable={draggable}
        plugins={pluginsConfig}
        {...customProps}
        {...otherProps}
      >
        {children}
      </StyledCarousel>

      {showDots && (
        <Row align="center" justify="center" spacing="2">
          {React.Children.map(itemsList, (n, idx) => (
            <StyledDot
              onClick={() => onClickDots(idx)}
              active={idx === currentPosition}
            />
          ))}
        </Row>
      )}
    </Column>
  );
};

ItemsCarousel.defaultProps = {
  itemsPerPage: 1,
  infinite: false,
  draggable: false,
};

export default ItemsCarousel;
