import { isNumber, split } from 'lodash';
import { useMemo } from 'react';
import { SchemaObjectDescription } from 'yup';

import logger from '../../log';
import { FieldPath } from '../../types';
import useFormValidationDescription from '../useFormValidationDescription';

const isFieldPartNumber = (fieldPart: string) => {
  const parsed = parseInt(fieldPart, 10);
  return isNumber(parsed) && parsed > -1;
};

const calculateConfig = (
  fieldPath: FieldPath,
  validationDescription: SchemaObjectDescription,
) => {
  const fieldParts = split(fieldPath, '.');
  let index = 0;
  let currentConfig = validationDescription;

  while (index < fieldParts.length) {
    const currentFieldPart = fieldParts[index];
    if (isFieldPartNumber(currentFieldPart)) {
      index += 1;
    } else {
      if (!currentConfig) {
        return null;
      }
      switch (currentConfig.type) {
        case 'object':
          // @ts-ignore
          currentConfig = currentConfig.fields[currentFieldPart];
          index += 1;
          if (!currentConfig) {
            logger.warn(
              'Couldnt find validation description for',
              currentFieldPart,
              currentConfig,
              fieldPath,
            );
            return null;
          }
          break;
        case 'array':
          // @ts-ignore
          currentConfig = currentConfig.innerType;
          break;
        default: {
          index += 1; // XXX: possibly got to field, to break the loop
          break;
        }
      }
    }
  }

  return currentConfig;
};

const useFieldCurrentConfig = (
  fieldPath: FieldPath,
): SchemaObjectDescription | null => {
  const validationDescription = useFormValidationDescription();

  return useMemo(
    () => calculateConfig(fieldPath, validationDescription),
    [fieldPath, validationDescription],
  );
};

export default useFieldCurrentConfig;
