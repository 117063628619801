import React, { createContext, useContext } from 'react';
import { U } from 'ts-toolbelt';

import { ProductsSelectionByCogsConfigs } from './types';

export * from './types';

const INITIAL_VALUES = {
  enabled: false,
  classOfGoodSelectionConfigs: null,
};

export const ProductSelectionByCogsContext =
  createContext<ProductsSelectionByCogsConfigs>(INITIAL_VALUES);

export const useProductSelectionByCogsConfigs =
  (): ProductsSelectionByCogsConfigs =>
    useContext(ProductSelectionByCogsContext);

interface Props {
  configs?: U.Nullable<ProductsSelectionByCogsConfigs>;
}

const ProductsSelectionByCogsProvider: React.FC<
  React.PropsWithChildren<Props>
> = ({ configs, children }: React.PropsWithChildren<Props>) => (
  <ProductSelectionByCogsContext.Provider value={configs || INITIAL_VALUES}>
    {children}
  </ProductSelectionByCogsContext.Provider>
);

export default ProductsSelectionByCogsProvider;
