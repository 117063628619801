import contextualConfig from '@mmw/contextual-config';
import get from 'lodash/get';
import { useCallback, useEffect } from 'react';
import { F } from 'ts-toolbelt';

import listenForHistory from './history';
import initialize from './initialize';
import sendEvent from './sendEvent';
import { GaEventOptions } from './types';

export { default as listenForHistory } from './history';
export { default as initialize } from './initialize';
export { default as sendEvent } from './sendEvent';

const { logger, applicationId } = contextualConfig.application;

export function useHandler(handler: F.Function, event: GaEventOptions) {
  return useCallback(
    (...args) => {
      if (handler) handler.apply(this, args);
      if (event) sendEvent(event);
    },
    [handler, event],
  );
}

export function useOnMount(event: GaEventOptions) {
  useEffect(() => {
    if (event) sendEvent(event);
  }, [event]);
}

export function initializeGoogleAnalytics(
  history: Record<string, any>,
  localStorageKey?: string,
) {
  logger.info('Will check if user accepted analytics cookies');
  const localStorageCookies = JSON.parse(
    localStorage.getItem(
      localStorageKey || `${applicationId}-cookie-consent`,
    ) as string,
  );
  const userAcceptedAnalyticsCookies = get(
    localStorageCookies,
    'state.cookies.analytics',
  );
  logger.info(
    'Got user analytics acceptance status as',
    userAcceptedAnalyticsCookies,
  );
  if (userAcceptedAnalyticsCookies) {
    logger.info('Will initialize analytics data collector');
    initialize();
    listenForHistory(history);
  } else {
    logger.info(
      'User denies the google analytics cookies, will ignore initializer',
    );
  }
}
