import { SalesOrgBrand } from '@mmw/constants-salesorgbrand-ids';
import { useLanguage } from '@mmw/redux-store-i18n/hooks';
import {
  ConsumerProductClassOfGoodsJSON,
  ConsumerProductJSON,
  ConsumerProductSearchRequestParams,
} from '@mmw/services-core-consumer-product/types';
import isEmpty from 'lodash/isEmpty';
import { useCallback, useEffect } from 'react';
import { U } from 'ts-toolbelt';

import useProductSelectionByCogsStore from './store';

function useGetAvailableClassOfGoodsAction() {
  return useProductSelectionByCogsStore(
    state => state.getAvailableClassOfGoods,
  );
}

function useSearchProductsByCogAction() {
  return useProductSelectionByCogsStore(state => state.searchProducts);
}

export function useSetSelectAllProductsAction() {
  const selectAllProductsFromCogAction = useProductSelectionByCogsStore(
    state => state.setSelectAllProducts,
  );
  return useCallback(
    () => selectAllProductsFromCogAction(),
    [selectAllProductsFromCogAction],
  );
}

export function useAvailableClassOfGoodsList() {
  return useProductSelectionByCogsStore(state => state.classOfGoodsList);
}

export function useProductsList() {
  return useProductSelectionByCogsStore(state => state.products);
}

export function useSelectAllProductsStatus() {
  return useProductSelectionByCogsStore(state => state.selectAllProducts);
}

export function useProductsListTotal() {
  return useProductSelectionByCogsStore(state => state.products.total);
}

export function useProductSearchLastRequest() {
  return useProductSelectionByCogsStore(state => state.productSearchRequest);
}

export function useSelectedClassOfGoods() {
  return useProductSelectionByCogsStore(state => state.selectedCogs);
}

export function useSelectedProductOrClassOfGood() {
  return useProductSelectionByCogsStore(state => state.productOrClassOfGood);
}

export function useIsLoadingProductsOrCogs() {
  return useProductSelectionByCogsStore(state => state.loading);
}

export function useProductSelectionError() {
  return useProductSelectionByCogsStore(state => state.error);
}

export function useResetClassOfGoodsSelectionAction() {
  return useProductSelectionByCogsStore(state => state.resetClassOfGoods);
}

export function useAddClassOfGoodToSelectionAction() {
  const selectCogAction = useProductSelectionByCogsStore(
    state => state.selectClassOfGood,
  );
  return useCallback(
    (
      classOfGood: U.Nullable<ConsumerProductClassOfGoodsJSON>,
      selectionIndex: number,
    ) => {
      selectCogAction(classOfGood, selectionIndex);
    },
    [selectCogAction],
  );
}

export function useSelectProductOrClassOfGood() {
  const selectProductOrGroupAction = useProductSelectionByCogsStore(
    state => state.selectProductOrClassOfGood,
  );
  return useCallback(
    (
      productOrProductGroup:
        | ConsumerProductJSON
        | ConsumerProductClassOfGoodsJSON,
    ) => {
      selectProductOrGroupAction(productOrProductGroup);
    },
    [selectProductOrGroupAction],
  );
}

export function useGetAvailableClassOfGoodsOnMount(
  salesOrgBrandID?: SalesOrgBrand,
) {
  const getAvailableClassOfGoodsAction = useGetAvailableClassOfGoodsAction();
  const language = useLanguage();
  const cogsList = useAvailableClassOfGoodsList();

  useEffect(() => {
    if (isEmpty(cogsList)) {
      getAvailableClassOfGoodsAction(
        {
          salesOrgBrandID,
        },
        language,
      );
    }
  }, [salesOrgBrandID]);
}

export function useGetClassOfGoodsByParent(salesOrgBrandID?: SalesOrgBrand) {
  const language = useLanguage();
  const getAvailableClassOfGoodsAction = useGetAvailableClassOfGoodsAction();

  return useCallback(
    (cogParentId: number) => {
      getAvailableClassOfGoodsAction(
        {
          salesOrgBrandID,
          classOfGood: cogParentId,
        },
        language,
      );
    },
    [getAvailableClassOfGoodsAction, language, salesOrgBrandID],
  );
}

export function useSearchProductsByCog() {
  const language = useLanguage();
  const searchProductsAction = useSearchProductsByCogAction();

  return useCallback(
    (searchParams: ConsumerProductSearchRequestParams) => {
      searchProductsAction(searchParams, language);
    },
    [language, searchProductsAction],
  );
}

export function usePaginateProductSearch() {
  const language = useLanguage();
  const searchProductsAction = useSearchProductsByCogAction();
  const lastProductSearchRequest = useProductSearchLastRequest();
  return useCallback(
    (searchParams: ConsumerProductSearchRequestParams) => {
      searchProductsAction(
        {
          ...lastProductSearchRequest,
          ...searchParams,
        },
        language,
      );
    },
    [language, lastProductSearchRequest, searchProductsAction],
  );
}
