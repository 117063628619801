import contextualConfig from '@mmw/contextual-config';
import { isDevelopment } from '@mmw/environment';
import ReactGA from 'react-ga4';
import ReactGTM, { TagManagerArgs } from 'react-gtm-module';

function initialize() {
  const trackingID = contextualConfig?.googleAnalytics?.trackingID;
  if (!trackingID) {
    return;
  }

  ReactGA.initialize(trackingID, { testMode: isDevelopment() });
  ReactGTM.initialize({
    gtmId: contextualConfig.googleTagManager?.gtmId,
  } as TagManagerArgs);
}

export default initialize;
