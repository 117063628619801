import {
  useGetAvailableClassOfGoodsOnMount,
  useResetClassOfGoodsSelectionAction,
  useSelectedProductOrClassOfGood,
} from '@product-selection-cogs/store-creator';
import {
  ClassOfGoodsSelector,
  SelectedProductCard,
} from '@product-selection-cogs/view-selection';
import { useProductSelectionByCogsConfigs } from '@product-selection-cogs/view-selection/context';
import ProductSelector from '@product-selection-cogs/view-selection/ProductSelector';
import { css } from '@ui-system/css';
import { useIsMediaQueryUpToMD } from '@ui-system/media-query';
import UI from '@ui-system/ui';
import { useBoolean } from 'ahooks';
import isEmpty from 'lodash/isEmpty';
import React, { useCallback } from 'react';
import { F } from 'ts-toolbelt';

import AddProductToSearchAction from './AddProductToSearchAction';

interface ProductSelectionProps {
  onConfirmSelection?: F.Function;
}

const ProductSelectionContent: React.FC<ProductSelectionProps> = ({
  onConfirmSelection,
}: ProductSelectionProps) => {
  const isMobile = useIsMediaQueryUpToMD();
  useGetAvailableClassOfGoodsOnMount();
  return (
    <UI.Card
      style={css`
        padding-top: 40px;
        padding-bottom: 20px;
        ${isMobile
          ? `
          padding-top: 0;
          margin-top: 20px;
          height: 100%;
        `
          : ''};
        overflow-y: auto;
        width: auto;
      `}
      elevation={4}
    >
      <UI.Card.Content>
        <ClassOfGoodsSelector />
        <ProductSelector onConfirmSelection={onConfirmSelection} />
      </UI.Card.Content>
    </UI.Card>
  );
};

interface Props {
  hasResults?: boolean;
}

const SearchByProduct: React.FC<Props> = ({ hasResults }: Props) => {
  const configs = useProductSelectionByCogsConfigs();
  const [visible, { toggle }] = useBoolean();
  const selectedProductOrProductGroup = useSelectedProductOrClassOfGood();
  const resetCogsSelection = useResetClassOfGoodsSelectionAction();
  const isMobile = useIsMediaQueryUpToMD();
  const onClose = useCallback(() => {
    toggle();
    resetCogsSelection();
  }, [resetCogsSelection, toggle]);
  const hasProduct = !isEmpty(selectedProductOrProductGroup);

  if (!configs?.enabled || (configs?.enabled && hasResults && !hasProduct))
    return null;

  return (
    <>
      <AddProductToSearchAction
        selectedProduct={
          hasProduct ? (
            <SelectedProductCard
              productOrProductGroup={selectedProductOrProductGroup}
              onlyProductName={hasResults}
            />
          ) : null
        }
        foundResults={hasResults}
        onClick={toggle}
      />
      <UI.Modal
        style={css`
          max-width: 850px;
          max-height: ${isMobile ? 'auto' : '600px'};
          margin: 0 auto;
          ${isMobile
            ? `
            top: 60px;          
          `
            : 'top: 10%;'}
        `}
        visible={visible}
        toggle={onClose}
      >
        <ProductSelectionContent onConfirmSelection={toggle} />
      </UI.Modal>
    </>
  );
};

export default SearchByProduct;
