import {
  ClassOfGoodType,
  ConsumerProductClassOfGoodsJSON,
} from '@mmw/services-core-consumer-product/types';
import { TraderJSON } from '@mmw/services-core-store-locator/types';
import { useSelectedProductOrClassOfGood } from '@product-selection-cogs/store-creator';
import { EMPTY_OBJECT } from '@shared-utils/object';
import isEmpty from 'lodash/isEmpty';
import { useMemo } from 'react';
import { F, U } from 'ts-toolbelt';

import useStoreLocatorStore from './store';
import { Coordinates, SearchRequestObject } from './types';

export const useSearchStoresByLocation = (): F.Function<
  [Partial<SearchRequestObject>]
> => useStoreLocatorStore(state => state.searchStores);

export const useStoresByLocationSearchError = () =>
  useStoreLocatorStore(state => state.error);

export const useIsLoadingStoresByLocationSearch = () =>
  useStoreLocatorStore(state => state.loading);

export const useStoresByLocationIdsList = (): U.Nullable<number[]> =>
  useStoreLocatorStore(state => state.storeIdsList);

export const useResetStoresByLocationSearch = () =>
  useStoreLocatorStore(state => state.resetSearch);

export const useStoreItemById = (
  id: U.Nullable<number>,
): U.Nullable<TraderJSON> => {
  const items = useStoreLocatorStore(state => state.itemsById);
  if (!id) return null;
  return items[id];
};

export const useTotalStoresListByLocation = (): U.Nullable<number> =>
  useStoreLocatorStore(state => state.total);

export const useOriginCoordinates = (): U.Nullable<Coordinates> => {
  const coordinates = useStoreLocatorStore(state => state.lastCoordinates);
  if (coordinates?.lat === 0 || coordinates?.lng === 0) return null;
  return coordinates;
};

export const useSearchRequestRadius = (): U.Nullable<number> =>
  useStoreLocatorStore(state => state.lastRadius);

export function useSearchByProductParams() {
  const selectedProductOrClassOfGood = useSelectedProductOrClassOfGood();
  const productOrCogParams = useMemo(() => {
    if (isEmpty(selectedProductOrClassOfGood)) {
      return EMPTY_OBJECT;
    }
    if (
      (selectedProductOrClassOfGood as ConsumerProductClassOfGoodsJSON)
        ?.type === ClassOfGoodType.CLASSOFGOOD
    ) {
      return {
        classOfGoods: `${selectedProductOrClassOfGood.id}`,
      };
    }
    return {
      products: `${selectedProductOrClassOfGood.id}`,
    };
  }, [selectedProductOrClassOfGood]);
  return productOrCogParams;
}

export function useSelectedStore() {
  return useStoreLocatorStore(state => state.selectedStore);
}

export function useAlreadySearch() {
  return useStoreLocatorStore(state => state.alreadySearch);
}

export function useLastSearchByUserLocation() {
  return useStoreLocatorStore(state => state.lastSearchByUserLocation);
}
