import { singleton as tokenParser } from '@mmw/services-auth-api-token-parser';
import { TokenParseResult } from '@mmw/services-auth-api-token-parser/types';
import { useEffect, useState } from 'react';
import { U } from 'ts-toolbelt';

import useAccessToken from './useAccessToken';

function useUserParsedAccessToken() {
  const [parsedTokenInfo, setParsedTokenInfo] =
    useState<U.Nullable<TokenParseResult>>(null);
  const accessToken = useAccessToken();

  useEffect(() => {
    async function getTokenInfo() {
      const parsedToken = await tokenParser.parseToken(accessToken as string);

      setParsedTokenInfo(parsedToken);
    }
    if (accessToken) {
      getTokenInfo();
    }
  }, [accessToken]);

  return parsedTokenInfo;
}

export default useUserParsedAccessToken;
