import { createDI } from '@di/core';
import { FastFormInput, FastFormSelect } from '@fast-form/ui-system';
import { CountryCode } from '@mmw/constants-country-codes';
import { RADIUS } from '@mmw/constants-i18n-common';
import {
  PERSON as I18N,
  PERSON as PERSON_I18N,
} from '@mmw/constants-i18n-fields';
import { useAvailableCountries } from '@mmw/redux-store-common-available-countries/hooks';
import { useSetFieldValue } from '@mmw/redux-store-fast-form/hooks';
import { useValue } from '@mmw/redux-store-fast-form/hooks/fields';
import { css } from '@ui-system/css';
import { InputProps, SelectProps } from '@ui-system/interfaces-form';
import UI from '@ui-system/ui';
import { useMount } from 'ahooks';
import { isEmpty } from 'lodash';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import map from 'lodash/map';
import React, { useCallback, useEffect, useMemo } from 'react';

import {
  useCurrentMeasureUnits,
  useOnChangeSwitch,
  useRadiusOptions,
  useSetMeasureUnits,
  useToggleMeasureUnits,
} from '../../../context/measure-units';
import { STORE_LOCATOR_FIELDPATHS } from './configs';

export const [getCountriesWithStateEnabled, setCountriesWithStateEnabled] =
  createDI<string[]>('COUNTRIES_WITH_STATE_ENABLED', []);

export const CityFormField: React.FC<Partial<InputProps>> = props => (
  <FastFormInput
    fieldPath={STORE_LOCATOR_FIELDPATHS.city.$path}
    label={I18N.ADDRESS.CITY.LABEL}
    // @ts-ignore
    autoComplete="none"
    {...props}
  />
);

export const StateFormField: React.FC<Partial<InputProps>> = props => {
  const country = useValue<string>(STORE_LOCATOR_FIELDPATHS.country.$path);

  const visible = useMemo(
    () => includes(getCountriesWithStateEnabled(), country),
    [country],
  );

  if (!visible) return null;

  return (
    <FastFormInput
      fieldPath={STORE_LOCATOR_FIELDPATHS.state.$path}
      label={I18N.ADDRESS.STATE.LABEL}
      // @ts-ignore
      autoComplete="none"
      {...props}
    />
  );
};

export const ZipcodeFormField: React.FC<Partial<InputProps>> = (
  props: Partial<InputProps>,
) => (
  <FastFormInput
    fieldPath={STORE_LOCATOR_FIELDPATHS.zipcode.$path}
    label={I18N.ADDRESS.ZIPCODE.LABEL}
    // @ts-ignore
    autoComplete="none"
    {...props}
  />
);

export const InvisibleRadiusSelect: React.FC<Partial<SelectProps>> = () => {
  const setField = useSetFieldValue();

  useMount(() => {
    setField(STORE_LOCATOR_FIELDPATHS.radius.$path, 100);
  });

  return null;
};

export const RadiusSelectFormField: React.FC<Partial<SelectProps>> = (
  props: Partial<SelectProps>,
) => {
  const options = useRadiusOptions();
  return (
    <FastFormSelect
      fieldPath={STORE_LOCATOR_FIELDPATHS.radius.$path}
      label={RADIUS}
      labelModifiers="grayA400, uppercase"
      options={options}
      {...props}
    />
  );
};

export const [getRadiusSelect, setRadiusSelect] = createDI<
  React.FC<Partial<SelectProps>>
>('RADIUS_SELECT', RadiusSelectFormField);

interface CountrySelectProps extends Partial<SelectProps> {
  supportedCountries: CountryCode[];
}

export const CountrySelectFormField: React.FC<CountrySelectProps> = ({
  supportedCountries,
  ...props
}: CountrySelectProps) => {
  const currentCountry = useValue<string>(
    STORE_LOCATOR_FIELDPATHS.country.$path,
  );
  const setMeasureUnits = useSetMeasureUnits();
  const countries = useAvailableCountries();
  const supportedCountriesOptions = useMemo(() => {
    const filteredCountries = filter(countries, country =>
      includes(supportedCountries, country.id),
    );
    return map(filteredCountries, country => ({
      label: country.name,
      value: country.id,
    }));
  }, [countries, supportedCountries]);

  useEffect(() => setMeasureUnits(currentCountry), [currentCountry]);

  return (
    <FastFormSelect
      fieldPath={STORE_LOCATOR_FIELDPATHS.country.$path}
      label={PERSON_I18N.ADDRESS.COUNTRY.LABEL}
      labelModifiers="grayA400"
      options={supportedCountriesOptions}
      disabled={isEmpty(supportedCountriesOptions)}
      placeholderI18n={PERSON_I18N.ADDRESS.COUNTRY.PLACEHOLDER}
      {...props}
    />
  );
};

export const MeasureUnitFormField: React.FC = () => {
  const currentMeasureUnit = useCurrentMeasureUnits();
  const toggleMeasureUnits = useToggleMeasureUnits();
  const onChangeSwitch = useOnChangeSwitch();
  const onChange = useCallback(
    () => toggleMeasureUnits(onChangeSwitch),
    [onChangeSwitch, toggleMeasureUnits],
  );

  return (
    <UI.Container align="center">
      <UI.Typography variant="body1">Km</UI.Typography>
      <UI.Form.Switch
        checked={currentMeasureUnit === 'miles'}
        onChange={onChange}
        labelStyle={css`
          margin: 0;
        `}
        style={css`
          margin: 0;
        `}
        label={null}
      />
      <UI.Typography variant="body1">Miles</UI.Typography>
    </UI.Container>
  );
};

export const [getMeasureUnit, setMeasureUnit] = createDI<React.FC>(
  'MEASURE_UNIT_FORM_FIELD',
  MeasureUnitFormField,
);
