import { EMPTY_ARRAY, EMPTY_OBJECT } from '@mmw/constants-utils';
import contextualConfig from '@mmw/contextual-config';
import {
  ConsumerClassOfGoodsRequestParams,
  ConsumerProductClassOfGoodsJSON,
  ConsumerProductJSON,
  ConsumerProductSearchRequestParams,
} from '@mmw/services-core-consumer-product/types';
import { getConsumerProductService } from '@mmw/services-holder';
import { createStoreModule } from '@zustand-store/core';
import includes from 'lodash/includes';
import keys from 'lodash/keys';
import last from 'lodash/last';
import noop from 'lodash/noop';
import pickBy from 'lodash/pickBy';
import slice from 'lodash/slice';
import { U } from 'ts-toolbelt';

import { ProductSelectionCogsStoreValue } from './types';

const { logger, salesOrgBrandID, defaultCountry } =
  contextualConfig.application;

const consumerProductService = getConsumerProductService();

const EMPTY_PRODUCTS = {
  offset: 0,
  limit: 10,
  list: EMPTY_ARRAY,
  total: 0,
};

const INITIAL_STATE = {
  productOrClassOfGood: null,
  selectAllProducts: false,
  selectedCogs: EMPTY_OBJECT,
  classOfGoodsList: EMPTY_ARRAY,
  products: EMPTY_PRODUCTS,
  productSearchRequest: {
    offset: 0,
    // XXX Adjust product list pagination params as needed
    limit: 150,
  },
  loading: false,
  error: null,
  setSelectAllProducts: noop,
  getAvailableClassOfGoods: noop,
  selectClassOfGood: noop,
  selectProductOrClassOfGood: noop,
  searchProducts: noop,
  resetClassOfGoods: noop,
};

const useProductSelectionByCogsStore =
  createStoreModule<ProductSelectionCogsStoreValue>({
    name: 'product-selection-by-cogs',
    disablePersist: true,
    initialState: INITIAL_STATE,
    initializer: (set, getState) => ({
      ...INITIAL_STATE,
      getAvailableClassOfGoods: async (
        requestOptions: ConsumerClassOfGoodsRequestParams,
        language: string,
      ) => {
        try {
          const currentCogsList = getState().classOfGoodsList;
          logger.info('Will try to get available class of goods');
          set({
            loading: true,
          });
          const result = await consumerProductService.getProductsClassOfGoods(
            {
              country: defaultCountry,
              enabledPortals: 2, // consumer actor visibility
              ...requestOptions,
            },
            salesOrgBrandID,
            language,
          );
          set({
            classOfGoodsList: [...currentCogsList, result],
            loading: false,
            error: null,
          });
        } catch (error) {
          set({
            error,
          });
        }
      },
      selectClassOfGood: (
        classOfGood: U.Nullable<ConsumerProductClassOfGoodsJSON>,
        selectionLevel: number,
      ) => {
        const currentCogsList = getState().classOfGoodsList;
        const previousSelectedCogs = getState().selectedCogs;
        const selecedLevels = keys(currentCogsList);
        if (!classOfGood) {
          const newSelection = pickBy(
            previousSelectedCogs,
            (v, key) => Number(key) < selectionLevel,
          );
          set({
            selectedCogs: newSelection,
            classOfGoodsList: slice(currentCogsList, 0, selectionLevel),
            products: EMPTY_PRODUCTS,
          });
          return;
        }
        if (
          currentCogsList.length > 1 &&
          includes(selecedLevels, String(selectionLevel)) &&
          selectionLevel <= Number(last(selecedLevels))
        ) {
          const newSelection = pickBy(
            previousSelectedCogs,
            (v, key) => Number(key) <= selectionLevel,
          );
          set({
            selectedCogs: {
              ...newSelection,
              [selectionLevel]: classOfGood,
            },
            classOfGoodsList: slice(currentCogsList, 0, selectionLevel),
            products: EMPTY_PRODUCTS,
          });
          return;
        }
        set({
          selectedCogs: {
            ...previousSelectedCogs,
            [selectionLevel]: classOfGood,
          },
        });
      },
      selectProductOrClassOfGood: (
        productOrClassOfGood: U.Nullable<
          ConsumerProductJSON | ConsumerProductClassOfGoodsJSON
        >,
      ) => {
        set({
          productOrClassOfGood,
          selectAllProducts: false,
        });
      },
      setSelectAllProducts: () => {
        set({
          selectAllProducts: !getState().selectAllProducts,
        });
      },
      searchProducts: async (
        request: ConsumerProductSearchRequestParams,
        language: string,
      ) => {
        try {
          logger.info('Will try to get products by class of goods');
          set({
            loading: true,
          });
          const productSearchRequestState = getState().productSearchRequest;
          const result = await consumerProductService.getProducts(
            request,
            language,
          );
          set({
            productSearchRequest: {
              ...productSearchRequestState,
              ...(request || EMPTY_OBJECT),
            },
            products: result,
            loading: false,
            error: null,
          });
        } catch (error) {
          set({
            error,
          });
        }
      },
      resetClassOfGoods: () => {
        const currentCogsList = getState().classOfGoodsList;
        set({
          selectedCogs: EMPTY_OBJECT,
          classOfGoodsList: slice(currentCogsList, 0, 1),
          products: EMPTY_PRODUCTS,
          productOrClassOfGood: null,
          selectAllProducts: false,
          loading: false,
          error: null,
        });
      },
    }),
  });

export default useProductSelectionByCogsStore;
