import { COOKIE_CONSENT } from './i18n';

export const LOCAL_STORAGE_KEY = 'accepted-cookies';
export const ANALYTICS_NAMESPACE = 'analytics';
export const THIRD_PARTY_NAMESPACE = 'thirdParty';
export const FUNCTIONAL_NAMESPACE = 'functional';
export const GOOGLE_RECAPTCHA_NAMESPACE = 'googleReCaptcha';
export const GOOGLE_MAPS_NAMESPACE = 'googleMaps';
export const MARKETING_NAMESPACE = 'marketing';

export const ALL_COOKIES_CONFIGS = [
  {
    key: GOOGLE_MAPS_NAMESPACE,
    displayNameI18n:
      COOKIE_CONSENT.DYNAMIC_CONSENT_CONTENTS.GOOGLE_MAPS_CONSENT.TITLE,
    description:
      COOKIE_CONSENT.DYNAMIC_CONSENT_CONTENTS.GOOGLE_MAPS_CONSENT.CONTENT,
    optional: true,
  },
  {
    key: ANALYTICS_NAMESPACE,
    displayNameI18n:
      COOKIE_CONSENT.DYNAMIC_CONSENT_CONTENTS.GOOGLE_ANALYTICS_CONSENT.TITLE,
    description:
      COOKIE_CONSENT.DYNAMIC_CONSENT_CONTENTS.GOOGLE_ANALYTICS_CONSENT.CONTENT,
    optional: true,
  },
  {
    key: THIRD_PARTY_NAMESPACE,
    displayNameI18n:
      COOKIE_CONSENT.DYNAMIC_CONSENT_CONTENTS.THIRD_PARTY_CONSENT.TITLE,
    description:
      COOKIE_CONSENT.DYNAMIC_CONSENT_CONTENTS.THIRD_PARTY_CONSENT.CONTENT,
    optional: true,
  },
  {
    key: FUNCTIONAL_NAMESPACE,
    displayNameI18n:
      COOKIE_CONSENT.DYNAMIC_CONSENT_CONTENTS.FUNCTIONAL_CONSENT.TITLE,
    description:
      COOKIE_CONSENT.DYNAMIC_CONSENT_CONTENTS.FUNCTIONAL_CONSENT.CONTENT,
    optional: false,
  },
];
